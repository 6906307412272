import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
  Image,
  SafeAreaView,
  Dimensions
} from 'react-native';
import { styles } from '../Styles/style';

// Import Components
import Loader from '../Components/Loader';

const DownloadApp = ({navigation}) => {

  const goToAppStore = () => {
    console.log('DownloadApp -> Go to AppStore')
  }

  const goToGooglePlay = () => {
    console.log('DownloadApp -> Go to GooglePlay')
  }
    
    return (
    
        <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#000000'
          }}>
            <Image
                  source={require('../assets/backGround.jpg')}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    opacity: 0.2
                  }}
                />
          <View accessibilityRole='form'>
            <KeyboardAvoidingView enabled>
              <View style={{alignItems: 'center'}}>
              <Image
                  source={require('../assets/Logo.jpg')}
                  style={{
                    width: '30vw',
                    height: '30vw',
                    //resizeMode: 'contain', //objectFit?: 'cover' | 'contain' | 'fill' | 'scale-down' | undefined;
                    margin: '1vw',
                    borderRadius: '1vw',
                  }}
                />
              </View>
              <View style={styles.downloadAppStyle}>
                Vous pouvez utiliser nos services et profiter plainement des toutes nos fonctionalités sur votre appareil mobile.
                Téléchargé notre application sur la plateforme de votre choix.
              </View>
              <View style={{width: '100vw', height: '30vw'}}>
                <TouchableOpacity onPress={goToAppStore}>
                  <View style={{alignItems: 'left', left: '10vw', position: 'absolute'}}>
                    <Image
                      source={require('../assets/appStore.png')}
                      style={{
                        width: '30vw',
                        height: '20vw',
                        resizeMode: 'contain',                      
                      }}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={goToGooglePlay}>
                <View style={{alignItems: 'right', left: '60vw', position: 'absolute'}}>
                  <Image
                    source={require('../assets/googlePlay.png')}
                    style={{
                      width: '30vw',
                      height: '20vw',
                      resizeMode: 'contain',                      
                    }}
                  />
                </View>
                </TouchableOpacity>
              </View>
              
              
             
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
      );
}

export default DownloadApp;