import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  box: {
    width: 150,
    height: '100%',
    backgroundColor: '#d2b0ff',
    margin: 0,
    left: -350,
    top: 0,
    position: 'absolute',
    shadowColor: 'black', // Couleur de l'ombre
    shadowOffset: {
      width: 10, // Largeur du décalage de l'ombre
      height: 0, // Hauteur du décalage de l'ombre
    },
    shadowOpacity: 0.5, // Opacité de l'ombre (0.0 - 1.0)
    shadowRadius: 20, // Rayon de l'ombre
    elevation: 5, // Élévation pour Android (ignorée sur iOS)
  },
  bgMenuOpen: {
    width:'0%', 
    height:'100%', 
    backgroundColor:'#000000b3', 
    position:'absolute',
    //display: 'none' // 'flex' to display the view Or 'none' to hide the view
  },
  shadowView: {
    shadowColor: 'black', // Couleur de l'ombre
    shadowOffset: {
      width: 0, // Largeur du décalage de l'ombre
      height: 2, // Hauteur du décalage de l'ombre
    },
    shadowOpacity: 0.5, // Opacité de l'ombre (0.0 - 1.0)
    shadowRadius: 3, // Rayon de l'ombre
    elevation: 5, // Élévation pour Android (ignorée sur iOS)
  },
  boxModal: {
    width: '80%',
    height: '80%',
    backgroundColor: '#d2b0ff',
    margin: 0,
    left: '10%',
    top: '-90%',
    position: 'fixed',
    shadowColor: 'black', // Couleur de l'ombre
    shadowOffset: {
      width: 10, // Largeur du décalage de l'ombre
      height: 10, // Hauteur du décalage de l'ombre
    },
    shadowOpacity: 0.5, // Opacité de l'ombre (0.0 - 1.0)
    shadowRadius: 20, // Rayon de l'ombre
    elevation: 5, // Élévation pour Android (ignorée sur iOS)
    borderRadius: 10
  },
  // labelInputText: {
  //   position: 'absolute',
  //   top: field.value === '' ? 32 : 22,
  //   left: 10,
  //   fontSize: field.value === '' ? 30 : 14,
  //   color: field.value === '' ? 'gray' : '#9f56ff',
  //   zIndex: 1,
  // },
  labelInputText: {
    position: 'absolute',
    top: 32,
    left: 10,
    fontSize:30,
    color:'gray',
    zIndex: 1,
    backgroundColor:'#cccccc',
    width:'100%',
    height:46
  },
  labelContainer: {
    backgroundColor: "#ffffff", // Same color as background
    alignSelf: "flex-start", // Have View be same width as Text inside
    paddingHorizontal: 3, // Amount of spacing between border and first/last letter
    marginStart: 10, // How far right do you want the label to start
    zIndex: 1, // Label must overlap border
    elevation: 1, // Needed for android
    shadowColor: "#ffffff", // Same as background color because elevation: 1 creates a shadow that we don't want
    position: "absolute", // Needed to be able to precisely overlap label with border
    top: -9, // Vertical position of label. Eyeball it to see where label intersects border.
},
inputContainer: {
    borderWidth: 1, // Create border
    borderRadius: 12, // Not needed. Just make it look nicer.
    padding: 4, // Also used to make it look nicer
    zIndex: 0, // Ensure border has z-index of 0
    borderColor: '#9f56ff', // 9f56ff // #ff000017
    height: 60,
    backgroundColor: 'transparent',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12
},
inputContainerError: {
  borderWidth: 1, // Create border
  borderRadius: 12, // Not needed. Just make it look nicer.
  padding: 4, // Also used to make it look nicer
  zIndex: 0, // Ensure border has z-index of 0
  borderColor: '#ff0000', // 9f56ff // #ff000017
  height: 60,
  backgroundColor: '#ff000017',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12
},
  topBar: {
    backgroundColor:'#f5f5f5', 
    width:'100%', 
    height:100, 
    top:0, 
    position:'fixed',
    flexFlow: 'wrap',
    borderWidth:1,
    paddingLeft:10
  },
  bottomBar: {
    backgroundColor:'#f5f5f5', 
    width:'100%', 
    height:100, 
    bottom:0, 
    position:'fixed',
    borderColor:'#252525',
    borderWidth:1,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  containerSample: {
    flex: 1,
    backgroundColor: '#ffffff',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: "1em",
    color: '#ffffff',
    fontFamily: 'sans-serif'
  },
  mainMenuConnected: {
    alignSelf:'center', width:'60%', height:35, bottom:5, left:'0%', flexWrap:'wrap'
  },
  mainMenuUnconnected: {
    alignSelf:'center', width:'60%', height:35, bottom:5, left:'20%', flexWrap:'wrap'
  },
  bottomBarMenu: {
    alignSelf:'center', width:'90%', height:35, bottom:5, left:'0%', flexWrap:'wrap'
  },
  activityIndicator: {
    alignItems: 'center',
    height: 80,
  },
  containerSplash: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#903eff',
  },
  image: {
    margin: 40
  },
  inputView: {
    backgroundColor: "#cccccc",
    borderRadius: 10,
    width: "70%",
    height: '6%',
    marginBottom: 20,
    alignItems: "center",
  },
  TextInput: {
    height: 50,
    flex: 1,
    padding: 10,
    marginLeft: 20,
  },
  forgot_button: {
    height: 30,
    marginBottom: 30,
  },
  loginBtn: {
    width: "70%",
    borderRadius: 10,
    tintColor: '#ffffff',
    height: '6%',
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: 'darkred',
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
    width: '50%',
    alignSelf: 'center'
  },
  SectionStyleMinus: {
    height: 30,
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
    margin: 5,
    width: '50%',
    alignSelf: 'center'
  },
  buttonStyle: {
    backgroundColor: '#7e2584',
    borderWidth: 0,
    //color: '#666666',
    borderColor: '#7DE24E',
    height: 50,
    alignItems: 'center',
    borderRadius: 5,
    marginLeft: 35,
    marginRight: 35,
    marginTop: '5%',
    width: "50%",
    alignSelf: 'center',
    fontSize:25
  },
  buttonDisableStyle: {
    backgroundColor: '#cccccc',
    borderWidth: 0,
    color: '#666666',
    borderColor: '#7DE24E',
    height: 50,
    alignItems: 'center',
    borderRadius: 5,
    marginLeft: 35,
    marginRight: 35,
    marginTop: '5%',
    marginBottom: '5%',
    width: "50%",
    alignSelf: 'center',
    fontSize: 25
  },
  buttonTextStyle: {
    color: '#ffffff',
    paddingVertical: 10,
    fontSize: 25,
  },
  buttonMenuStyle: {
    color: '#666666',
    width: 50,
    height: 50,
    left: 10,
    top: 0,
    padding: 0,
    textAlign: 'center',
    position: 'absolute',
  },
  inputStyle: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#7e2584',
    placeholderTextColor: '#cccccc',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#e9e9e9'
  },
  inputStyleSuccess: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'green',
    placeholderTextColor: '#d0adff',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#00800033'
  },
  inputStyleError: {
    flex: 1,
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: 'red',
    placeholderTextColor: '#d0adff',
    width: "50%",
    height: 50,
    alignSelf: 'center',
    backgroundColor: '#ff00001f'
  },
  inputStyleDisable: {
    flex: 1,
    backgroundColor: '#f1f1f11f',
    color: '#903eff',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'cccccc',
    placeholderTextColor: '#ffffff',
    width: "50%",
    height: 50,
    alignSelf: 'center'
  },
  registerTextStyle: {
    color: '#666666',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 35,
    marginRight: 35,
  },
  successTextStyle: {
    color: 'green',
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 35,
    marginRight: 35,
  },
  infoTextStyle: {
    color: 'gray',
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 35,
    marginRight: 35,
  },
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: '#00000040',
  },
  activityIndicatorWrapper: {
    backgroundColor: '#FFFFFF',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  activityIndicator: {
    alignItems: 'center',
    height: 80,
  },
  linkStyle: {
    color: '#7e2584',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  downloadAppStyle: {
    textAlign: 'left',
    width: '80vw',
    height: '20vw',
    backgroundColor: '#292929ba',
    left: '10%',
    fontFamily: 'sans-serif;',
    fontSize: '3vw',
    padding: '2vw',
    color: '#cccccc',
    borderRadius: '1vw'
  },
  appStoreLogo: {
    height: 150, 
    resizeMode: 'contain', 
    alignSelf: 'left'
  },
  googlePlayLogo: {
    height: 150, 
    resizeMode: 'contain', 
    alignSelf: 'right'
  }
   
});

export { styles }