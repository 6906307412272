// Import React and Component
import React, {useState, useEffect} from 'react';
import {ActivityIndicator, View, StyleSheet, Image} from 'react-native';
import { storeData, getData, removeData } from "../Utils/DataManager";
import { styles } from '../Styles/style';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';



const SplashScreen = ({navigation}) => {
    console.log("SplashScreen -> ")
    //State for ActivityIndicator animation
    const [animating, setAnimating] = useState(true);

    const deviceTypeMap = {
      [DeviceType.UNKNOWN]: "unknown",
      [DeviceType.PHONE]: "phone",
      [DeviceType.TABLET]: "tablet",
      [DeviceType.DESKTOP]: "desktop",
      [DeviceType.TV]: "tv",
    };
 
    useEffect(() => {

      getDeviceTypeAsync().then((deviceType) => {
        console.log('SplashScreen -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
        if (deviceTypeMap[deviceType] != 'desktop') {
          navigation.navigate('DownloadApp')
        }

      });

      setTimeout(() => {
        setAnimating(false);
        //Check if user_id is set or not
        //If not then send for Authentication
        //else send to Home Screen
        
        getData('MGUserInfo').then((value) => {
          console.log('SplashScreen -> getData -> MGGetUserId : ' + value.MGGetUserId)
          if(value === null) {
            navigation.navigate('LoginScreen')
          } else {
            navigation.navigate('HomeScreen')
          }
        })
        
      }, 3000);
    }, []);
    console.log("SplashScreen -> ")
    return (
        
      <View style={styles.containerSplash}>
        <Image
          source={require('../assets/logoMail.jpg')}
          style={{width: '90%', resizeMode: 'contain', margin: 30}}
        />
        <ActivityIndicator
          animating={animating}
          color="#FFFFFF"
          size="large"
          style={styles.activityIndicator}
        />
      </View>
    );
  };
  
  export default SplashScreen;
  
  