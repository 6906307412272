import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { APIMainRequest } from '../Communication/MGAPIRequest';

const MGGetSettings = (IDForGetSettings) => {


  const [getMailSettings, setMailSettings] = React.useState()

  const settingsParams = {
    clientID: IDForGetSettings,
  };
  console.log('MGGetSettings -> settingsParams -> ' + JSON.stringify(settingsParams, null, 2))

  APIMainRequest('/MGUsers/settings/dh/list', settingsParams).then(function (response) {

    return response.getData

  }).then(function (myJson) {


    console.log('MGGetSettings -> resultSettingsRequest -> isSuccess -> ' + myJson)
    console.log('MGGetSettings -> resultSettingsRequest -> message -> ' + myJson.message)
    console.log('MGGetSettings -> resultSettingsRequest -> settings -> ' + JSON.stringify(myJson.settings, null, 2))


  }).finally(() => {
    console.log('MGGetSettings -> Finally ')

  })

}

export { MGGetSettings }