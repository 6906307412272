import axios from 'axios';

export const APIUrl = 'https://api.magraf.fr:8443';
export const APIEndPointUser = '/MGUsers';
export const APIEndPointUserLogin = '/login';




const instance = axios.create({
    baseURL: APIUrl,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});
    
const APIMainRequest = async (endPoint, getParameter) => {
    
    console.log('APIMainRequest ->');

    try {

      const response = await instance.post(endPoint, getParameter)
      
      console.log('APIMainRequest -> response #1 : ' + response.data.success);
      
      const result = {
        // isSuccess: response.data.success,
        // isSuccessLogin: response.data.login,
        // isSuccessPwd: response.data.password,

        getData: response.data,
        
      };
      // console.log('APIMainRequest -> result #1 : ' + result.getData[0].stoneName);
      
      return result

    } catch (error) {
        
      console.log('APIMainRequest -> Catch Request ERROR : ' + error);

        const resultError = {
          isSuccess: false,
          getData: 'ERROR',
          
        };

        return resultError
    }
    

};

const selectClientListRequest = (whichProfile) => {

  var returnValue = ''
  if (whichProfile == 'root') {
    returnValue = '/MGUsers/dh/root/lists'
  } 
  if (whichProfile == 'admin') {
    returnValue = '/MGUsers/dh/admin/lists'
  }
  if (whichProfile == 'user') {
    returnValue = '/MGUsers/dh/user/lists'
  } 
  if (whichProfile == 'customer') {
    returnValue = '/MGUsers/dh/customer/lists'
  } 
  return returnValue
}


export { APIMainRequest, selectClientListRequest }