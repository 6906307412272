import React, { useState, createRef, useRef, useEffect, Component, useMemo } from 'react';
import { StyleSheet, TextInput, View, ScrollView, ImageBackground, Keyboard, TouchableOpacity, KeyboardAvoidingView, Image, SafeAreaView, Dimensions, FlatList } from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';

import {
    Box, Button, Link, Fab, ListItemText, Avatar, Typography, ListItemIcon,
    Fade, Modal, Backdrop, CardMedia
} from '@mui/material';

import {
    AccountCircle, MailOutlineOutlined,
    SupervisorAccountOutlined, AddCircle, EngineeringOutlined,
    ExitToApp, TaskAltOutlined, CancelOutlined, PersonOutlineOutlined, NoAccountsOutlined,
    BusinessOutlined, LocationCityOutlined, FormatListBulletedOutlined, FlagOutlined, RuleOutlined, CalendarMonthOutlined,
    EditOutlined, PhoneIphoneOutlined, PhoneOutlined, Grid3x3Outlined, AdminPanelSettingsOutlined, LockOpenOutlined,
    OutboxOutlined, SignpostOutlined, PersonOutlined, AssignmentIndOutlined
} from '@mui/icons-material';

import {
    DataGrid, GridToolbar,
} from '@mui/x-data-grid'

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap } from '../Utils/Helper';
import MGModal from '../Components/MGModal';
import Loader from '../Components/Loader';
import FormAddClients from '../Components/FormAddClient';
import UnixTimestampToDate from '../Components/UnixTimestampToDate'




const ClientsList = ({ navigation, getWitchPage }) => {
    console.log('ClientsList -> START')
    const [id, setID] = useState('1')
    const [clientName, setNameClient] = useState('')


    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    const [data, setData] = React.useState()
    const [maxWidth, setMaxWidth] = useState('95%')
    const [loading, setLoading] = useState(false)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [rows, setRows] = React.useState([]);
    const [whichForm, setWhichForm] = useState()

    const [switchActive, setSwitchActive] = React.useState();

    const closeModalRefreshList = (raf) => {
        console.log('ClientsList -> closeModalRefreshList - ID ' + id + ' - getVar : ' + raf)
        handleClose()

        executeRequest()
    }


    const executeRequest = () => {

        getData('MGUserInfo').then(function (value) {
            console.log('ClientsList -> executeRequest -> getData -> MGUserInfo -> profile : ' + value.profile)
            return value
        }).then(function (myValue) {

            console.log('ClientsList -> executeRequest -> getData -> MGUserInfo -> myValue.profile : ' + myValue.parentID)

            getData('MGUserSetting').then(function (value) {
                console.log('ClientsList -> executeRequest -> getData -> MGUserSetting -> : ' + value.entryDate)
                return value
            }).then(function (myValue) {

                console.log('ClientsList -> executeRequest -> getData -> MGUserSetting ->  : ' + myValue.entryDate)

            })

            const request = selectClientListRequest(myValue.profile)

            console.log('ClientsList -> executeRequest -> getData -> then -> MGUserInfo -> Request : ' + request)

            const params = {
                userId: myValue.MGGetUserId,
                parentID: myValue.parentID,
                licenceKey: myValue.licenceKey
            };

            APIMainRequest(request, params).then(function (response) {

                return response.getData
            }).then(function (myJson) {
                console.log('ClientsList -> getClientsList -> Display Data #2 -> ' + JSON.stringify(myJson, null, 2))
                setData(myJson)

                // populateGrid(myJson.rows)

            }).finally(() => {
                setLoading(false)

            })

        })

    }



    // const updateFormClientModal = (myparam) => (
    //     myparam, // GridRowParams
    //     event, // MuiEvent<React.MouseEvent<HTMLElement>>
    //     details, // GridCallbackDetails
    // ) => {

    //     console.log('ClientsList -> displayNewModal -> id -> ' + myparam.row._id)
    //     console.log('ClientsList -> displayNewModal -> Name -> ' + myparam.row.name)
    //     setID(myparam.row._id)
    //     //handleOpen()
    //     setNameClient(myparam.row.name)
    //     // setWhichForm(<FormClients idClients={myparam.row._id}/>)
    //     getWitchPage(myparam.row._id, 'detailsUsers')
    // }

    // const addFormClientModal = (id) => {
    //     console.log('ClientsList -> displayNewModal -> id -> ' + id)
    //     setID(id)
    //     handleOpen()
    //     setWhichForm(<FormAddClients idClients={id} />)
    // }

    // const onChange = () => {
    //     console.log('ClientsList -> onChange ->')
    //     //handler to get device Height
    //     setHeight(Dimensions.get('window').height);
    //     //handler to get device Width
    //     setWidth(Dimensions.get('window').width);
    //     var setDimBody = Dimensions.get('window').width - 240

    // };

    const updateFormClientModal = (_id) => {

        console.log('ClientsList -> updateFormClientModal -> id -> ' + _id)
        setID(_id)
        //handleOpen()
        // setNameClient(name)
        // setWhichForm(<FormClients idClients={myparam.row._id}/>)
        getWitchPage(_id, 'detailsUsers')
    }

    const addFormClientModal = (id) => {
        console.log('ClientsList -> displayNewModal -> id -> ' + id)
        setID(id)
        handleOpen()
        setWhichForm(<FormAddClients idClients={id} closeModalRefreshList={closeModalRefreshList} />)
    }

    const onChange = () => {
        console.log('ClientsList -> onChange ->')
        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);
        var setDimBody = Dimensions.get('window').width - 240

    };

    useEffect(function effectFunction() {
        console.log('ClientsList -> useEffect');

        getDeviceTypeAsync().then((deviceType) => {
            console.log('ClientsList -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
            if (deviceTypeMap[deviceType] != 'desktop') {
                navigation.navigate('DownloadApp')
            }

        });

        onChange()

        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);

        Dimensions.addEventListener('change', onChange);

        executeRequest()

    }, []);

    const tmpInitialState = {
        columns: {
            columnVisibilityModel: {

                _id: true, // 1
                custoId: false, // 2
                id: false, // 3
                parentID: false, // 4
                entryDate: true, // 5
                updateDate: true, // 6
                login: true, // 7
                passWord: false, // 8
                email: true, // 9
                firstName: false, // 10
                lastName: false, // 11
                ceoName: true, // 12
                phonFixe: true, // 13
                phoneMobile: true, // 14
                active: true, // 15
                cp: false, // 16
                bp: false, // 17
                adress1: false, // 18
                adress2: false, // 19 
                city: true, // 20
                stateProvince: false, // 21
                profile: true, // 22
                name: true, // 23
                identificationNumber: true, // 24
                sendDoc: false, // 25
                deadLineInvoicePayment: false, // 26
                salesRepresent: false, // 27
                info: false, // 28
                licenceKey: false, // 29
                ticketsList: true // 30

            }
        }
    };

    const MGDefineCustomerColumns = [
        {
            field: "_id",
            headerName: <>
                <ListItemIcon>
                    <EditOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Éditer</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hideable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Button variant="outlined" sx={{ color: grey[700], borderColor: grey[700] }} startIcon={<EditOutlined />}
                            onClick={() => { updateFormClientModal(params.value) }}>
                            Éditer
                        </Button>
                        {/* <EditOutlined sx={{ color: grey[600], width: 30, height: 30 }} /> */}
                    </>
                );
            },
            align: 'center'
        }, // _id
        {
            field: "custoId",
            hideable: false
        }, // custoId
        {
            field: "id",
            hideable: false
        }, // id
        {
            field: "parentID",
            hideable: false
        }, // parentID
        {
            field: "active",
            headerName: <>
                <ListItemIcon>
                    <RuleOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Actif</ListItemText>
                </ListItemIcon>
            </>,
            width: 80,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            renderCell: (params) => {
                console.log('ClientsList -> Header -> Active : ' + params.value)
                return (
                    <>
                        {params.value == true ?
                            <TaskAltOutlined sx={{ color: green[800] }} /> :
                            <CancelOutlined sx={{ color: red[800] }} />}

                    </>
                );
            },
            align: 'center'

            //   "availableAggregationFunctions": [
            //     "avg",
            //     "min",
            //     "max",
            //     "size"
            //   ]
        }, // active
        {
            field: "name",
            headerName: <>
                <ListItemIcon>
                    <BusinessOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Nom compagny</ListItemText>
                </ListItemIcon>
            </>,
            width: 200,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // name
        {
            field: "identificationNumber",
            headerName: <>
                <ListItemIcon>
                    <Grid3x3Outlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>I.D</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // identificationNumber
        {
            field: "ceoName",
            headerName: <>
                <ListItemIcon>
                    <AdminPanelSettingsOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Nom gérant</ListItemText>
                </ListItemIcon>
            </>,
            width: 200,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // ceoName
        {
            field: "phonFixe",
            headerName: <>
                <ListItemIcon>
                    <PhoneOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Bureau</ListItemText>
                </ListItemIcon>
            </>,
            type: "number",
            width: 150,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            align: 'center'
        }, // phonFixe
        {
            field: "phoneMobile",
            headerName: <>
                <ListItemIcon>
                    <PhoneIphoneOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Cellulaire</ListItemText>
                </ListItemIcon>
            </>,
            type: "number",
            width: 150,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            align: 'center'
        }, // phoneMobile
        {
            field: "entryDate",
            headerName: <>
                <ListItemIcon>
                    <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Inscrit le</ListItemText>
                </ListItemIcon>
            </>,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            width: 180,
            renderCell: (params) => {
                return (
                    <><UnixTimestampToDate unixTimestamp={params.value} /></>
                );
            },
        }, // entryDate
        {
            field: "email",
            headerName: <>
                <ListItemIcon>
                    <MailOutlineOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Mail</ListItemText>
                </ListItemIcon>
            </>,
            width: 200,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            renderCell: (params) => {
                var setHref = "mailto:" + params.value
                return (
                    <>

                        <Link href={setHref} underline="always">
                            {params.value}
                        </Link>

                    </>
                );
            },
        }, // email
        {
            field: "login",
            headerName: <>
                <ListItemIcon>
                    <LockOpenOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Login</ListItemText>
                </ListItemIcon>
            </>,
            dataGeneratorUniquenessEnabled: true,
            width: 200,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // login
        {
            field: "profile",
            headerName: <>
                <ListItemIcon>
                    <AccountCircle sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Profile</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            renderCell: (params) => {
                return (
                    <>
                        {params.value == 'admin' ?
                            <SupervisorAccountOutlined sx={{ color: purple[900], width: 30, height: 30 }} /> :
                            params.value == 'user' ?
                                <EngineeringOutlined sx={{ color: purple[600], width: 30, height: 30 }} /> :
                                params.value == 'customer' ?
                                    <PersonOutlineOutlined sx={{ color: purple[300], width: 30, height: 30 }} /> :
                                    <NoAccountsOutlined sx={{ color: grey[300], width: 30, height: 30 }} />
                        }
                    </>
                );
            },
            align: 'center'
        }, // profile
        {
            field: "passWord",
            headerName: "Mot de passe",
            width: 160,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hideable: false
        }, // passWord
        {
            field: "sendDoc",
            headerName: <>
                <ListItemIcon>
                    <OutboxOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Envoi Doc</ListItemText>
                </ListItemIcon>
            </>,
            width: 150,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // sendDoc
        {
            field: "deadLineInvoicePayment",
            headerName: <>
                <ListItemIcon>
                    <OutboxOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Limite paiement</ListItemText>
                </ListItemIcon>
            </>,
            width: 180,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            renderCell: (params) => {
                return (
                    <><Avatar sx={{ bgcolor: blue[500], width: 35, height: 35 }}>{params.value} J</Avatar></>
                );
            },
            align: 'center'
        }, // deadLineInvoicePayment
        {
            field: "ticketsList",
            headerName: <>
                <ListItemIcon>
                    <FormatListBulletedOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Tickets</ListItemText>
                </ListItemIcon>
            </>,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // ticketsList
        {
            field: "country",
            headerName: <>
                <ListItemIcon>
                    <FlagOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Pays</ListItemText>
                </ListItemIcon>
            </>,
            type: "singleSelect",
            valueOptions: [
                {
                    "value": "AF",
                    "code": "AF",
                    "label": "Afghanistan",
                    "phone": "93"
                },
                {
                    "value": "AL",
                    "code": "AL",
                    "label": "Albania",
                    "phone": "355"
                },
                {
                    "value": "DZ",
                    "code": "DZ",
                    "label": "Algeria",
                    "phone": "213"
                },
                {
                    "value": "AX",
                    "code": "AX",
                    "label": "Alland Islands",
                    "phone": "358"
                },
                {
                    "value": "AS",
                    "code": "AS",
                    "label": "American Samoa",
                    "phone": "1-684"
                },
                {
                    "value": "AD",
                    "code": "AD",
                    "label": "Andorra",
                    "phone": "376"
                },
                {
                    "value": "AO",
                    "code": "AO",
                    "label": "Angola",
                    "phone": "244"
                },
                {
                    "value": "AI",
                    "code": "AI",
                    "label": "Anguilla",
                    "phone": "1-264"
                },
                {
                    "value": "AQ",
                    "code": "AQ",
                    "label": "Antarctica",
                    "phone": "672"
                },
                {
                    "value": "AG",
                    "code": "AG",
                    "label": "Antigua and Barbuda",
                    "phone": "1-268"
                },
                {
                    "value": "AR",
                    "code": "AR",
                    "label": "Argentina",
                    "phone": "54"
                },
                {
                    "value": "AM",
                    "code": "AM",
                    "label": "Armenia",
                    "phone": "374"
                },
                {
                    "value": "AW",
                    "code": "AW",
                    "label": "Aruba",
                    "phone": "297"
                },
                {
                    "value": "AU",
                    "code": "AU",
                    "label": "Australia",
                    "phone": "61",
                    "suggested": true
                },
                {
                    "value": "AT",
                    "code": "AT",
                    "label": "Austria",
                    "phone": "43"
                },
                {
                    "value": "AZ",
                    "code": "AZ",
                    "label": "Azerbaijan",
                    "phone": "994"
                },
                {
                    "value": "BS",
                    "code": "BS",
                    "label": "Bahamas",
                    "phone": "1-242"
                },
                {
                    "value": "BH",
                    "code": "BH",
                    "label": "Bahrain",
                    "phone": "973"
                },
                {
                    "value": "BD",
                    "code": "BD",
                    "label": "Bangladesh",
                    "phone": "880"
                },
                {
                    "value": "BB",
                    "code": "BB",
                    "label": "Barbados",
                    "phone": "1-246"
                },
                {
                    "value": "BY",
                    "code": "BY",
                    "label": "Belarus",
                    "phone": "375"
                },
                {
                    "value": "BE",
                    "code": "BE",
                    "label": "Belgium",
                    "phone": "32"
                },
                {
                    "value": "BZ",
                    "code": "BZ",
                    "label": "Belize",
                    "phone": "501"
                },
                {
                    "value": "BJ",
                    "code": "BJ",
                    "label": "Benin",
                    "phone": "229"
                },
                {
                    "value": "BM",
                    "code": "BM",
                    "label": "Bermuda",
                    "phone": "1-441"
                },
                {
                    "value": "BT",
                    "code": "BT",
                    "label": "Bhutan",
                    "phone": "975"
                },
                {
                    "value": "BO",
                    "code": "BO",
                    "label": "Bolivia",
                    "phone": "591"
                },
                {
                    "value": "BA",
                    "code": "BA",
                    "label": "Bosnia and Herzegovina",
                    "phone": "387"
                },
                {
                    "value": "BW",
                    "code": "BW",
                    "label": "Botswana",
                    "phone": "267"
                },
                {
                    "value": "BV",
                    "code": "BV",
                    "label": "Bouvet Island",
                    "phone": "47"
                },
                {
                    "value": "BR",
                    "code": "BR",
                    "label": "Brazil",
                    "phone": "55"
                },
                {
                    "value": "IO",
                    "code": "IO",
                    "label": "British Indian Ocean Territory",
                    "phone": "246"
                },
                {
                    "value": "VG",
                    "code": "VG",
                    "label": "British Virgin Islands",
                    "phone": "1-284"
                },
                {
                    "value": "BN",
                    "code": "BN",
                    "label": "Brunei Darussalam",
                    "phone": "673"
                },
                {
                    "value": "BG",
                    "code": "BG",
                    "label": "Bulgaria",
                    "phone": "359"
                },
                {
                    "value": "BF",
                    "code": "BF",
                    "label": "Burkina Faso",
                    "phone": "226"
                },
                {
                    "value": "BI",
                    "code": "BI",
                    "label": "Burundi",
                    "phone": "257"
                },
                {
                    "value": "KH",
                    "code": "KH",
                    "label": "Cambodia",
                    "phone": "855"
                },
                {
                    "value": "CM",
                    "code": "CM",
                    "label": "Cameroon",
                    "phone": "237"
                },
                {
                    "value": "CA",
                    "code": "CA",
                    "label": "Canada",
                    "phone": "1",
                    "suggested": true
                },
                {
                    "value": "CV",
                    "code": "CV",
                    "label": "Cape Verde",
                    "phone": "238"
                },
                {
                    "value": "KY",
                    "code": "KY",
                    "label": "Cayman Islands",
                    "phone": "1-345"
                },
                {
                    "value": "CF",
                    "code": "CF",
                    "label": "Central African Republic",
                    "phone": "236"
                },
                {
                    "value": "TD",
                    "code": "TD",
                    "label": "Chad",
                    "phone": "235"
                },
                {
                    "value": "CL",
                    "code": "CL",
                    "label": "Chile",
                    "phone": "56"
                },
                {
                    "value": "CN",
                    "code": "CN",
                    "label": "China",
                    "phone": "86"
                },
                {
                    "value": "CX",
                    "code": "CX",
                    "label": "Christmas Island",
                    "phone": "61"
                },
                {
                    "value": "CC",
                    "code": "CC",
                    "label": "Cocos (Keeling) Islands",
                    "phone": "61"
                },
                {
                    "value": "CO",
                    "code": "CO",
                    "label": "Colombia",
                    "phone": "57"
                },
                {
                    "value": "KM",
                    "code": "KM",
                    "label": "Comoros",
                    "phone": "269"
                },
                {
                    "value": "CD",
                    "code": "CD",
                    "label": "Congo, Democratic Republic of the",
                    "phone": "243"
                },
                {
                    "value": "CG",
                    "code": "CG",
                    "label": "Congo, Republic of the",
                    "phone": "242"
                },
                {
                    "value": "CK",
                    "code": "CK",
                    "label": "Cook Islands",
                    "phone": "682"
                },
                {
                    "value": "CR",
                    "code": "CR",
                    "label": "Costa Rica",
                    "phone": "506"
                },
                {
                    "value": "CI",
                    "code": "CI",
                    "label": "Cote d'Ivoire",
                    "phone": "225"
                },
                {
                    "value": "HR",
                    "code": "HR",
                    "label": "Croatia",
                    "phone": "385"
                },
                {
                    "value": "CU",
                    "code": "CU",
                    "label": "Cuba",
                    "phone": "53"
                },
                {
                    "value": "CW",
                    "code": "CW",
                    "label": "Curacao",
                    "phone": "599"
                },
                {
                    "value": "CY",
                    "code": "CY",
                    "label": "Cyprus",
                    "phone": "357"
                },
                {
                    "value": "CZ",
                    "code": "CZ",
                    "label": "Czech Republic",
                    "phone": "420"
                },
                {
                    "value": "DK",
                    "code": "DK",
                    "label": "Denmark",
                    "phone": "45"
                },
                {
                    "value": "DJ",
                    "code": "DJ",
                    "label": "Djibouti",
                    "phone": "253"
                },
                {
                    "value": "DM",
                    "code": "DM",
                    "label": "Dominica",
                    "phone": "1-767"
                },
                {
                    "value": "DO",
                    "code": "DO",
                    "label": "Dominican Republic",
                    "phone": "1-809"
                },
                {
                    "value": "EC",
                    "code": "EC",
                    "label": "Ecuador",
                    "phone": "593"
                },
                {
                    "value": "EG",
                    "code": "EG",
                    "label": "Egypt",
                    "phone": "20"
                },
                {
                    "value": "SV",
                    "code": "SV",
                    "label": "El Salvador",
                    "phone": "503"
                },
                {
                    "value": "GQ",
                    "code": "GQ",
                    "label": "Equatorial Guinea",
                    "phone": "240"
                },
                {
                    "value": "ER",
                    "code": "ER",
                    "label": "Eritrea",
                    "phone": "291"
                },
                {
                    "value": "EE",
                    "code": "EE",
                    "label": "Estonia",
                    "phone": "372"
                },
                {
                    "value": "ET",
                    "code": "ET",
                    "label": "Ethiopia",
                    "phone": "251"
                },
                {
                    "value": "FK",
                    "code": "FK",
                    "label": "Falkland Islands (Malvinas)",
                    "phone": "500"
                },
                {
                    "value": "FO",
                    "code": "FO",
                    "label": "Faroe Islands",
                    "phone": "298"
                },
                {
                    "value": "FJ",
                    "code": "FJ",
                    "label": "Fiji",
                    "phone": "679"
                },
                {
                    "value": "FI",
                    "code": "FI",
                    "label": "Finland",
                    "phone": "358"
                },
                {
                    "value": "FR",
                    "code": "FR",
                    "label": "France",
                    "phone": "33",
                    "suggested": true
                },
                {
                    "value": "GF",
                    "code": "GF",
                    "label": "French Guiana",
                    "phone": "594"
                },
                {
                    "value": "PF",
                    "code": "PF",
                    "label": "French Polynesia",
                    "phone": "689"
                },
                {
                    "value": "TF",
                    "code": "TF",
                    "label": "French Southern Territories",
                    "phone": "262"
                },
                {
                    "value": "GA",
                    "code": "GA",
                    "label": "Gabon",
                    "phone": "241"
                },
                {
                    "value": "GM",
                    "code": "GM",
                    "label": "Gambia",
                    "phone": "220"
                },
                {
                    "value": "GE",
                    "code": "GE",
                    "label": "Georgia",
                    "phone": "995"
                },
                {
                    "value": "DE",
                    "code": "DE",
                    "label": "Germany",
                    "phone": "49",
                    "suggested": true
                },
                {
                    "value": "GH",
                    "code": "GH",
                    "label": "Ghana",
                    "phone": "233"
                },
                {
                    "value": "GI",
                    "code": "GI",
                    "label": "Gibraltar",
                    "phone": "350"
                },
                {
                    "value": "GR",
                    "code": "GR",
                    "label": "Greece",
                    "phone": "30"
                },
                {
                    "value": "GL",
                    "code": "GL",
                    "label": "Greenland",
                    "phone": "299"
                },
                {
                    "value": "GD",
                    "code": "GD",
                    "label": "Grenada",
                    "phone": "1-473"
                },
                {
                    "value": "GP",
                    "code": "GP",
                    "label": "Guadeloupe",
                    "phone": "590"
                },
                {
                    "value": "GU",
                    "code": "GU",
                    "label": "Guam",
                    "phone": "1-671"
                },
                {
                    "value": "GT",
                    "code": "GT",
                    "label": "Guatemala",
                    "phone": "502"
                },
                {
                    "value": "GG",
                    "code": "GG",
                    "label": "Guernsey",
                    "phone": "44"
                },
                {
                    "value": "GN",
                    "code": "GN",
                    "label": "Guinea",
                    "phone": "224"
                },
                {
                    "value": "GW",
                    "code": "GW",
                    "label": "Guinea-Bissau",
                    "phone": "245"
                },
                {
                    "value": "GY",
                    "code": "GY",
                    "label": "Guyana",
                    "phone": "592"
                },
                {
                    "value": "HT",
                    "code": "HT",
                    "label": "Haiti",
                    "phone": "509"
                },
                {
                    "value": "HM",
                    "code": "HM",
                    "label": "Heard Island and McDonald Islands",
                    "phone": "672"
                },
                {
                    "value": "VA",
                    "code": "VA",
                    "label": "Holy See (Vatican City State)",
                    "phone": "379"
                },
                {
                    "value": "HN",
                    "code": "HN",
                    "label": "Honduras",
                    "phone": "504"
                },
                {
                    "value": "HK",
                    "code": "HK",
                    "label": "Hong Kong",
                    "phone": "852"
                },
                {
                    "value": "HU",
                    "code": "HU",
                    "label": "Hungary",
                    "phone": "36"
                },
                {
                    "value": "IS",
                    "code": "IS",
                    "label": "Iceland",
                    "phone": "354"
                },
                {
                    "value": "IN",
                    "code": "IN",
                    "label": "India",
                    "phone": "91"
                },
                {
                    "value": "ID",
                    "code": "ID",
                    "label": "Indonesia",
                    "phone": "62"
                },
                {
                    "value": "IR",
                    "code": "IR",
                    "label": "Iran, Islamic Republic of",
                    "phone": "98"
                },
                {
                    "value": "IQ",
                    "code": "IQ",
                    "label": "Iraq",
                    "phone": "964"
                },
                {
                    "value": "IE",
                    "code": "IE",
                    "label": "Ireland",
                    "phone": "353"
                },
                {
                    "value": "IM",
                    "code": "IM",
                    "label": "Isle of Man",
                    "phone": "44"
                },
                {
                    "value": "IL",
                    "code": "IL",
                    "label": "Israel",
                    "phone": "972"
                },
                {
                    "value": "IT",
                    "code": "IT",
                    "label": "Italy",
                    "phone": "39"
                },
                {
                    "value": "JM",
                    "code": "JM",
                    "label": "Jamaica",
                    "phone": "1-876"
                },
                {
                    "value": "JP",
                    "code": "JP",
                    "label": "Japan",
                    "phone": "81",
                    "suggested": true
                },
                {
                    "value": "JE",
                    "code": "JE",
                    "label": "Jersey",
                    "phone": "44"
                },
                {
                    "value": "JO",
                    "code": "JO",
                    "label": "Jordan",
                    "phone": "962"
                },
                {
                    "value": "KZ",
                    "code": "KZ",
                    "label": "Kazakhstan",
                    "phone": "7"
                },
                {
                    "value": "KE",
                    "code": "KE",
                    "label": "Kenya",
                    "phone": "254"
                },
                {
                    "value": "KI",
                    "code": "KI",
                    "label": "Kiribati",
                    "phone": "686"
                },
                {
                    "value": "KP",
                    "code": "KP",
                    "label": "Korea, Democratic People's Republic of",
                    "phone": "850"
                },
                {
                    "value": "KR",
                    "code": "KR",
                    "label": "Korea, Republic of",
                    "phone": "82"
                },
                {
                    "value": "XK",
                    "code": "XK",
                    "label": "Kosovo",
                    "phone": "383"
                },
                {
                    "value": "KW",
                    "code": "KW",
                    "label": "Kuwait",
                    "phone": "965"
                },
                {
                    "value": "KG",
                    "code": "KG",
                    "label": "Kyrgyzstan",
                    "phone": "996"
                },
                {
                    "value": "LA",
                    "code": "LA",
                    "label": "Lao People's Democratic Republic",
                    "phone": "856"
                },
                {
                    "value": "LV",
                    "code": "LV",
                    "label": "Latvia",
                    "phone": "371"
                },
                {
                    "value": "LB",
                    "code": "LB",
                    "label": "Lebanon",
                    "phone": "961"
                },
                {
                    "value": "LS",
                    "code": "LS",
                    "label": "Lesotho",
                    "phone": "266"
                },
                {
                    "value": "LR",
                    "code": "LR",
                    "label": "Liberia",
                    "phone": "231"
                },
                {
                    "value": "LY",
                    "code": "LY",
                    "label": "Libya",
                    "phone": "218"
                },
                {
                    "value": "LI",
                    "code": "LI",
                    "label": "Liechtenstein",
                    "phone": "423"
                },
                {
                    "value": "LT",
                    "code": "LT",
                    "label": "Lithuania",
                    "phone": "370"
                },
                {
                    "value": "LU",
                    "code": "LU",
                    "label": "Luxembourg",
                    "phone": "352"
                },
                {
                    "value": "MO",
                    "code": "MO",
                    "label": "Macao",
                    "phone": "853"
                },
                {
                    "value": "MK",
                    "code": "MK",
                    "label": "Macedonia, the Former Yugoslav Republic of",
                    "phone": "389"
                },
                {
                    "value": "MG",
                    "code": "MG",
                    "label": "Madagascar",
                    "phone": "261"
                },
                {
                    "value": "MW",
                    "code": "MW",
                    "label": "Malawi",
                    "phone": "265"
                },
                {
                    "value": "MY",
                    "code": "MY",
                    "label": "Malaysia",
                    "phone": "60"
                },
                {
                    "value": "MV",
                    "code": "MV",
                    "label": "Maldives",
                    "phone": "960"
                },
                {
                    "value": "ML",
                    "code": "ML",
                    "label": "Mali",
                    "phone": "223"
                },
                {
                    "value": "MT",
                    "code": "MT",
                    "label": "Malta",
                    "phone": "356"
                },
                {
                    "value": "MH",
                    "code": "MH",
                    "label": "Marshall Islands",
                    "phone": "692"
                },
                {
                    "value": "MQ",
                    "code": "MQ",
                    "label": "Martinique",
                    "phone": "596"
                },
                {
                    "value": "MR",
                    "code": "MR",
                    "label": "Mauritania",
                    "phone": "222"
                },
                {
                    "value": "MU",
                    "code": "MU",
                    "label": "Mauritius",
                    "phone": "230"
                },
                {
                    "value": "YT",
                    "code": "YT",
                    "label": "Mayotte",
                    "phone": "262"
                },
                {
                    "value": "MX",
                    "code": "MX",
                    "label": "Mexico",
                    "phone": "52"
                },
                {
                    "value": "FM",
                    "code": "FM",
                    "label": "Micronesia, Federated States of",
                    "phone": "691"
                },
                {
                    "value": "MD",
                    "code": "MD",
                    "label": "Moldova, Republic of",
                    "phone": "373"
                },
                {
                    "value": "MC",
                    "code": "MC",
                    "label": "Monaco",
                    "phone": "377"
                },
                {
                    "value": "MN",
                    "code": "MN",
                    "label": "Mongolia",
                    "phone": "976"
                },
                {
                    "value": "ME",
                    "code": "ME",
                    "label": "Montenegro",
                    "phone": "382"
                },
                {
                    "value": "MS",
                    "code": "MS",
                    "label": "Montserrat",
                    "phone": "1-664"
                },
                {
                    "value": "MA",
                    "code": "MA",
                    "label": "Morocco",
                    "phone": "212"
                },
                {
                    "value": "MZ",
                    "code": "MZ",
                    "label": "Mozambique",
                    "phone": "258"
                },
                {
                    "value": "MM",
                    "code": "MM",
                    "label": "Myanmar",
                    "phone": "95"
                },
                {
                    "value": "NA",
                    "code": "NA",
                    "label": "Namibia",
                    "phone": "264"
                },
                {
                    "value": "NR",
                    "code": "NR",
                    "label": "Nauru",
                    "phone": "674"
                },
                {
                    "value": "NP",
                    "code": "NP",
                    "label": "Nepal",
                    "phone": "977"
                },
                {
                    "value": "NL",
                    "code": "NL",
                    "label": "Netherlands",
                    "phone": "31"
                },
                {
                    "value": "NC",
                    "code": "NC",
                    "label": "New Caledonia",
                    "phone": "687"
                },
                {
                    "value": "NZ",
                    "code": "NZ",
                    "label": "New Zealand",
                    "phone": "64"
                },
                {
                    "value": "NI",
                    "code": "NI",
                    "label": "Nicaragua",
                    "phone": "505"
                },
                {
                    "value": "NE",
                    "code": "NE",
                    "label": "Niger",
                    "phone": "227"
                },
                {
                    "value": "NG",
                    "code": "NG",
                    "label": "Nigeria",
                    "phone": "234"
                },
                {
                    "value": "NU",
                    "code": "NU",
                    "label": "Niue",
                    "phone": "683"
                },
                {
                    "value": "NF",
                    "code": "NF",
                    "label": "Norfolk Island",
                    "phone": "672"
                },
                {
                    "value": "MP",
                    "code": "MP",
                    "label": "Northern Mariana Islands",
                    "phone": "1-670"
                },
                {
                    "value": "NO",
                    "code": "NO",
                    "label": "Norway",
                    "phone": "47"
                },
                {
                    "value": "OM",
                    "code": "OM",
                    "label": "Oman",
                    "phone": "968"
                },
                {
                    "value": "PK",
                    "code": "PK",
                    "label": "Pakistan",
                    "phone": "92"
                },
                {
                    "value": "PW",
                    "code": "PW",
                    "label": "Palau",
                    "phone": "680"
                },
                {
                    "value": "PS",
                    "code": "PS",
                    "label": "Palestine, State of",
                    "phone": "970"
                },
                {
                    "value": "PA",
                    "code": "PA",
                    "label": "Panama",
                    "phone": "507"
                },
                {
                    "value": "PG",
                    "code": "PG",
                    "label": "Papua New Guinea",
                    "phone": "675"
                },
                {
                    "value": "PY",
                    "code": "PY",
                    "label": "Paraguay",
                    "phone": "595"
                },
                {
                    "value": "PE",
                    "code": "PE",
                    "label": "Peru",
                    "phone": "51"
                },
                {
                    "value": "PH",
                    "code": "PH",
                    "label": "Philippines",
                    "phone": "63"
                },
                {
                    "value": "PN",
                    "code": "PN",
                    "label": "Pitcairn",
                    "phone": "870"
                },
                {
                    "value": "PL",
                    "code": "PL",
                    "label": "Poland",
                    "phone": "48"
                },
                {
                    "value": "PT",
                    "code": "PT",
                    "label": "Portugal",
                    "phone": "351"
                },
                {
                    "value": "PR",
                    "code": "PR",
                    "label": "Puerto Rico",
                    "phone": "1"
                },
                {
                    "value": "QA",
                    "code": "QA",
                    "label": "Qatar",
                    "phone": "974"
                },
                {
                    "value": "RE",
                    "code": "RE",
                    "label": "Reunion",
                    "phone": "262"
                },
                {
                    "value": "RO",
                    "code": "RO",
                    "label": "Romania",
                    "phone": "40"
                },
                {
                    "value": "RU",
                    "code": "RU",
                    "label": "Russian Federation",
                    "phone": "7"
                },
                {
                    "value": "RW",
                    "code": "RW",
                    "label": "Rwanda",
                    "phone": "250"
                },
                {
                    "value": "BL",
                    "code": "BL",
                    "label": "Saint Barthelemy",
                    "phone": "590"
                },
                {
                    "value": "SH",
                    "code": "SH",
                    "label": "Saint Helena",
                    "phone": "290"
                },
                {
                    "value": "KN",
                    "code": "KN",
                    "label": "Saint Kitts and Nevis",
                    "phone": "1-869"
                },
                {
                    "value": "LC",
                    "code": "LC",
                    "label": "Saint Lucia",
                    "phone": "1-758"
                },
                {
                    "value": "MF",
                    "code": "MF",
                    "label": "Saint Martin (French part)",
                    "phone": "590"
                },
                {
                    "value": "PM",
                    "code": "PM",
                    "label": "Saint Pierre and Miquelon",
                    "phone": "508"
                },
                {
                    "value": "VC",
                    "code": "VC",
                    "label": "Saint Vincent and the Grenadines",
                    "phone": "1-784"
                },
                {
                    "value": "WS",
                    "code": "WS",
                    "label": "Samoa",
                    "phone": "685"
                },
                {
                    "value": "SM",
                    "code": "SM",
                    "label": "San Marino",
                    "phone": "378"
                },
                {
                    "value": "ST",
                    "code": "ST",
                    "label": "Sao Tome and Principe",
                    "phone": "239"
                },
                {
                    "value": "SA",
                    "code": "SA",
                    "label": "Saudi Arabia",
                    "phone": "966"
                },
                {
                    "value": "SN",
                    "code": "SN",
                    "label": "Senegal",
                    "phone": "221"
                },
                {
                    "value": "RS",
                    "code": "RS",
                    "label": "Serbia",
                    "phone": "381"
                },
                {
                    "value": "SC",
                    "code": "SC",
                    "label": "Seychelles",
                    "phone": "248"
                },
                {
                    "value": "SL",
                    "code": "SL",
                    "label": "Sierra Leone",
                    "phone": "232"
                },
                {
                    "value": "SG",
                    "code": "SG",
                    "label": "Singapore",
                    "phone": "65"
                },
                {
                    "value": "SX",
                    "code": "SX",
                    "label": "Sint Maarten (Dutch part)",
                    "phone": "1-721"
                },
                {
                    "value": "SK",
                    "code": "SK",
                    "label": "Slovakia",
                    "phone": "421"
                },
                {
                    "value": "SI",
                    "code": "SI",
                    "label": "Slovenia",
                    "phone": "386"
                },
                {
                    "value": "SB",
                    "code": "SB",
                    "label": "Solomon Islands",
                    "phone": "677"
                },
                {
                    "value": "SO",
                    "code": "SO",
                    "label": "Somalia",
                    "phone": "252"
                },
                {
                    "value": "ZA",
                    "code": "ZA",
                    "label": "South Africa",
                    "phone": "27"
                },
                {
                    "value": "GS",
                    "code": "GS",
                    "label": "South Georgia and the South Sandwich Islands",
                    "phone": "500"
                },
                {
                    "value": "SS",
                    "code": "SS",
                    "label": "South Sudan",
                    "phone": "211"
                },
                {
                    "value": "ES",
                    "code": "ES",
                    "label": "Spain",
                    "phone": "34"
                },
                {
                    "value": "LK",
                    "code": "LK",
                    "label": "Sri Lanka",
                    "phone": "94"
                },
                {
                    "value": "SD",
                    "code": "SD",
                    "label": "Sudan",
                    "phone": "249"
                },
                {
                    "value": "SR",
                    "code": "SR",
                    "label": "Suriname",
                    "phone": "597"
                },
                {
                    "value": "SJ",
                    "code": "SJ",
                    "label": "Svalbard and Jan Mayen",
                    "phone": "47"
                },
                {
                    "value": "SZ",
                    "code": "SZ",
                    "label": "Swaziland",
                    "phone": "268"
                },
                {
                    "value": "SE",
                    "code": "SE",
                    "label": "Sweden",
                    "phone": "46"
                },
                {
                    "value": "CH",
                    "code": "CH",
                    "label": "Switzerland",
                    "phone": "41"
                },
                {
                    "value": "SY",
                    "code": "SY",
                    "label": "Syrian Arab Republic",
                    "phone": "963"
                },
                {
                    "value": "TW",
                    "code": "TW",
                    "label": "Taiwan, Province of China",
                    "phone": "886"
                },
                {
                    "value": "TJ",
                    "code": "TJ",
                    "label": "Tajikistan",
                    "phone": "992"
                },
                {
                    "value": "TH",
                    "code": "TH",
                    "label": "Thailand",
                    "phone": "66"
                },
                {
                    "value": "TL",
                    "code": "TL",
                    "label": "Timor-Leste",
                    "phone": "670"
                },
                {
                    "value": "TG",
                    "code": "TG",
                    "label": "Togo",
                    "phone": "228"
                },
                {
                    "value": "TK",
                    "code": "TK",
                    "label": "Tokelau",
                    "phone": "690"
                },
                {
                    "value": "TO",
                    "code": "TO",
                    "label": "Tonga",
                    "phone": "676"
                },
                {
                    "value": "TT",
                    "code": "TT",
                    "label": "Trinidad and Tobago",
                    "phone": "1-868"
                },
                {
                    "value": "TN",
                    "code": "TN",
                    "label": "Tunisia",
                    "phone": "216"
                },
                {
                    "value": "TR",
                    "code": "TR",
                    "label": "Turkey",
                    "phone": "90"
                },
                {
                    "value": "TM",
                    "code": "TM",
                    "label": "Turkmenistan",
                    "phone": "993"
                },
                {
                    "value": "TC",
                    "code": "TC",
                    "label": "Turks and Caicos Islands",
                    "phone": "1-649"
                },
                {
                    "value": "TV",
                    "code": "TV",
                    "label": "Tuvalu",
                    "phone": "688"
                },
                {
                    "value": "UG",
                    "code": "UG",
                    "label": "Uganda",
                    "phone": "256"
                },
                {
                    "value": "UA",
                    "code": "UA",
                    "label": "Ukraine",
                    "phone": "380"
                },
                {
                    "value": "AE",
                    "code": "AE",
                    "label": "United Arab Emirates",
                    "phone": "971"
                },
                {
                    "value": "GB",
                    "code": "GB",
                    "label": "United Kingdom",
                    "phone": "44"
                },
                {
                    "value": "TZ",
                    "code": "TZ",
                    "label": "United Republic of Tanzania",
                    "phone": "255"
                },
                {
                    "value": "US",
                    "code": "US",
                    "label": "United States",
                    "phone": "1",
                    "suggested": true
                },
                {
                    "value": "UY",
                    "code": "UY",
                    "label": "Uruguay",
                    "phone": "598"
                },
                {
                    "value": "VI",
                    "code": "VI",
                    "label": "US Virgin Islands",
                    "phone": "1-340"
                },
                {
                    "value": "UZ",
                    "code": "UZ",
                    "label": "Uzbekistan",
                    "phone": "998"
                },
                {
                    "value": "VU",
                    "code": "VU",
                    "label": "Vanuatu",
                    "phone": "678"
                },
                {
                    "value": "VE",
                    "code": "VE",
                    "label": "Venezuela",
                    "phone": "58"
                },
                {
                    "value": "VN",
                    "code": "VN",
                    "label": "Vietnam",
                    "phone": "84"
                },
                {
                    "value": "WF",
                    "code": "WF",
                    "label": "Wallis and Futuna",
                    "phone": "681"
                },
                {
                    "value": "EH",
                    "code": "EH",
                    "label": "Western Sahara",
                    "phone": "212"
                },
                {
                    "value": "YE",
                    "code": "YE",
                    "label": "Yemen",
                    "phone": "967"
                },
                {
                    "value": "ZM",
                    "code": "ZM",
                    "label": "Zambia",
                    "phone": "260"
                },
                {
                    "value": "ZW",
                    "code": "ZW",
                    "label": "Zimbabwe",
                    "phone": "263"
                }
            ],
            width: 150,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // country
        {
            field: "updateDate",
            headerName: <>
                <ListItemIcon>
                    <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Modifié le</ListItemText>
                </ListItemIcon>
            </>,
            width: 180,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false,
            renderCell: (params) => {
                return (
                    <><UnixTimestampToDate unixTimestamp={params.value} /></>
                );
            },
        }, // updateDate
        {
            field: "adress1",
            headerName: <>
                <ListItemIcon>
                    <SignpostOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Adresse</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // adress1
        {
            field: "adress2",
            headerName: <>
                <ListItemIcon>
                    <SignpostOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Adr suite</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // adress2
        {
            field: "bp",
            headerName: <><Avatar sx={{ bgcolor: grey[600], width: 35, height: 35 }}>B.P</Avatar></>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // bp
        {
            field: "cp",
            headerName: <><Avatar sx={{ bgcolor: grey[600], width: 35, height: 35 }}>C.P</Avatar></>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // cp
        {
            field: "city",
            headerName: <>
                <ListItemIcon>
                    <LocationCityOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Ville</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // city
        {
            field: "firstName",
            headerName: <>
                <ListItemIcon>
                    <PersonOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Prénom</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // firstName
        {
            field: "lastName",
            headerName: <>
                <ListItemIcon>
                    <PersonOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Nom</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // lastName
        {
            field: "info",
            headerName: <>
                <ListItemIcon>
                    <PersonOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Information</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // info
        {
            field: "salesRepresent",
            headerName: <>
                <ListItemIcon>
                    <AssignmentIndOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Commercial</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // salesRepresent
        {
            field: "stateProvince",
            headerName: <>
                <ListItemIcon>
                    <FlagOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>État / Province</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // stateProvince
        {
            field: "licenceKey",
            headerName: <>
                <ListItemIcon>
                    <FlagOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
                    <ListItemText>Licence</ListItemText>
                </ListItemIcon>
            </>,
            width: 120,
            editable: false,
            sortable: true,
            filterable: true,
            groupable: true,
            aggregable: true,
            disableExport: false,
            hide: false
        }, // licenceKey
    ]

    const updateFormTest = (data, RAF) => {
        console.log('ClientsList -> updateFormTest -> data : ' + data + ' - RAF : ' + RAF);
    }

    return (

        <View style={{ paddingBottom: '15%', backgroundColor: '#ffffff', alignSelf: 'center', width: '100%' }}>
            <Loader loading={loading} />

            <Box onClick={() => { addFormClientModal(id) }} sx={{
                width: { marginLeft: 5, marginTop: 5 },
                // ml: { sm: `${drawerWidth}px` },
            }}>
                <Fab color="secondary" aria-label="add">
                    <AddCircle />

                </Fab>
            </Box>
            <Box sx={{ width: 1 }}>

                <DataGrid
                    columns={MGDefineCustomerColumns}
                    //columns={data != null ? data.columns : [{ field: 'username' }]}
                    rows={data != null ? data.rows : rowsTmp}
                    showColumnVerticalBorder={true}
                    editMode='row'
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    initialState={tmpInitialState}
                />
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={closeModalRefreshList}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <ExitToApp
                            style={{ position: 'absolute', top: '2%', right: '2%', width: '6%', height: '6%', color: '#ffffff' }}
                            onClick={() => { closeModalRefreshList() }} />
                        <CardMedia
                            component='img'
                            height='194'
                            image={require('../assets/Logo.jpg')}
                            alt={clientName} />
                        <Typography id="transition-modal-title" variant="h5" component="h2">
                            {clientName}
                        </Typography>
                        <ScrollView style={{ height: '70%', padding: 5 }}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}>

                            {whichForm}
                        </ScrollView>

                    </Box>
                </Fade>
            </Modal>
        </View>
    )

}

const style = {
    position: 'absolute',
    top: '5%',
    left: '5%',
    // transform: 'translate(-50%, -50%)',
    width: '88%',
    height: '88%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden'
};
const rowsTmp = [
    {
        _id: "1", // 1
        custoId: "1", // 2
        id: "1", // 3
        parentID: "1", // 4
        entryDate: "1", // 5
        updateDate: "1", // 6
        login: "1", // 7
        passWord: "1", // 8
        email: "1", // 9
        firstName: "1", // 10
        lastName: "1", // 11
        ceoName: "1", // 12
        phonFixe: "1", // 13
        phoneMobile: "1", // 14
        active: true, // 15
        cp: "1", // 16
        bp: "1", // 17
        adress1: "1", // 18
        adress2: "1", // 19 
        city: "1", // 20
        stateProvince: "1", // 21
        profile: "1", // 22
        name: "1", // 23
        identificationNumber: "1", // 24
        sendDoc: "1", // 25
        deadLineInvoicePayment: "1", // 26
        salesRepresent: "1", // 27
        info: "1", // 28
        licenceKey: "1", // 29
        ticketsList: "1" // 30
    }
]


export default ClientsList