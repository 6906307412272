import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { StyleSheet, View, ScrollView, Keyboard, TouchableOpacity, KeyboardAvoidingView, Image, SafeAreaView, Dimensions, TextInput, FlatList } from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Text } from 'react-native-paper'
import { useForm, Controller, useWatch } from 'react-hook-form';

import {
  TextField, Box, Switch, Button, Grid, Paper, 
  InputAdornment, styled, Typography, alpha
} from '@mui/material';

import {
  AccountCircle, StoreRounded,
  LocalPostOfficeRounded, MarkunreadMailboxRounded, BusinessCenterRounded,
  FlagRounded, NumbersRounded, PendingActionsRounded, ContactPageRounded, HelpRounded,
  AlternateEmailRounded, HowToRegRounded, PersonOffRounded, ErrorOutlineRounded, KeyRounded
} from '@mui/icons-material';
// import { alpha, styled } from '@mui/material/styles';
import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import MuiPhoneNumber from 'material-ui-phone-number';


import { Link } from 'react-router-dom';

import moment from 'moment';

// import { styles } from '../Styles/style';
import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap } from '../Utils/Helper';
// Import Components
import Loader from '../Components/Loader';
import SnackBarManager from './SnackBarManager';
import MGModal from './MGModal';
import { InputBase } from '@material-ui/core';
import Constants from 'expo-constants';
import APIController from './APIController';

// import ClientsList from '../Pages/ClientsList';



const FormClients = ({ idClients }) => {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [switchActive, setSwitchActive] = React.useState();
  const [currentProfile, setCurrentProfile] = useState('')
  const [currentID, setCurrentID] = useState('')
  const [getUserCanActive, setUserCanActive] = React.useState()

  const [messageDisplayed, setMessageDisplayed] = useState('')

  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [phonFixe, setPhonFixe] = useState('');
  const [phoneMobile, setPhoneMobile] = useState('');
  const [cp, setCP] = useState('');
  const [bp, setBP] = useState('');
  const [adress1, setAdress1] = useState('');
  const [adress2, setAdress2] = useState('');
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState('')
  const [profile, setProfile] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [deadLineInvoicePayment, setDeadLineInvoicePayement] = useState('');
  const [salesRepresent, setSalesRepresent] = useState('');
  const [licenceKey, setLicenceKey] = useState('');
  const [info, setInfo] = useState('');



  const [formWasUpdated, setFormWasUpdated] = useState(false)

  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
    values: {
      name: name,
      login: login,
      name: name,
      email: email,
      firstName: firstName,
      lastName: lastName,
      ceoName: ceoName,
      phonFixe: phonFixe,
      phoneMobile: phoneMobile,
      cp: cp,
      bp: bp,
      adress1: adress1,
      adress2: adress2,
      city: city,
      stateProvince: stateProvince,
      profile: profile,
      identificationNumber: identificationNumber,
      deadLineInvoicePayment: deadLineInvoicePayment,
      salesRepresent: salesRepresent,
      info: info,
    }
  });



  const params = {
    userId: idClients
  };

  const getClientsDetail = async () => {
    console.log('FormClients -> getClientsDetail');
    await APIMainRequest('/MGUsers/dh/list', params).then((value) => {
      console.log('FormClients -> getClientsDetail -> Then -> Name : ' + value.getData.name);
      console.log('FormClients -> getClientsDetail -> Then -> Login : ' + value.getData.login);
      var getClientsInfo = value.getData
      setData(getClientsInfo)
      const formattedDate = moment.unix(getClientsInfo.entryDate).format('DD/MM/YYYY');

      setID(value.getData._id)
      setName(value.getData.name)
      setLogin(value.getData.login)
      setEmail(value.getData.email)
      setFirstName(value.getData.firstName);
      setLastName(value.getData.lastName);
      setCeoName(value.getData.ceoName);
      setPhonFixe(value.getData.phonFixe);
      setPhoneMobile(value.getData.phoneMobile);
      setCP(value.getData.cp);
      setBP(value.getData.bp);
      setAdress1(value.getData.adress1);
      setAdress2(value.getData.adress2);
      setCity(value.getData.city);
      setStateProvince(value.getData.stateProvince)
      setProfile(value.getData.profile);
      setIdentificationNumber(value.getData.identificationNumber);
      setDeadLineInvoicePayement(value.getData.deadLineInvoicePayment);
      setSalesRepresent(value.getData.salesRepresent);
      setLicenceKey(value.getData.licenceKey);
      setInfo(value.getData.info);
      setSwitchActive(value.getData.active)
      console.log('FormClients -> getClientsDetail -> Then -> formattedDate : ' + formattedDate);


    }).finally(() => {
      getData('MGUserSetting').then(function (value) {
        console.log('FormClients -> getClientsDetail -> getData -> MGUserSetting -> : ' + value.entryDate)
        return value
      }).then(function (myValue) {

        console.log('FormClients -> getClientsDetail -> getData -> MGUserSetting ->  : ' + myValue.entryDate)
        setUserCanActive(myValue.setUserCanActive)

      })
    })
  };

  useEffect(() => {
    console.log('FormClients -> useEffect');
    getClientsDetail()

    getDeviceTypeAsync().then((deviceType) => {
      console.log('FormClients -> useEffect -> Device : ' + deviceTypeMap[deviceType]);

    });

    getData('MGUserInfo').then((value) => {
      console.log('FormClients -> useEffect -> getData -> MGUserInfo -> MGGetUserId : ' + value.MGGetUserId)
      setCurrentProfile(value.profile)
      setCurrentID(value.MGGetUserId)

    })
  }, []);

  const onSubmit = (formData) => {

    console.log('FormClients -> onSubmit -> formData.firstName : ' + formData.name + " - Login : " + formData.login);
    const updateParm = {
      id: id,
      name: formData.name,
      login: formData.login,
      name: formData.name,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      ceoName: formData.ceoName,
      phonFixe: formData.phonFixe,
      phoneMobile: formData.phoneMobile,
      cp: formData.cp,
      bp: formData.bp,
      adress1: formData.adress1,
      adress2: formData.adress2,
      city: formData.city,
      stateProvince: formData.stateProvince,
      profile: formData.profile,
      identificationNumber: formData.identificationNumber,
      deadLineInvoicePayment: formData.deadLineInvoicePayment,
      salesRepresent: formData.salesRepresent,
      info: formData.info,
      active: switchActive
    }
    updateClient(updateParm).then((value) => {
      console.log('FormClients -> onSubmit -> updateClient -> Then : ' + value);
    }).finally(() => {
      console.log('FormClients -> onSubmit -> updateClient -> Finally');

      setMessageDisplayed('Mise à jour des informations terminé')
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 5000);
    })
  };

  const updateClient = async (params) => {

    const resultRequest = await APIMainRequest('/MGUsers/dh/update/', params)

    console.log('FormClients -> onSubmit -> ' + resultRequest.getData.success + ' - getData -> ' + resultRequest.getData.message)

    if (resultRequest.getData.success) {
      setLoading(false)

    } else {
      setLoading(false)
    }
  }

  const addLicenceKey = async () => {
    console.log('FormClients -> addLicenceKey ->');
    const param = {
      userID: id,
    }
    await APIMainRequest('/MGUsers/dh/gen/api', param).then((value) => {
      console.log('FormClients -> addLicenceKey -> Generate Licence Key -> Then : ' + value.getData.success);
      return value
    }).then(function (myValue) {
      console.log('FormClients -> addLicenceKey -> Generate Licence Key -> Then 2 : ' + myValue.getData.success);

      if (myValue.getData.success == true) {
        getClientsDetail()
      }
    })

  }

  const updateActiveUser = () => {
    console.log('FormClients -> updateActiveUser')

    var finalState = switchActive == true ? false : true
    setSwitchActive(finalState)
  }

  const setFormUpdate = (fieldValue, whichField) => {
    // console.log('FormClients -> Form Client was updated !')
    setFormWasUpdated(true)
    if (whichField == 'email') {
      // console.log('FormClients -> Form Client was updated -> Email : ' + fieldValue)
      if (validateEmail(fieldValue)) {
        // console.log('FormClients -> Form Client was updated -> Email is validde ')

      }
    }
  }

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const MGSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[700],
      '&:hover': {
        backgroundColor: alpha(green[700], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[700],
    },
  }));
  const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  return (


    <View style={{ backgroundColor: '#ffffff', margin: 10 }}>

      <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType="success" />
      <Loader loading={loading} />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 4, md: 4 }} columns={{ xs: 2, sm: 2, md: 6 }}>
          <Grid item xs={2} sm={2} md={3}>
            <Item>
              {data.active == true ?
                <>
                  <Typography variant="body1">
                    <HowToRegRounded fontSize='large' sx={{ color: green[700] }} /> Utilisateur actif
                  </Typography>
                </>
                :
                <>
                  <Typography variant="body1" gutterBottom>
                    <PersonOffRounded fontSize='large' color='disabled' /> Utilisateur non activé
                  </Typography>
                </>}
              {idClients == currentID ?
                <></>
                :
                currentProfile == 'root' || currentProfile == 'admin' || getUserCanActive == true ?
                  <Typography variant="body1" gutterBottom>
                    <MGSwitch
                      checked={switchActive}
                      onChange={() => { updateActiveUser() }}
                      {...label}
                    />
                    {switchActive == true ? "Désactiver cet utilisateur" : "Activer cet utilisateur"}
                  </Typography>
                  :
                  currentProfile == 'user' && getUserCanActive == true ?
                    <Typography variant="body1" gutterBottom>
                      <MGSwitch
                        checked={switchActive}
                        onChange={() => { updateActiveUser() }}
                        {...label}
                      />
                      {switchActive == true ? "Désactiver cet utilisateur" : "Activer cet utilisateur"}
                    </Typography>
                    :
                    <></>
              }

              <br />
              <Text variant="bodyMedium">Date création : {moment.unix(data.entryDate).format('DD/MM/YYYY à HH:mm')}</Text>
              <br />
              <Text variant="bodyMedium">Date dernière modif : {moment.unix(data.updateDate).format('DD/MM/YYYY à HH:mm')}</Text>
              <br />
              <br />
              <form onSubmit={handleSubmit(onSubmit)}>

                <Controller name="login" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField disabled label="Login" id="standard-basic" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                    />
                  )}
                /><br /><br />

                <Controller name="name" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Nom de la compagnie" id="name" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'name')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <StoreRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.name && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le nom de la compagnie est obligatoire.
                </Typography>}<br /><br />

                <Controller name="email" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Corriel de la compagine" id="email" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'email')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.email && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le courriel est obligatoire.
                </Typography>}<br /><br />

                <Controller name="firstName" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Prénom" id="firstName" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'firstName')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.firstName && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le prénom est obligatoire.
                </Typography>}<br /><br />

                <Controller name="lastName" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Nom de famille" id="lastName" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'lastName')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.lastName && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le nom est obligatoire.
                </Typography>}<br /><br />

                <Controller name="ceoName" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Nom Prénom du propriétaire" id="ceoName" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'ceoName')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.ceoName && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le nom et prénom du propriétaire est obligatoire.
                </Typography>}<br /><br />

                <Controller name="phonFixe" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MuiPhoneNumber defaultCountry={'ca'} label="Téléphone fixe" id="phonFixe" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'phonFixe')}
                    // sx={{ m: 1, width: '48ch' }}

                    />
                  )}
                /> {errors.phonFixe && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le numéro de téléphone est obligatoire.
                </Typography>}

                <Controller name="phoneMobile" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MuiPhoneNumber defaultCountry={'ca'} label="Numéro téléphone mobile" id="phoneMobile" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'phonFixe')}
                    // sx={{ m: 1, width: '48ch' }}

                    />
                  )}
                /><br /><br />

                <Controller name="cp" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Code postal" id="cp" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'cp')}
                      // sx={{ m: 1, width: '48ch' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPostOfficeRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.cp && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le code postal est obligatoire.
                </Typography>}

                <Controller name="bp" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Boite postal" id="bp" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'bp')}
                      // sx={{ m: 1, width: '48ch' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MarkunreadMailboxRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Controller name="adress1" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Adresse postale de la compagnie" id="adress1" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'adress1')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BusinessCenterRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.adress1 && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Une adresse postal est obligatoire.
                </Typography>}<br /><br />

                <Controller name="adress2" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Suite de l'adresse" id="adress2" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'adress2')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BusinessCenterRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Controller name="city" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Ville" id="city" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'city')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPostOfficeRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.city && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Veuillez indiquer une ville.
                </Typography>}<br /><br />

                <Controller name="stateProvince" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Etat / province" id="stateProvince" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'stateProvince')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FlagRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Controller name="profile" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField disabled label="Profile" id="profile" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'profile')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Controller name="identificationNumber" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Numéro d'identification de la compagnie" id="identificationNumber" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'identificationNumber')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.identificationNumber && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Le numéro d'identification est obligatoire.
                </Typography>}<br /><br />

                <Controller name="deadLineInvoicePayment" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Délais de paiement (jours)" id="deadLineInvoicePayment" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'deadLineInvoicePayment')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PendingActionsRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Controller name="salesRepresent" control={control} rules={{ required: true, }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Nom Prénom contact commercial" id="salesRepresent" variant="standard" fullWidth
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'salesRepresent')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ContactPageRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> {errors.salesRepresent && <Typography variant="body2" sx={{ color: red[900] }}>
                  <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                  Un contact commercial est obligatoire.
                </Typography>}<br /><br />

                <Controller name="info" control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField label="Informations complémentaire" id="info" variant="standard" fullWidth
                      multiline
                      rows={5}
                      onBlur={onBlur} onChange={onChange} value={value}
                      onFocus={setFormUpdate(value, 'info')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HelpRounded />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /><br /><br />

                <Button type="submit" variant="contained" size="large" sx={{ backgroundColor: purple[700] }}>
                  Enregistrer
                </Button>
              </form>

            </Item>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Item>

              {
                currentProfile == 'root' && licenceKey == '' || licenceKey == null ?
                  <>

                    <Button variant="contained" endIcon={<KeyRounded fontSize='large' />}
                      onClick={() => { addLicenceKey() }} sx={{ backgroundColor: purple[700] }}>
                      Ajouter une cléf API
                    </Button><br/><br />
                  </>
                  :
                  <></>
              }

              <APIController idClients={idClients} />

            </Item>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Item>3</Item>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Item>4</Item>
          </Grid>
        </Grid>
      </Box>


    </View>

  )

}


export default FormClients


