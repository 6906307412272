import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  StyleSheet, TextInput, View, Text, ScrollView, Keyboard, TouchableOpacity,
  KeyboardAvoidingView, SafeAreaView, Dimensions, FlatList, WebView
} from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Share } from 'react-native';
import { useForm, Controller, useWatch } from 'react-hook-form';

import { styled, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import {
  Input, InputLabel, Box, MenuItem, Switch,
  Button, Stack, IconButton,
  InputAdornment, Paper,
  Typography,
  Fade, Modal, Backdrop, CardMedia, CircularProgress,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';

import {
  Delete, ModeEditRounded, CreateNewFolderOutlined,
  Settings, ContactMailOutlined, GppGoodOutlined,
  MarkEmailUnreadOutlined, NotificationsActiveOutlined, NotificationsOffOutlined,
  LockClockOutlined, DnsOutlined, SettingsEthernetOutlined, Visibility, VisibilityOff, TuneOutlined, CategoryOutlined,
  PermIdentityOutlined, PersonOffOutlined, ExpandMoreOutlined, ChevronRightOutlined
} from '@mui/icons-material';

import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, useTreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';


import { Masonry } from '@mui/lab';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap } from '../Utils/Helper';
import SnackBarManager from '../Components/SnackBarManager';
import DepartementManager from '../Components/SettingsPage/DepartementManager';

const SettingsPage = ({ navigation }) => {

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(0),
    textAlign: 'left',
    // color: theme.palette.text.secondary,
  }));

  const [id, setID] = useState('');

  const [loading, setLoading] = useState(false)
  const [userConnected, setUserConnected] = React.useState();
  const [userInfo, setUserInfo] = React.useState();
  const [messageDisplayed, setMessageDisplayed] = useState('')
  const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')
  const [open, setOpen] = React.useState(false);

  ///////// To manage mail settings
  const [switchMailFormEnable, setSwitchMailFormEnable] = React.useState(false);
  const [mailFormEnable, setMailFormEnable] = React.useState(true);

  const [showPassword, setShowPassword] = React.useState(false);
  const [getMailData, setMailData] = React.useState()

  const [mailPopUser, setMailPopUser] = useState('');
  const [mailPopPassword, setMailPopPassword] = useState('');
  const [mailPopHost, setMailPopHost] = useState('');
  const [mailPopPort, setMailPopPort] = useState('');
  const [mailPopTls, setMailPopTls] = useState(); // true/false
  const [mailPopCheckFreq, setMailPopCheckFreq] = useState(''); // Int 1 for each minutes
  const [mailNotification, setMailNotification] = useState(); // true/false
  ///////// To manage mail settings


  ///////// To manage general settings
  const [switchAuthFormEnable, setSwitchAuthFormEnable] = React.useState(false);
  const [authFormEnable, setAuthFormEnable] = React.useState(true);

  const [userCanActive, setUserCanActive] = React.useState();
  const [userCanManageSubject, setUserCanManageSubject] = React.useState();

  ///////// To manage general settings

  ///////// To manage DEPARTEMENTS
  const [switchDepMgtFormEnable, setSwitchDepMgtFormEnable] = React.useState(false)
  const [depMgtFormEnable, setDepMgtFormEnable] = React.useState(true)
  const [expanded, setExpanded] = React.useState(false);

  const [idMainDepSelected, setIdMainDepSelected] = React.useState()
  const [idLV1DepSelected, setIdLV1DepSelected] = React.useState()
  const [idLV2DepSelected, setIdLV2DepSelected] = React.useState()
  const [idLV3DepSelected, setIdLV3DepSelected] = React.useState()

  const [whichActionSelected, setWhichActionSelected] = React.useState()
  const [whichNameSelected, setWhichNameSelected] = React.useState()
  const [whichDescSelected, setWhichDescSelected] = React.useState()
  const [whichContactSelected, setWhichContactSelected] = React.useState()
  const [whichLevelSelected, setWhichLevelSelected] = React.useState()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [getDepList, setDepList] = React.useState([])
  const [getDepListChildV1, setDepListChildV1] = React.useState([])

  const [subjectName, setSubjectName] = React.useState()
  const [subjectDescription, setSubjectDescription] = React.useState()
  const [contactRef, setContactRef] = React.useState()


  ///////// To manage DEPARTEMENTS

  const [openDepModal, setOpenDepModal] = React.useState(false);
  const handleOpen = () => setOpenDepModal(true);
  const handleClose = () => setOpenDepModal(false);

  const [openDepModalConfDelete, setOpenDepModalConfDelete] = React.useState(false);
  const handleOpenConfDelete = () => setOpenDepModalConfDelete(true);
  const handleCloseConfDelete = () => setOpenDepModalConfDelete(false);
  const [modalSettingToDelete, setModalSettingToDelete] = React.useState([]);

  const closeModalRefreshList = () => {
    console.log('ClientsList -> closeModalRefreshList - ID ' + id)
    handleClose()

    executeRequest()
  }

  const executeRequest = () => {

    getData('MGUserInfo').then((value) => {
      console.log('SettingScreen -> useEffect -> getData -> MGUserInfo -> profile : ' + value.profile)

      if (value === null) {

        navigation.navigate('home')
        setUserConnected(false)

      } else {

        var IDForGetSettings

        if (value.profile == 'customer') {
          console.log('SettingScreen -> customer -> GO TO HOME SCREEN ')
          navigation.navigate('HomeScreen')
        } else {

          if (value.profile == 'root' || value.profile == 'admin') {
            IDForGetSettings = value.MGGetUserId
          } else {
            IDForGetSettings = value.parentID
          }

          setID(IDForGetSettings)

          const settingsParams = {
            clientID: IDForGetSettings,
          };
          console.log('SettingScreen -> settingsParams -> ' + JSON.stringify(settingsParams, null, 2))

          APIMainRequest('/MGUsers/settings/dh/list', settingsParams).then(function (response) {

            return response.getData

          }).then(function (myJson) {


            console.log('SettingScreen -> resultSettingsRequest -> isSuccess -> ' + myJson)
            console.log('SettingScreen -> resultSettingsRequest -> message -> ' + myJson.message)
            console.log('SettingScreen -> resultSettingsRequest -> settings -> ' + JSON.stringify(myJson.settings.settingMail.mailNotification, null, 2))

            ////////// To Retreive all settings for mail box
            var defineSettingMail = myJson.settings.settingMail

            setMailPopUser(defineSettingMail.mailPopUser)
            setMailPopPassword(defineSettingMail.mailPopPassword)
            setMailPopHost(defineSettingMail.mailPopHost)
            setMailPopPort(defineSettingMail.mailPopPort)
            setMailPopTls(defineSettingMail.mailPopTls)
            setMailPopCheckFreq(defineSettingMail.mailPopCheckFreq)
            setMailNotification(defineSettingMail.mailNotification)
            ////////// To Retreive all settings for mail box

            ////////// To Retreive general settings
            var defineSettingGeneral = myJson.settings.settingAutorization
            setUserCanActive(defineSettingGeneral.setUserCanActive)
            setUserCanManageSubject(defineSettingGeneral.setUserCanManageSubject)
            ////////// To Retreive general settings

            const settingsParams = {
              clientID: IDForGetSettings,
            }
            APIMainRequest('/MGSubject/list', settingsParams).then(function (response) {

              return response.getData

            }).then(function (myJson) {

              console.log('DepartementMGT -> resultSettingsRequest -> ' + JSON.stringify(myJson, null, 2))

              setDepList(myJson)


            }).finally(() => {
              console.log('DepartementMGT -> DepartementMGT -> Finally : ')

            })


          }).finally(() => {
            console.log('SettingScreen -> customer -> GO TO HOME SCREEN ')

          })
        }

      }

    })

  }

  ///////// To manage mail settings
  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
    values: {
      mailPopUser: mailPopUser,
      mailPopPassword: mailPopPassword,
      mailPopHost: mailPopHost,
      mailPopPort: mailPopPort,
      mailPopTls: mailPopTls,
      mailPopCheckFreq: mailPopCheckFreq,
      mailNotification: mailNotification
    }
  });

  const onSubmit = (formData) => {

    console.log('SettingScreen -> onSubmit -> Login : ' + formData.mailPopUser);
    const updateParm = {
      clientID: id,
      mailPopUser: formData.mailPopUser,
      mailPopPassword: formData.mailPopPassword,
      mailPopHost: formData.mailPopHost,
      mailPopPort: formData.mailPopPort,
      mailPopTls: formData.mailPopTls,
      mailPopCheckFreq: formData.mailPopCheckFreq,
      mailNotification: formData.mailNotification,
    }
    console.log('SettingScreen -> onSubmit -> updateParm : ' + JSON.stringify(updateParm, null, 2));

    updateMailParam(updateParm).then((value) => {

      console.log('SettingScreen -> onSubmit -> updateClient -> Then : ' + value);

    }).finally(() => {

      console.log('SettingScreen -> onSubmit -> updateClient -> Finally');



      handleMailFormEnable()

      setOpen(true)

      setTimeout(() => {

        setOpen(false)

      }, 5000);
    })
  };

  const updateMailParam = async (params) => {
    setLoading(true)
    const resultRequest = await APIMainRequest('/MGUsers/settings/dh/update/mail', params)

    console.log('SettingScreen -> onSubmit -> ' + resultRequest.getData.success + ' - getData -> ' + resultRequest.getData.message)

    if (resultRequest.getData.success) {
      setLoading(false)
      setMessageTypeDisplayed('success')
      setMessageDisplayed('Mise à jour des paramètres de la boite mail terminé')
    } else {
      setLoading(false)
      setMessageTypeDisplayed('error')
      setMessageDisplayed('Erreur de la mise à jour des paramètres de la boite mail')
    }
  }

  const handleChangeMailNotification = () => {
    setMailNotification(!mailNotification)
  }

  const handleChangeMailPopTls = () => {
    setMailPopTls(!mailPopTls)
  }

  const handleChangeMailPopCheckFreq = (event) => {
    console.log('SettingScreen -> handleChangeMailPopCheckFreq : ' + event.target.value)
    setMailPopCheckFreq(event.target.value)
  }

  const handleMailFormEnable = () => {
    setMailFormEnable(!mailFormEnable)
    setSwitchMailFormEnable(!switchMailFormEnable)
    if (showPassword == true) {
      setShowPassword(false)
    }
  }
  ///////// To manage mail settings


  ///////// To manage general settings

  const updategeneralParameter = async () => {
    setLoading(true)

    const updateParm = {
      clientID: id,
      setUserCanActive: userCanActive,
      setUserCanManageSubject: userCanManageSubject,
    }

    const resultRequest = await APIMainRequest('/MGUsers/settings/dh/update/general', updateParm)

    console.log('SettingScreen -> onSubmit -> ' + resultRequest.getData.success)

    if (resultRequest.getData.success) {
      setLoading(false)
      setMessageTypeDisplayed('success')
      setMessageDisplayed('Mise à jour des paramètres généraux est terminé')
    } else {
      setLoading(false)
      setMessageTypeDisplayed('error')
      setMessageDisplayed('Erreur de la mise à jour des paramètres généraux')
    }
    handleAuthFormEnable()

    setOpen(true)

    setTimeout(() => {

      setOpen(false)

    }, 5000);
  }

  const handleAuthFormEnable = () => {
    setAuthFormEnable(!authFormEnable)
    setSwitchAuthFormEnable(!switchAuthFormEnable)

  }

  const handleChangeUserCanActive = () => { setUserCanActive(!userCanActive) }
  const handleChangeUserCanManageSubject = () => { setUserCanManageSubject(!userCanManageSubject) }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  ///////// To manage general settings

  ///////// To manage DEPARTEMENTS

  const handleDepMgtFormEnable = () => {
    setDepMgtFormEnable(!depMgtFormEnable)
    setSwitchDepMgtFormEnable(!switchDepMgtFormEnable)
  }

  ////////////////// MAIN DEP //////////////////
  const handleChangeDepSubjectName = (event) => {
    console.log('DepartementMGT -> handleChangeDepSubjectName -> ' + event.target.value)
    setSubjectName(event.target.value);
  };

  const handleChangeDepSubjectDescription = (event) => {
    console.log('DepartementMGT -> handleChangeDepSubjectDescription -> ' + event.target.value)
    setSubjectDescription(event.target.value);
  };

  const handleChangeDepContactRef = (event) => {
    console.log('DepartementMGT -> handleChangeDepContactRef -> ' + event.target.value)
    setContactRef(event.target.value);
  };
  ////////////////// MAIN DEP //////////////////

  const askConfToDelete = (name, desc, contact, idMain, idLV1, idLV2, idLV3, level) => {
    
    setModalSettingToDelete([name, desc, contact, idMain, idLV1, idLV2, idLV3, level])
    handleOpenConfDelete()

  }

  const ConfDeleteResponse = () => {
    delteDepartement(modalSettingToDelete[3], modalSettingToDelete[4], modalSettingToDelete[5], modalSettingToDelete[6], modalSettingToDelete[7])
  }

  const delteDepartement = (idMain, idLV1, idLV2, idLV3, level) => {
    
    switch (level) {
      case '1':
        console.log('DepartementManager -> DelteDepartement -> level -> ' + level)
        deleteMainDep(idMain, idLV1, idLV2, idLV3, level)
        break

      case '2':
        console.log('DepartementManager -> DelteDepartement -> level -> ' + level)
        deleteLV1Dep(idMain, idLV1, idLV2, idLV3, level)
        break

      case '3':
        console.log('DepartementManager -> DelteDepartement -> level -> ' + level)
        deleteLV2Dep(idMain, idLV1, idLV2, idLV3, level)
        break

      case '4':
        console.log('DepartementManager -> DelteDepartement -> level -> ' + level)
        deleteLV3Dep(idMain, idLV1, idLV2, idLV3, level)
        break

    }
  }

  const deleteMainDep = (idMain, idLV1, idLV2, idLV3, level) => {
    console.log('DepartementManager -> DelteMainDep -> clientID : ' + idMain)
    console.log('DepartementManager -> DelteMainDep -> level -> ' + level)

    const settingsParams = {
      clientID: id,
      subjectID: idMain,
    }

    console.log('DepartementManager -> DelteMainDep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/deleteMain', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> DelteMainDep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> DelteMainDep -> Finally ')
      closeModalRefreshList()
      handleCloseConfDelete()
    })
  }

  const deleteLV1Dep = (idMain, idLV1, idLV2, idLV3, level) => {
    console.log('DepartementManager -> DelteLV1Dep -> idMainDep : ' + idMain)
    console.log('DepartementManager -> DelteLV1Dep -> level -> ' + level)

    const settingsParams = {
      clientID: id,
      subjectID: idMain,
      subjectIDCHV1: idLV1
    }
    console.log('DepartementManager -> DelteLV1Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/deleteLevel1', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> DelteLV1Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> DelteLV1Dep -> Finally ')
      closeModalRefreshList()
      handleCloseConfDelete()
    })
  }

  const deleteLV2Dep = (idMain, idLV1, idLV2, idLV3, level) => {
    console.log('DepartementManager -> DelteLV2Dep -> idLV1Dep : ' + idLV1)
    console.log('DepartementManager -> DelteLV2Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMain,
      idMainLevel: idMain,
      subjectIDCHV1: idLV1,
      subjectIDCHV2: idLV2,
    }

    console.log('DepartementManager -> DelteLV2Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/deleteLevel2', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> DelteLV2Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> DelteLV2Dep -> Finally ')
      closeModalRefreshList()
      handleCloseConfDelete()
    })
  }

  const deleteLV3Dep = (idMain, idLV1, idLV2, idLV3, level) => {
    console.log('DepartementManager -> DelteLV3Dep -> idLV2Dep : ' + idLV2)
    console.log('DepartementManager -> DelteLV3Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMain,
      idMainLevel: idMain,
      subjectIDCHV1: idLV1,
      subjectIDCHV2: idLV2,
      subjectIDCHV3: idLV3,
    }

    console.log('DepartementManager -> DelteLV3Dep -> SettingsParams -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/deleteLevel3', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> DelteLV3Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> DelteLV3Dep -> Finally ')
      closeModalRefreshList()
      handleCloseConfDelete()
    })
  }

  ///////// To manage DEPARTEMENTS


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setFormUpdate = (fieldValue, whichField) => {
    // console.log('LoginScreen -> Form Client was updated !')

    if (whichField == 'email') {
      // console.log('LoginScreen -> Form Client was updated -> Email : ' + fieldValue)
      if (validateEmail(fieldValue)) {
        // console.log('LoginScreen -> Form Client was updated -> Email is validde ')

      }
    }
  }

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  useEffect(() => {
    console.log('SettingScreen -> useEffect');

    getDeviceTypeAsync().then((deviceType) => {
      console.log('SettingScreen -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }

    });

    getData('MGUserInfo').then((value) => {
      console.log('SettingScreen -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)


      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        // navigation.navigate('HomeScreen')
        // setID(value.MGGetUserId)
        // userInfoRequest(value.MGGetUserId)
        setUserConnected(true)
        setUserInfo(value)
        // executeRequestDepList()
        executeRequest()


      }
    })

  }, []);

  const toOpenDepModal = (idMain, idLV1, idLV2, idLV3, level, whicthAction, name, desc, contact) => {
    console.log('SettingScreen -> toOpenDepModal : idMain ' + idMain);
    console.log('SettingScreen -> toOpenDepModal : level ' + level);
    console.log('SettingScreen -> toOpenDepModal : whicthAction ' + whicthAction);

    setIdMainDepSelected(idMain)
    setIdLV1DepSelected(idLV1)
    setIdLV2DepSelected(idLV2)
    setIdLV3DepSelected(idLV3)

    setWhichActionSelected(whicthAction)
    setWhichNameSelected(name)
    setWhichDescSelected(desc)
    setWhichContactSelected(contact)
    setWhichLevelSelected(level)
    handleOpen()
  }

  const contentTreeItemLabel = (idMain, idLV1, idLV2, idLV3, level, numberSubDep, name, desc, contact) => {
    var setupLabel = name + ' - ' + numberSubDep + ' départements - Contact : ' + contact
    return (
      <>
        <Box>

          <Stack direction="row" spacing={2}>
            {
              level > 0 && level <= 3 ?
                <CreateNewFolderOutlined sx={{
                  color: purple[500],
                  backgroundColor: purple[50],
                  width: 35,
                  height: 35,
                  borderRadius: 1,
                }}
                  onClick={() => toOpenDepModal(idMain, idLV1, idLV2, idLV3, level, 'add')} />
                : <></>
            }

            <ModeEditRounded sx={{
              color: purple[500],
              backgroundColor: purple[50],
              width: 35,
              height: 35,
              borderRadius: 1,
            }}
              onClick={() => toOpenDepModal(idMain, idLV1, idLV2, idLV3, level, 'mod', name, desc, contact)} />


            {
              numberSubDep > 0 ?
                <></> :
                <Delete sx={{
                  color: purple[500],
                  backgroundColor: purple[50],
                  width: 35,
                  height: 35,
                  borderRadius: 1,
                }}
                  onClick={() => askConfToDelete(name, desc, contact, idMain, idLV1, idLV2, idLV3, level)}
                  // onClick={() => delteDepartement(idMain, idLV1, idLV2, idLV3, level)}
                />
            }
            <Typography sx={{ color: grey[800] }}>
              - {setupLabel}
            </Typography>
          </Stack>
        </Box>

      </>
    )
  }

  const contentTreeItem = (id, desc, contact) => {
    return (
      <>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ color: grey[500] }}>
              Description: {desc} {contact != '' ? ' - contact : ' + contact + ' - ID: ' + id : <></>}
            </Typography>
          </Stack>
        </Stack>
      </>
    )
  }
  const styleProps = { '--tree-view-bg-color': purple[50] };
  const displayMainDep = ({ item }) => {

    // var setupLabel = item.subjectName + ' - ' + item.childLevel1.length + ' sous départements - ID : ' + item._id
    console.log('SettingScreen -> displayMainDep -> Main Dep ID : ' + item._id);
    console.log('SettingScreen -> displayMainDep -> LV1 Dep ID : ' + item._id);
    return (
      <>

        <TreeItem
          nodeId={item._id}
          label={
            contentTreeItemLabel(
              item._id,
              '',
              '',
              '',
              '1',
              item.childLevel1.length,
              item.subjectName,
              item.subjectDescription,
              item.contactRef
            )
          }
          sx={{
            margin: 1, borderColor: purple[400],
            borderStyle: 'solid', borderWidth: 1,
            color: grey[900], borderRadius: 1,
            padding: 1
          }}
          style={styleProps}
        >
          {contentTreeItem(
            item._id,
            item.subjectDescription,
            item.contactRef)
          }
          <FlatList
            data={item.childLevel1}
            renderItem={displayDepLV1}
            numColumns={1}
            key={1}
            windowSize={100}
            refreshing={true}
          />
        </TreeItem >
      </>
    )
  }

  const displayDepLV1 = ({ item }) => {

    console.log('SettingScreen -> displayDepLV1 -> LV1 Dep ID : ' + item._id);
    return (
      <>
        <TreeItem
          nodeId={item._id}
          label={
            contentTreeItemLabel(
              item.idMainLevel,
              item._id,
              '',
              '',
              '2',
              item.childLevel2.length,
              item.subjectName,
              item.subjectDescription,
              item.contactRef
            )
          }
          sx={{
            margin: 1, borderColor: purple[400],
            borderStyle: 'solid', borderWidth: 1,
            color: grey[900], borderRadius: 1,
            padding: 1
          }}
          style={styleProps}
        >
          {contentTreeItem(
            item._id,
            item.subjectDescription,
            item.contactRef)}
          <FlatList
            data={item.childLevel2}
            renderItem={displayDepLV2}
            numColumns={1}
            key={1}
            windowSize={60}
            refreshing={true}
          />
        </TreeItem>
      </>
    )
  }

  const displayDepLV2 = ({ item }) => {
    var setupLabel = item.subjectName + ' - ' + item.childLevel3.length + ' sous département'
    return (
      <>
        <TreeItem
          nodeId={item._id}
          label={
            contentTreeItemLabel(
              item.idMainLevel, // Main id
              item.idLV1Level, // LV1 id
              item._id, // LV2 id
              '', // LV3 id
              '3',
              item.childLevel3.length,
              item.subjectName,
              item.subjectDescription,
              item.contactRef
            )
          }
          sx={{
            margin: 1, borderColor: purple[400],
            borderStyle: 'solid', borderWidth: 1,
            color: grey[900], borderRadius: 1,
            padding: 1
          }}
        >
          {contentTreeItem(
            item._id,
            item.subjectDescription,
            item.contactRef)}
          <FlatList
            data={item.childLevel3}
            renderItem={displayDepLV3}
            numColumns={1}
            key={1}
            windowSize={60}
            refreshing={true}
          />
        </TreeItem>
      </>
    )
  }

  const displayDepLV3 = ({ item }) => {
    var setupLabel = item.subjectName
    return (
      <>
        <TreeItem
          nodeId={item._id}
          label={
            contentTreeItemLabel(
              item.idMainLevel, // Main id
              item.idLV1Level, // LV1 id
              item.idLV2Level, // LV2 id
              item._id, // LV3 id
              '4',
              '0',
              item.subjectName,
              item.subjectDescription,
              item.contactRef
            )
          }
          sx={{
            margin: 1, borderColor: purple[400],
            borderStyle: 'solid', borderWidth: 1,
            color: grey[900], borderRadius: 1,
            padding: 1
          }}
        >
          {contentTreeItem(
            item._id,
            item.subjectDescription,
            item.contactRef)}
        </TreeItem>
      </>
    )
  }

  return (

    <View style={{ backgroundColor: '#ffffff', margin: 10 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType="success" />
      {/* <Loader loading={loading} /> */}

      <Box sx={{ flexGrow: 1 }}>
        <Masonry columns={2} spacing={0.5}>

          <Item key={1}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }} aria-label="simple table">

                  <TableHead sx={{ color: purple[100] }}>

                    <TableRow sx={{ backgroundColor: purple[700] }}>
                      <TableCell align="left">
                        <Settings fontSize='large' sx={{ color: purple[100] }} />
                      </TableCell>
                      <TableCell align="left" colSpan={4}>
                        <Typography variant="h5" sx={{ color: grey[50] }}>
                          Paramètres de la boite mail
                        </Typography>
                      </TableCell>

                    </TableRow>

                    <TableRow>

                      <TableCell align="left">
                        <Switch
                          onChange={handleMailFormEnable}
                          checked={switchMailFormEnable}
                          color="secondary"
                          label="Modifier le formulaire" />
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        Modifier les paramètres des la boite mail
                      </TableCell>
                    </TableRow>

                  </TableHead>

                  <TableBody>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        <ContactMailOutlined sx={{ color: purple[700], width: 30, height: 30 }} />
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        <Controller name="mailPopUser" control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <><InputLabel
                              htmlFor="filled-adornment-password">Nom d'utilisateur</InputLabel>
                              <Input disabled={mailFormEnable} label="Nom du compte" id="standard-basic"
                                sx={{ width: '100%' }}
                                variant="standard"
                                onBlur={onBlur} onChange={onChange} value={value}
                                onFocus={setFormUpdate(value, 'mailPopUser')}
                              />
                            </>
                          )}
                        /> {errors.name && <Typography variant="body2" sx={{ color: red[900] }}>
                          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                          Le nom du compte est obligatoire.
                        </Typography>}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        <LockClockOutlined sx={{ color: purple[700], width: 30, height: 30 }} />
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        <Controller name="mailPopPassword" control={control} rules={{ required: true, }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <><InputLabel
                              htmlFor="filled-adornment-password">Mot de passe</InputLabel>
                              <Input disabled={mailFormEnable}
                                id="filled-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onBlur={onBlur} onChange={onChange} value={value}
                                onFocus={setFormUpdate(value, 'mailPopPassword')}
                                sx={{ width: '100%' }}
                                variant="standard"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton disabled={mailFormEnable}
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              /></>
                          )}
                        /> {errors.name && <Typography variant="body2" sx={{ color: red[900] }}>
                          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                          Le mot de passe est obligatoire.
                        </Typography>}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        <DnsOutlined sx={{ color: purple[700], width: 30, height: 30 }} />
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        <Controller name="mailPopHost" control={control} rules={{ required: true, }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <><InputLabel
                              htmlFor="filled-adornment-password">Serveur mail</InputLabel>
                              <Input disabled={mailFormEnable}
                                label="Serveur mail" id="mailPopHost"
                                sx={{ width: '100%' }}
                                variant="standard"
                                onBlur={onBlur} onChange={onChange} value={value}
                                onFocus={setFormUpdate(value, 'mailPopHost')}
                              /></>
                          )}
                        /> {errors.email && <Typography variant="body2" sx={{ color: red[900] }}>
                          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                          Le nom du sereur de mail est obligatoire.
                        </Typography>}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        <SettingsEthernetOutlined sx={{ color: purple[700], width: 30, height: 30 }} />
                      </TableCell>
                      <TableCell align="left" colSpan={3}>
                        <Controller name="mailPopPort" control={control} rules={{ required: true, }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <><InputLabel
                              htmlFor="filled-adornment-password">Port de communication</InputLabel><Input disabled={mailFormEnable}
                                label="Port" id="mailPopPort"
                                sx={{ width: '100%' }}
                                variant="standard"
                                onBlur={onBlur} onChange={onChange} value={value}
                                onFocus={setFormUpdate(value, 'mailPopPort')}
                              /></>
                          )}
                        /> {errors.firstName && <Typography variant="body2" sx={{ color: red[900] }}>
                          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
                          Le port de communication est obligatoire.
                        </Typography>}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        {
                          mailPopTls == true ?
                            <GppGoodOutlined sx={{ color: purple[700], width: 30, height: 30 }} /> :
                            <GppGoodOutlined sx={{ color: red[700], width: 30, height: 30 }} />
                        }
                      </TableCell>
                      <TableCell align="left">
                        <Switch disabled={mailFormEnable}
                          onChange={handleChangeMailPopTls}
                          checked={mailPopTls}
                          color="secondary"
                          label="Activer le TLS (Recommandé)" />
                      </TableCell>
                      <TableCell align="left" colSpan={2}>
                        <Typography variant="body3">
                          Activer le TLS (Recommandé)
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        <MarkEmailUnreadOutlined sx={{ color: purple[700], width: 30, height: 30 }} />
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body3">
                          Relever les mails
                        </Typography>
                      </TableCell>
                      <TableCell align="right" colSpan={2}>
                        <Select disabled={mailFormEnable}
                          labelId="mailPopCheckFreq-label"
                          id="mailPopCheckFreq"
                          value={mailPopCheckFreq}
                          label="Temps"
                          onChange={handleChangeMailPopCheckFreq}
                          variant="standard"
                          sx={{ m: 1, minWidth: '70%' }}
                        >

                          <MenuItem value={1} >1 min</MenuItem>
                          <MenuItem value={5}>5 min</MenuItem>
                          <MenuItem value={10}>10 min</MenuItem>
                          <MenuItem value={20}>20 min</MenuItem>
                          <MenuItem value={30}>30 min</MenuItem>
                          <MenuItem value={40}>40 min</MenuItem>
                          <MenuItem value={50}>50 min</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        {
                          mailNotification == true ?
                            <NotificationsActiveOutlined sx={{ color: purple[700], width: 30, height: 30 }} /> :
                            <NotificationsOffOutlined sx={{ color: red[700], width: 30, height: 30 }} />
                        }
                      </TableCell>
                      <TableCell align="left">
                        <Switch disabled={mailFormEnable}
                          onChange={handleChangeMailNotification}
                          checked={mailNotification}
                          color="secondary"
                          label="Reçevoir les notifications des nouveaux mails"

                        />
                      </TableCell>
                      <TableCell align="left" colSpan={2}>
                        <Typography variant="body3">
                          Reçevoir les notifications des nouveaux mails
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row" colSpan={4}>
                        <Button disabled={mailFormEnable}
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: purple[700] }}>
                          Enregistrer
                        </Button>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </form>

          </Item>

          <Item key={2}>

            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="simple table">

                <TableHead>

                  <TableRow sx={{ backgroundColor: purple[700] }}>
                    <TableCell align="left">
                      <TuneOutlined fontSize='large' sx={{ color: purple[100] }} />
                    </TableCell>
                    <TableCell align="left" colSpan={4}>
                      <Typography variant="h5" sx={{ color: grey[50] }}>
                        Paramètres généraux
                      </Typography>
                    </TableCell>

                  </TableRow>

                  <TableRow>

                    <TableCell align="left">
                      <Switch
                        onChange={handleAuthFormEnable}
                        checked={switchAuthFormEnable}
                        color="secondary"
                        label="Modifier les paramètres généraux" />
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Modifier les paramètres généraux
                    </TableCell>
                  </TableRow>

                </TableHead>

                <TableBody>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row">
                      {
                        userCanActive == true ?
                          <PermIdentityOutlined sx={{ color: purple[700], width: 30, height: 30 }} /> :
                          <PersonOffOutlined sx={{ color: red[700], width: 30, height: 30 }} />
                      }
                    </TableCell>
                    <TableCell align="left">
                      <Switch disabled={authFormEnable}
                        onChange={handleChangeUserCanActive}
                        checked={userCanActive}
                        color="secondary"
                        label="Activer le TLS (Recommandé)" />
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      <Typography variant="body3">
                        Les utilisateurs peuvent activer ou non des clients ou d'autres utilisateurs
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row">
                      {
                        userCanManageSubject == true ?
                          <CategoryOutlined sx={{ color: purple[700], width: 30, height: 30 }} /> :
                          <CategoryOutlined sx={{ color: red[700], width: 30, height: 30 }} />
                      }
                    </TableCell>
                    <TableCell align="left">
                      <Switch disabled={authFormEnable}
                        onChange={handleChangeUserCanManageSubject}
                        checked={userCanManageSubject}
                        color="secondary"
                        label="Reçevoir les notifications des nouveaux mails"

                      />
                    </TableCell>
                    <TableCell align="left" colSpan={2}>
                      <Typography variant="body3">
                        Les utilisateurs peuvent modifier les département
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" colSpan={4}>
                      <Button disabled={authFormEnable}
                        onClick={() => { updategeneralParameter() }}
                        variant="contained"
                        size="large"
                        sx={{ backgroundColor: purple[700] }}>
                        Enregistrer
                      </Button>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>

          </Item>

          <Item key={3}>

            {/* <form onSubmit={addDepartement}> */}
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%", '&:last-child td, &:last-child th': { border: 0 } }} aria-label="simple table">

                <TableHead>

                  <TableRow sx={{ backgroundColor: purple[700] }}>
                    <TableCell align="left">
                      <TuneOutlined fontSize='large' sx={{ color: purple[100] }} />
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      <Typography variant="h5" sx={{ color: grey[50] }}>
                        Gérer vos départements
                      </Typography>
                    </TableCell>

                  </TableRow>

                </TableHead>

                <TableBody>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell component="th" scope="row" colSpan={4}>
                      <Stack direction="column" spacing={2}>

                        <Stack direction="row" spacing={2}>
                          <CreateNewFolderOutlined sx={{
                            color: purple[500],
                            backgroundColor: purple[50],
                            width: 35,
                            height: 35,
                            borderRadius: 1,
                          }}
                            onClick={() => toOpenDepModal('', '', '', '', '0', 'add')} />
                        </Stack>

                      </Stack>
                      <TreeView
                        aria-label="gmail"
                        defaultExpanded={['3']}
                        defaultCollapseIcon={<ExpandMoreOutlined />}
                        defaultExpandIcon={<ChevronRightOutlined />}
                        defaultEndIcon={
                          <div style={{ width: 35 }} />
                        }
                      >
                        <FlatList
                          data={getDepList}
                          renderItem={displayMainDep}
                          numColumns={1}
                          key={1}
                          windowSize={60}
                          refreshing={true}
                        />
                      </TreeView>

                    </TableCell>

                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
            {/* </form> */}

          </Item>

          <Item key={4}>
            dfghdght hetrheythj eyje
          </Item>

        </Masonry>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDepModal}
        onClose={closeModalRefreshList}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDepModal}>
          <Box sx={style}>
            <CardMedia
              component='img'
              height='194'
              image={require('../assets/Logo.jpg')} />

            <DepartementManager clientID={id}
              idMainDep={idMainDepSelected}
              idLV1Dep={idLV1DepSelected}
              idLV2Dep={idLV2DepSelected}
              idLV3Dep={idLV3DepSelected}
              level={whichLevelSelected}
              whicthAction={whichActionSelected}
              whichName={whichNameSelected}
              whichDesc={whichDescSelected}
              whichContact={whichContactSelected}
              closeModalRefreshList={closeModalRefreshList} />

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDepModalConfDelete}
        onClose={closeModalRefreshList}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDepModalConfDelete}>
          <Box sx={styleConfDelete}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5" sx={{ color: grey[700] }}>
                Êtes vous sûr de vouloir supprimer le département {modalSettingToDelete[0]} ?
              </Typography>

              <Typography variant="body1" sx={{ color: grey[700] }}>
                <b>Nom :</b> {modalSettingToDelete[0]}
              </Typography>

              <Typography variant="body1" sx={{ color: grey[700] }}>
                <b>Decription :</b> {modalSettingToDelete[1]}
              </Typography>

              <Typography variant="body1" sx={{ color: grey[700] }}>
                <b>Contact :</b> {modalSettingToDelete[2]}
              </Typography>

              <Typography variant="h6" sx={{ color: grey[700] }}>
                Si oui cliquez sur <b>Confirmer</b>, si non <b>Annuler</b>
              </Typography>
              <Typography variant="body3" sx={{ color: grey[700] }}>

              </Typography>
              <Stack direction="column" alignItems="flex-end" spacing={2}>

                <Stack direction="row" spacing={2}>


                  <Button
                    onClick={() => { ConfDeleteResponse() }}
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: purple[700] }}>
                    Confirmer
                  </Button>

                  <Button
                    onClick={() => { handleCloseConfDelete() }}
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: red[700] }}>
                    Annuler
                  </Button>

                </Stack>

              </Stack>

            </Stack>
          </Box>
        </Fade>
      </Modal>

    </View>

  )

}

const style = {
  position: 'absolute',
  top: '10%',
  left: '10%',
  // transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  padding: '1%',
  overflow: 'hidden'
};

const styleConfDelete = {
  position: 'absolute',
  top: '30%',
  left: '30%',
  // transform: 'translate(-50%, -50%)',
  width: '40%',
  height: '40%',
  bgcolor: 'background.paper',
  padding: '1%',
  overflow: 'hidden',
  borderRadius: 5
};

export default SettingsPage