import React, { useState, createRef, useRef, useEffect, Component } from 'react'

import {
  InputLabel, TextField, Box, MenuItem, 
  FormControl, Button, Paper, Typography, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select,
} from '@mui/material'

import {
  ModeEditRounded, CreateNewFolderOutlined,
} from '@mui/icons-material'

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors'
import { APIMainRequest, selectClientListRequest } from '../../Communication/MGAPIRequest';
import { storeData, getData, removeData } from "../../Utils/DataManager";

const DepartementManager = ({ clientID,
  idMainDep,
  idLV1Dep,
  idLV2Dep,
  idLV3Dep,
  level,
  whicthAction,
  whichName,
  whichDesc,
  whichContact,
  closeModalRefreshList }) => {

  const [subjectName, setSubjectName] = React.useState(whichName)
  const [subjectDescription, setSubjectDescription] = React.useState(whichDesc)
  const [subjectContact, setSubjectContact] = React.useState(whichContact)
  const [subjectContactToDisplay, setSubjectContactToDisplay] = React.useState(whichContact)
  const [subjectContactID, setSubjectContactID] = React.useState(whichContact)
  const [subjectLevel, setSubjectLevel] = React.useState(level)

  const [getTechnicienList, setTechnicienList] = React.useState([])

  console.log('DepartementManager -> clientID -> ' + clientID)
  console.log('DepartementManager -> idMainDep -> ' + idMainDep)
  console.log('DepartementManager -> idLV1Dep -> ' + idLV1Dep)
  console.log('DepartementManager -> idLV2Dep -> ' + idLV2Dep)
  console.log('DepartementManager -> idLV3Dep -> ' + idLV3Dep)

  console.log('DepartementManager -> whichContact -> ' + whichContact)

  console.log('DepartementManager -> level -> ' + level)

  console.log('DepartementManager -> whicthAction -> ' + whicthAction)

  const technicienListRequest = () => {
    const settingsParams = {
      clientID: clientID,
    }

    console.log('DepartementManager -> technicienListRequest -> SettingsParams -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/technicien/list', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> DelteLV3Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)
      setTechnicienList(myJson)

    }).finally(() => {
      console.log('DepartementManager -> DelteLV3Dep -> Finally ')
      
    })
  }

  useEffect(() => {
    console.log('DepartementManager -> useEffect');


    getData('MGUserInfo').then((value) => {

      console.log('FormAddClients -> useEffect -> getData -> MGUserInfo -> MGGetUserId : ' + value.MGGetUserId)
      console.log('FormAddClients -> useEffect -> getData -> MGUserInfo -> MGGetUserId : ' + value.profile)

      technicienListRequest()
    })


  }, []);

  const closeProcess = () => {
    closeModalRefreshList()
    setSubjectName()
    setSubjectDescription()
    setSubjectContact()
  }

  const addDepartement = () => {
    switch (level) {
      case '0':
        addMainDep()
        break

      case '1':
        addLV1Dep()
        break

      case '2':
        addLV2Dep()
        break

      case '3':
        addLV3Dep()
        break

    }
  }

  const addMainDep = () => {
    console.log('DepartementManager -> addMainDep -> clientID : ' + clientID)
    console.log('DepartementManager -> addMainDep -> level -> ' + level)

    const settingsParams = {
      clientID: clientID,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> addMainDep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))
    APIMainRequest('/MGSubject/add', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> addMainDep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> addMainDep -> customer -> GO TO HOME SCREEN ')
      closeProcess()
    })

  }

  const addLV1Dep = () => {
    console.log('DepartementManager -> addLV1Dep -> idMainDep : ' + idMainDep)
    console.log('DepartementManager -> addLV1Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }
    console.log('DepartementManager -> addLV1Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))
    APIMainRequest('/MGSubject/addCHV1', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> addLV1Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> addLV1Dep -> customer -> GO TO HOME SCREEN ')
      closeProcess()
    })

  }

  const addLV2Dep = () => {
    console.log('DepartementManager -> addLV2Dep -> idLV1Dep : ' + idLV1Dep)
    console.log('DepartementManager -> addLV2Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectIDCHV1: idLV1Dep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> addLV2Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))
    APIMainRequest('/MGSubject/addCHV2', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> addLV2Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> addLV2Dep -> customer -> GO TO HOME SCREEN ')
      closeProcess()
    })

  }

  const addLV3Dep = () => {
    console.log('DepartementManager -> addLV3Dep -> idLV2Dep : ' + idLV2Dep)
    console.log('DepartementManager -> addLV3Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectIDCHV1: idLV1Dep,
      subjectIDCHV2: idLV2Dep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> addLV3Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))
    APIMainRequest('/MGSubject/addCHV3', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> addLV3Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> addLV3Dep -> customer -> GO TO HOME SCREEN ')
      closeProcess()
    })

  }


  const ModDepartement = () => {
    console.log('DepartementManager -> ModDepartement -> level -> ' + level)
    switch (level) {
      case '1':
        ModMainDep()
        break

      case '2':
        ModLV1Dep()
        break

      case '3':
        ModLV2Dep()
        break

      case '4':
        ModLV3Dep()
        break

    }
  }

  const ModMainDep = () => {
    console.log('DepartementManager -> ModMainDep -> clientID : ' + clientID)
    console.log('DepartementManager -> ModMainDep -> level -> ' + level)

    const settingsParams = {
      clientID: clientID,
      subjectID: idMainDep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> ModMainDep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/update', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> ModMainDep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> ModMainDep -> Finally ')
      closeProcess()
    })
  }

  const ModLV1Dep = () => {
    console.log('DepartementManager -> ModLV1Dep -> idMainDep : ' + idMainDep)
    console.log('DepartementManager -> ModLV1Dep -> level -> ' + level)

    const settingsParams = {
      clientID: clientID,
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectIDCHV1: idLV1Dep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }
    console.log('DepartementManager -> ModLV1Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/updateCHV1', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> ModLV1Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> ModLV1Dep -> Finally ')
      closeProcess()
    })
  }

  const ModLV2Dep = () => {
    console.log('DepartementManager -> ModLV2Dep -> idLV1Dep : ' + idLV1Dep)
    console.log('DepartementManager -> ModLV2Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectIDCHV1: idLV1Dep,
      subjectIDCHV2: idLV2Dep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> ModLV2Dep -> SettingsParam -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/updateCHV2', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> ModLV2Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> ModLV2Dep -> Finally ')
      closeProcess()
    })
  }

  const ModLV3Dep = () => {
    console.log('DepartementManager -> ModLV3Dep -> idLV2Dep : ' + idLV2Dep)
    console.log('DepartementManager -> ModLV3Dep -> level -> ' + level)

    const settingsParams = {
      subjectID: idMainDep,
      idMainLevel: idMainDep,
      subjectIDCHV1: idLV1Dep,
      subjectIDCHV2: idLV2Dep,
      subjectIDCHV3: idLV3Dep,
      subjectName: subjectName,
      subjectDescription: subjectDescription,
      contactRef: subjectContact,
      contactRefID: subjectContactID,
    }

    console.log('DepartementManager -> ModLV3Dep -> SettingsParams -> ' + JSON.stringify(settingsParams, null, 2))

    APIMainRequest('/MGSubject/updateCHV3', settingsParams).then(function (response) {

      return response.getData

    }).then(function (myJson) {

      console.log('DepartementManager -> ModLV3Dep -> resultSettingsRequest -> isSuccess -> ' + myJson)

    }).finally(() => {
      console.log('DepartementManager -> ModLV3Dep -> Finally ')
      closeProcess()
    })
  }

  const handleChangeDepSubjectName = (event) => {
    console.log('DepartementManager -> handleChangeDepSubjectName -> ' + event.target.value)
    setSubjectName(event.target.value)
  }

  const handleChangeDepSubjectDescription = (event) => {
    console.log('DepartementManager -> handleChangeDepSubjectDescription -> ' + event.target.value)
    setSubjectDescription(event.target.value)
  }

  const handleChangeDepSubjectContact = (event) => {
    console.log('DepartementManager -> handleChangeDepSubjectContact -> ' + event.target.value)
    const splitValue = event.target.value.split('/')
    console.log('DepartementManager -> handleChangeDepSubjectContact -> Name ' + splitValue[0] + ' ID :' + splitValue[1])
    setSubjectContactID(splitValue[1])
    setSubjectContact(splitValue[0])
    setSubjectContactToDisplay(event.target.value)
  }

  const displayTechnicienList = () => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Choisir un technicien</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={subjectContactToDisplay}
            label="Choisir un technicien"
            onChange={handleChangeDepSubjectContact}
          >
            <MenuItem value="-" defaultChecked><em>Choisir un technicien</em></MenuItem>
            
            {
              getTechnicienList.map((item) => (
                <MenuItem value={item.firstName + ' ' + item.lastName + '/' + item._id}>{item.firstName + ' ' + item.lastName}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </Box>
    )
  }

  return (
    <div>

      <TableContainer component={Paper}>
        <Table sx={{
          width: "100%",
          '&:last-child td, &:last-child th': { border: 0 },
          paddingTop: 3,
          marginTop: 3
        }} aria-label="simple table">

          <TableHead>

            <TableRow sx={{ backgroundColor: purple[700] }}>
              <TableCell align="left">
                {whicthAction == 'add' ?
                  <CreateNewFolderOutlined fontSize='large' sx={{ color: purple[100] }} />
                  :
                  <ModeEditRounded fontSize='large' sx={{ color: purple[100] }} />
                }

              </TableCell>
              <TableCell align="left" colSpan={2}>
                <Typography variant="h5" sx={{ color: grey[50] }}>
                  {
                    whicthAction == 'add' ?
                      'Ajouter un département à ' + clientID :
                      'Modifier ce département ' + idMainDep
                  }
                </Typography>
              </TableCell>

            </TableRow>

          </TableHead>

          <TableBody>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell component="th" scope="row">

                <TextField label="Nom du département"
                  sx={{ width: '100%' }}
                  variant="standard"
                  value={subjectName}
                  id="subjectName"
                  name="subjectName"
                  onChange={handleChangeDepSubjectName}
                  onBlur={handleChangeDepSubjectName}
                  
                />

              </TableCell>

              <TableCell component="th" scope="row">

                <TextField label="Description"
                  sx={{ width: '100%' }}
                  variant="standard"
                  value={subjectDescription}
                  id="subjectDescription"
                  name="subjectDescription"
                  onChange={handleChangeDepSubjectDescription}
                  onBlur={handleChangeDepSubjectDescription}
                />

              </TableCell>

              <TableCell align="center">
                { displayTechnicienList() }
              </TableCell>

            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

              <TableCell align="center" colSpan={3}>
                <Button
                  onClick={() => { 
                    whicthAction == 'add' ?
                    addDepartement() :
                    ModDepartement()
                  }}
                  variant="contained"
                  size="large"
                  sx={{ backgroundColor: purple[700] }}>
                  {
                    whicthAction == 'add' ?
                      'Ajouter ce département' :
                      'Modifier ce département'
                  }
                </Button>
              </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>


    </div>
  )

}



export default DepartementManager