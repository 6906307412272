import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { StyleSheet, TextInput, View, Text, ScrollView, Keyboard, TouchableOpacity, KeyboardAvoidingView, Image, SafeAreaView, Dimensions } from 'react-native';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { storeData, getData, removeData } from "../Utils/DataManager";
import { deviceTypeMap } from '../Utils/Helper';

import TopBarScreen from '../Components/TopBar';
import BottomBarScreen from '../Components/BottomBar';

import Dashboard from '../Pages/Dashboard';
import SettingsPage from '../Pages/SettingsPage';
import HistoryPages from '../Pages/HistoryPages';
import ClientsList from '../Pages/ClientsList';
import FormClients from '../Components/FormClients';
import Messages from '../Pages/Messages';
import Unassigned from '../Pages/Unassigned';

import MGMenu from '../Components/Menu';

const Tab = createBottomTabNavigator();




const HomeScreen = ({navigation}) => {

  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [bodyWidth, setBodyWidth] = useState('')
  const [data, setData] = useState(null);
  const [witchPage, seWitchPage] = useState(<Dashboard getWitchPage={getWitchPage}/>);

  const getWitchPage = (idUser, witchPage) => {
    console.log('HomeScreen -> displayWitchPage -> witchPage : ' + witchPage);
    
    if (witchPage == 'dashBoard') {
      seWitchPage(<Dashboard getWitchPage={getWitchPage}/>)
    }
    if (witchPage == 'clientsList') {
      console.log('HomeScreen -> displayWitchPage -> clientsList : ' + witchPage);
      seWitchPage(<ClientsList getWitchPage={getWitchPage}/>)
    }
    if (witchPage == 'history') {
      seWitchPage(<HistoryPages getWitchPage={getWitchPage}/>)
    }
    if (witchPage == 'message') {
      seWitchPage(<Messages getWitchPage={getWitchPage}/>)
    }

    if (witchPage == 'unassigned') {
      seWitchPage(<Unassigned getWitchPage={getWitchPage}/>)
    }

    if (witchPage == 'detailsUsers') {
      seWitchPage(<FormClients idClients={idUser}/>)
    }
    
    if (witchPage == 'settings') {
      
      seWitchPage(<SettingsPage getWitchPage={getWitchPage}/>)
      
    }
    if (witchPage == 'LoginScreen') {
      navigation.navigate('LoginScreen')
    }
      
  }
  const onChange = () => {
    //handler to get device Height
    setHeight(Dimensions.get('window').height);
    //handler to get device Width
    setWidth(Dimensions.get('window').width);
    var setDimBody = Dimensions.get('window').width - 240
    setBodyWidth(setDimBody)
  };

  useEffect(() => {
    console.log('HomeScreen -> useEffect');

    getDeviceTypeAsync().then((deviceType) => {
      console.log('HomeScreen -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }

    });

    onChange()

    getData('MGUserInfo').then((value) => {
      console.log('HomeScreen -> useEffect -> getData -> then -> MGUserInfo -> profile : ' + value.profile)
      
      setData(value)
      
    }).finally(() => {
      console.log('HomeScreen -> useEffect -> getData -> finally ->')
      // navigation.navigate('LoginScreen')

     
    }).catch(() => {
      console.log('HomeScreen -> useEffect -> getData -> catch ->')
      navigation.navigate('LoginScreen')
    }) 
  

    Dimensions.addEventListener('change', onChange);
    
    console.log('HomeScreen -> useEffect -> Body Width : ' + bodyWidth);
  }, []);
  
  return (
    
    <View style={{width:'100%', height:'100%', backgroundColor:'#ffffff', paddingBottom:100}}>
      <View style={{height:'100%', width:bodyWidth, top: 100, left:240, backgroundColor:'#ffffff'}}>
        {witchPage}
      </View>
      <MGMenu getWitchPage={getWitchPage}/>
      <TopBarScreen getWitchPage={getWitchPage}/>
      <BottomBarScreen getWitchPage={getWitchPage}/>
    </View>
  );
};


export default HomeScreen;

