import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { StyleSheet, TextInput, View, ScrollView, ImageBackground, Keyboard, TouchableOpacity, KeyboardAvoidingView, Image, SafeAreaView, Dimensions, FlatList } from 'react-native';

import { Snackbar, Alert } from '@mui/material';
  

// getMessage = Message that we want display
// getType = Type of alert view that we want Ex : error, warning, info, success
const SnackBarManager = ({isOpen, getMessage, getType}) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    const [finalWidth, setFinalWidth] = useState()


    const onChange = () => {
        console.log('ClientsList -> onChange ->')
        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);
        var fw = Dimensions.get('window').width - 260
        setFinalWidth(fw)
      };

    useEffect(function effectFunction() {
        console.log('SnackBarManager -> useEffect');
    
        
        onChange()

        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);

        Dimensions.addEventListener('change', onChange);
        

    }, []);
    
    return (
        <>
            <Snackbar open={isOpen} onClose={handleClose} autoHideDuration={5000}
                style={{position:'fixed', left:250, right:10, width:finalWidth, bottom:'8%'}}>
                <Alert onClose={handleClose} severity={getType} sx={{ width: '100%' }}>
                {getMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default SnackBarManager