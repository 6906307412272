import React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';

  export const storeData = async (setKey, setValue) => {
    try {
        const jsonValue = JSON.stringify(setValue);
        await AsyncStorage.setItem(setKey, jsonValue);
        return true
      } catch (e) {
        // saving error
        return false
      }
  };

  export const getData = async (getKey) => {
    try {
        
        const jsonValue = await AsyncStorage.getItem(getKey);
        // console.log('DataManager -> getData : ' + JSON.parse(jsonValue).MGGetUserId);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        return null;
    }
  };

  export const removeData = async (getKey) => {
    try {
      await AsyncStorage.removeItem(getKey);
    //   console.log('Donnée supprimée avec succès');
      return true
    } catch (error) {
    //   console.error('Erreur lors de la suppression des données:', error);
      return false
    }
  };

// export const storeData = (setKey, setValue) => {
    
//     AsyncStorage.setItem(setKey, setValue).then(() => 
//         {
//             return true
//         }
//     ).catch(() =>
//         {
//             return false
//         }
//     )
//   };

//   export const getData = (getKey) => {
    
//     AsyncStorage.getItem(getKey).then((value) => 
//         {
//             return value
//         }
//     ).catch(() =>
//         {
//             return null
//         }
//     )
//   };

//   export const removeData = (getKey) => {

//     AsyncStorage.removeItem(getKey).then(() => 
//         {
//             return true
//         }
//     ).catch(() =>
//         {
//             return false
//         }
//     )
//   };

  