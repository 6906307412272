import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  StyleSheet, TextInput, View, Text, ScrollView, Keyboard, TouchableOpacity,
  KeyboardAvoidingView, SafeAreaView, Dimensions, FlatList, WebView
} from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';
import {
  TextField, Box, CardActions, MenuItem, Skeleton,
  IconButton, Card, Autocomplete,
  ListItemText, ListItem, List, ListItemAvatar, ListItemIcon,
  Avatar, Typography, Accordion, AccordionSummary, AccordionDetails,
  Modal, Backdrop, CardMedia, CardHeader, CardContent, CircularProgress
} from '@mui/material';

import {
  Cancel, CancelOutlined, TitleOutlined, SubjectOutlined, ImageOutlined, PictureAsPdfOutlined, ExpandMoreOutlined,
  FileDownloadOutlined, VisibilityOutlined, FolderZipOutlined, AttachEmailOutlined, 
  ContactMailOutlined, MailOutlineOutlined, CalendarMonthOutlined,
  TaskAltOutlined, RuleOutlined, WarningAmberOutlined, ArrowDownwardOutlined
} from '@mui/icons-material';

import {
  DataGrid, GridToolbar
} from '@mui/x-data-grid'

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { saveAs } from "file-saver";
import { Buffer } from "buffer";

import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap } from '../Utils/Helper';
import Loader from '../Components/Loader';
import UnixTimestampToDate from '../Components/UnixTimestampToDate'

const Unassigned = ({ navigation, getWitchPage }) => {

  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [getWidthModal, setWidthModal] = useState('')
  const [getHeightModal, setHeightModal] = useState('')

  const [getMailList, setMailList] = React.useState([])

  const [getHeightGrid, setHeightGrid] = useState('')

  const [loading, setLoading] = useState(false)
  const [isLoadingAttach, setIsLoadingAttach] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [userConnected, setUserConnected] = React.useState();
  const [userInfo, setUserInfo] = React.useState();

  const [displayAttachList, setDisplayAttachList] = React.useState()

  const [getListAttachment, setListAttachment] = React.useState([])

  const [getDisplayMailDetails, setDisplayMailDetails] = React.useState([])

  const [getCustomersList, setCustomersList] = React.useState([])
  const [getUsersList, setUsersList] = React.useState([])

  const [getPreviewImageBase64, setPreviewImageBase64] = React.useState()
  const [getAttachmentName, setAttachmentName] = React.useState()
  const [getAttachmentData, setAttachmentData] = React.useState()
  const [getAttachmentType, setAttachmentType] = React.useState()

  /////// TO MODAL MANAGEMENT
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  /////// TO MODAL MANAGEMENT

  /////// TO CHILD MODAL MANAGEMENT
  const [openChildModal, setOpenChildModal] = React.useState(false);
  const handleOpenChildModal = () => {
    setOpenChildModal(true);
  };
  const handleCloseChildModal = () => {
    setOpenChildModal(false);
  };
  /////// TO CHILD MODAL MANAGEMENT

  /////// TO CONTEXTUAL MENU MANAGEMENT
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSubmenu = Boolean(anchorEl);
  const handleClickSubmenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };
  /////// TO CONTEXTUAL MENU MANAGEMENT

  // const [rows, setRows] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const columns = [
    {
      field: "id",
      hideable: false,
    },
    {
      field: "clientID",
      hideable: false,
    },
    {
      field: "ibmMessageID",
      hideable: false,
    },
    {
      field: 'ibmDate',
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Date</ListItemText>
        </ListItemIcon>
      </>,
      width: 180,
      renderCell: (params) => {
        return (
          <><UnixTimestampToDate unixTimestamp={params.value} /></>
        );
      },
    },
    {
      field: 'ibmAttachNumber',
      headerName: <><Typography>
        <AttachEmailOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
      </Typography></>,
      width: 60,
      renderCell: (params) => {
        return (
          <>{params.value == 0 ?
            <></>
            : <Avatar sx={{ bgcolor: blue[500], width: 25, height: 25 }}>{params.value}</Avatar>}</>
        );
      },
      align: 'center'
    },
    {
      field: 'ibmFromAddress',
      headerName: <>
        <ListItemIcon>
          <MailOutlineOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Expéditeur</ListItemText>
        </ListItemIcon>
      </>,
      width: 190
    },
    {
      field: 'ibmFromName',
      headerName: <>
        <ListItemIcon>
          <ContactMailOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Nom</ListItemText>
        </ListItemIcon>
      </>,
      width: 190
    },
    {
      field: 'ibmSubject',
      headerName: <>
        <ListItemIcon>
          <SubjectOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Sujet</ListItemText>
        </ListItemIcon>
      </>,
      width: 190
    },
    {
      field: 'ibmBody',
      headerName: <>
        <ListItemIcon>
          <TitleOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Message</ListItemText>
        </ListItemIcon>
      </>,
      width: 190
    },
    {
      field: 'ibmIsAssigned',
      headerName: <>
        <ListItemIcon>
          <RuleOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Assigné</ListItemText>
        </ListItemIcon>
      </>,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {params.value == true ? <TaskAltOutlined sx={{ color: green[800] }} /> : <CancelOutlined sx={{ color: red[800] }} />}

          </>
        );
      },
      align: 'center'
    },
    {
      field: 'ibmIsOpened',
      headerName: <>
        <ListItemIcon>
          <RuleOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Traité</ListItemText>
        </ListItemIcon>
      </>,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {params.value == true ? <TaskAltOutlined sx={{ color: green[800] }} /> : <CancelOutlined sx={{ color: red[800] }} />}

          </>
        );
      },
      align: 'center'
    },
  ];

  const tmpInitialState = {
    columns: {
      columnVisibilityModel: {

        id: false,
        clientID: false,
        ibmMessageID: false,
        ibmDate: true,
        ibmAttachNumber: true,
        ibmFromAddress: true,
        ibmFromName: true,
        ibmSubject: true,
        ibmBody: true,
        ibmToAddress: false,
        ibmIsAssigned: true,
        ibmIsOpened: true,

      }
    }
  };

  const executeRequest = () => {

    setLoading(true)

    getData('MGUserInfo').then(function (value) {
      console.log('Unassigned -> executeRequest -> getData -> then -> MGUserInfo -> profile : ' + value.profile)
      return value
    }).then(async function (myValue) {

      console.log('Unassigned -> executeRequest -> getData -> finally -> myValue.parentID : ' + myValue.parentID)
      console.log('Unassigned -> executeRequest -> getData -> finally -> myValue.MGGetUserId : ' + myValue.MGGetUserId)

      const params = {
        clientID: myValue.MGGetUserId,
      };

      await APIMainRequest('/MGUsers/dh/unassignedMail/', params).then(function (response) {

        return response.getData

      }).then(function (myJson) {
        console.log('Unassigned -> executeRequest #1 -> Mail list : ' + JSON.stringify(myJson, null, 2))
        // populateGrid(myJson)
        setMailList(myJson)

      }).finally(() => {
        console.log('Unassigned -> executeRequest -> -> Display Data #3 -> ')
        // console.log('Unassigned -> executeRequest #2 -> rows.length : ' + rows.length)


        const request = selectClientListRequest(myValue.profile)

        console.log('Unassigned -> getClientsList -> getData -> then -> MGUserInfo -> Request : ' + request)

        const params = {
          userId: myValue.MGGetUserId,
          parentID: myValue.parentID,
          licenceKey: myValue.licenceKey
        };

        APIMainRequest(request, params).then(function (response) {

          return response.getData
        }).then(function (myJson) {
          // console.log('Unassigned -> getClientsList -> Display Data #2 -> ' + JSON.stringify(myJson, null, 2))
          console.log('Unassigned -> getClientsList -> Display Data #2 -> ' + myJson.rows)

          var tmpCustoList = []
          var tmpUserList = []
          for (let index = 0; index < myJson.rows.length; index++) {
            const element = myJson.rows[index];


            if (element.profile == 'customer') {
              console.log('Unassigned -> getClientsList -> Display customer -> name ' + element.name)
              tmpCustoList.push(
                {
                  clientID: element._id,
                  clientName: element.name + ' - ' + element.phonFixe + ' - ' + element.phoneMobile,
                }
              )
            }
            if (element.profile == 'user') {
              console.log('Unassigned -> getClientsList -> Display user -> name ' + element.name)
              tmpUserList.push(
                {
                  userID: element._id,
                  userName: element.firstName + ' - ' + element.lastName
                }
              )
            }

          }
          console.log('Unassigned -> getClientsList -> setCustomersList ' + JSON.stringify(tmpCustoList, null, 2))
          setCustomersList(tmpCustoList)

          console.log('Unassigned -> getClientsList -> getUsersList ' + JSON.stringify(tmpUserList, null, 2))
          setUsersList(tmpUserList)

        }).finally(() => {

          setLoading(false)

        })


      })

    })

  }

  const optionsCustomersList = getCustomersList.map((option) => {
    const firstLetter = option.clientName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const optionsUserList = getUsersList.map((option) => {
    const firstLetter = option.userName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const toDisplayPreview = (getAttachment) => {
    // console.log('Unassigned -> toDisplayPreview #1 -> ' + JSON.stringify(getAttachment.ibmAttachments, null, 2))
    console.log('Unassigned -> toDisplayPreview #1 -> ' + getAttachment.ibmAttachments.data)
    console.log('Unassigned -> toDisplayPreview #1 -> ' + getAttachment._id)
    console.log('Unassigned -> toDisplayPreview #2 -> ' + getAttachment.ibmAttachType)

    if (getAttachment.ibmAttachType == 'image/png' || getAttachment.ibmAttachType == 'image/jpeg' || getAttachment.ibmAttachType == 'image/jpg') {

      //// FOR IMAGE
      const blob = new Blob([Int8Array.from(getAttachment.ibmAttachments.data)], { type: getAttachment.ibmAttachType });
      // const blob = new Blob([Int8Array.from(getDate)], { type: getType });
      console.log('Unassigned -> executeRequest -> -> Display Data blob -> ' + window.URL.createObjectURL(blob))
      setPreviewImageBase64(window.URL.createObjectURL(blob))
      //// FOR IMAGE
      handleOpenChildModal()
      setAttachmentName(getAttachment.ibmAttachName)
      setAttachmentData(getAttachment.ibmAttachments.data)
      setAttachmentType(getAttachment.ibmAttachType)

    } else if (getAttachment.ibmAttachType == 'application/pdf') {

      urlPdfFile(getAttachment.ibmAttachments.data)

    }

    // urlPdfFile(getAttachment.ibmAttachments.data)

  }

  const toDisplayAttachmentList = ({ item }) => {
    // console.log('Unassigned -> toDisplayAttachmentsLink #1 -> ' + item._id) // item.stoneShorDesc
    // console.log('Unassigned -> toDisplayAttachmentsLink #2 -> ' + item.ibmAttachType)

    return (
      <>
        {item.ibmAttachType == 'application/pdf' ||
          item.ibmAttachType == 'image/png' ||
          item.ibmAttachType == 'image/jpeg' ||
          item.ibmAttachType == 'image/jpg' ||
          item.ibmAttachType == 'application/zip' ?
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id={item._id}
            >
              <ListItemAvatar>

                {
                  item.ibmAttachType == 'application/pdf' ?
                    <PictureAsPdfOutlined sx={{ color: red[500] }} />
                    :
                    item.ibmAttachType == 'application/zip' ?
                      <FolderZipOutlined sx={{ color: yellow[800] }} />
                      :
                      <ImageOutlined sx={{ color: blue[500] }} />


                }

              </ListItemAvatar>
              <ListItemText primary={item.ibmAttachName} />
            </AccordionSummary>
            <AccordionDetails>
              <MenuItem onClick={(event) => downloadDoc(item.ibmAttachments.data, item.ibmAttachName)}>
                <ListItemIcon>
                  <FileDownloadOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Télécharger</ListItemText>
              </MenuItem>
              {item.ibmAttachType == 'application/zip' ? <></> :
                <MenuItem onClick={(event) => toDisplayPreview(item)}>
                  <ListItemIcon>
                    <VisibilityOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Apperçu</ListItemText>
                </MenuItem>
              }
            </AccordionDetails>
          </Accordion>
          : <>
            <Box sx={{ width: '90%', marginLeft: '5%' }}>
              <MenuItem>
                <ListItemIcon>
                  <WarningAmberOutlined sx={{ color: red[900] }} fontSize="small" />
                </ListItemIcon>
                <ListItemText>Le fichier <b>{item.ibmAttachName}</b> n'est pas pris en charge</ListItemText>
              </MenuItem>
            </Box>
          </>}
      </>
    )
  }

  const executeMailDetails = (mailID) => {
    setIsLoadingAttach(true)
    getData('MGUserInfo').then(function (value) {
      console.log('Unassigned -> executeRequest -> getData -> then -> MGUserInfo -> profile : ' + value.profile)
      return value
    }).then(function (myValue) {

      console.log('Unassigned -> executeRequest -> getData -> finally -> myValue.parentID : ' + myValue.parentID)
      console.log('Unassigned -> executeRequest -> getData -> finally -> myValue.MGGetUserId : ' + myValue.MGGetUserId)


      const params = {
        mailID: mailID
      };

      APIMainRequest('/MGUsers/dh/attachment/', params).then(function (response) {

        return response.getData
      }).then(function (myJson) {
        // console.log('Unassigned -> executeRequest -> -> Display Data #2 -> ' + JSON.stringify(myJson, null, 2))
        console.log('Unassigned -> executeRequest -> -> Display Data #2 -> ' + myJson.ibmAttachments.length)
        setDisplayAttachList(myJson.ibmAttachments)

        setListAttachment(myJson.ibmAttachments)

      }).finally(() => {

        setIsLoadingAttach(false)
      })

    })

  }

  const downloadDoc = (data, name) => {
    handleCloseSubmenu()
    // const sBase64 = myJson.ibmAttachments.data
    const sBase64 = data
    const buffer = Buffer.from(sBase64, "base64") //Buffer is only available when using nodejs
    saveAs(new Blob([buffer]), name)


  }

  const urlPdfFile = (data) => {
    const sBase64 = data
    const buffer = Buffer.from(sBase64, "base64") //Buffer is only available when using nodejs

    //Create a Blob from the PDF Stream
    const file = new Blob([buffer], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;


  }

  const toDisplayMailDetail = (myparam) => (
    myparam, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    console.log('Unassigned -> toDisplayMailDetail -> myparam -> ' + JSON.stringify(myparam.row, null, 2))
    executeMailDetails(myparam.row._id)

    handleOpen()

    var tmp = [
      myparam.row._id, // 0
      myparam.row.ibmDate, // 1
      myparam.row.ibmAttachments, // 2
      myparam.row.ibmAttachNumber, // 3
      myparam.row.ibmFromAddress, // 4
      myparam.row.ibmFromName, // 5
      myparam.row.ibmToAddress, // 6
      myparam.row.ibmSubject, // 7
      myparam.row.ibmBody, // 8
      myparam.row.ibmIsOpened, // 8

    ]

    setDisplayMailDetails(tmp)

  }

  const onChange = () => {
    //handler to get device Height
    setHeight(Dimensions.get('window').height);
    //handler to get device Width
    setWidth(Dimensions.get('window').width);
    var widthModal = Dimensions.get('window').width * 0.90
    var heightModal = Dimensions.get('window').height * 0.90
    var heightGrid = Dimensions.get('window').height * 0.70
    console.log('Unassigned -> onChange -> heightModal : ' + heightModal);
    setWidthModal(widthModal)
    setHeightModal(heightModal)
    setHeightGrid(heightGrid)

  };

  useEffect(() => {
    console.log('Unassigned -> useEffect');

    onChange()

    // setTimeout(() => {

    //   executeRequest()

    // }, 5000);

    getDeviceTypeAsync().then((deviceType) => {
      console.log('Unassigned -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }

    });

    getData('MGUserInfo').then((value) => {
      console.log('Unassigned -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)

      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        // navigation.navigate('HomeScreen')
        // setID(value.MGGetUserId)
        // userInfoRequest(value.MGGetUserId)
        setUserConnected(true)
        setUserInfo(value)
        executeRequest()

      }
    })


  }, []);

  const style = {
    position: 'absolute',
    top: '5%',
    left: '5%',
    // transform: 'translate(-10%, -10%)',
    width: getWidthModal,
    height: getHeightModal,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
    padding: '1%',
    overflow: 'hidden'
  };

  // const displayAvatarInMainModal = (getMail) => {
  //   var defineAvatar = getMail.split("@")
  //   var tmp1 = defineAvatar[0]
  //   var tmp2 = defineAvatar[1]
  //   var first = tmp1[0]
  //   var second = tmp2[0]

  //   return first + " " + second
  // }

  // handleDeleteClick('656e395f62df2a6219b29e87')
  const getRowId = (getMailList) => getMailList._id;
  return (

    <>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {loading == true ?
        <Box sx={{ width: '90%', marginLeft: '5%' }}>
          <Skeleton animation="wave" variant="rectangular" height={60} /><br />
          <Skeleton animation="wave" variant="rectangular" height={60} /><br />
          <Skeleton animation="wave" variant="rectangular" height={60} />
        </Box>
        :
        <Box sx={{ height: getHeightGrid, width: '98%', marginLeft: '1%' }}>

          <DataGrid

            initialState={tmpInitialState}
            rows={getMailList != null ? getMailList : rowsTmp}
            columns={columns}
            getRowId={getRowId}
            onRowClick={toDisplayMailDetail(displayAttachList)}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },

            }}


          // loading
          />

        </Box>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingAttach}
          // onClick={handleClose}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
          <ScrollView style={{ height: '100%', padding: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}>

            <Card sx={{ width: '98%', height: '98%', marginLeft: '1%', marginTop: '1%' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: purple[500] }} aria-label="recipe">
                    N
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings" onClick={handleClose}>
                    <Cancel />
                  </IconButton>
                }
                title={"Message de: " + getDisplayMailDetails[4] + " - Mail : " + getDisplayMailDetails[5]}
                subheader={getDisplayMailDetails[9] == false ? 'Message non traité' : 'Message traité'}
              />

              <CardContent>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <Autocomplete
                      id="autoCompletCustomer"
                      options={optionsCustomersList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.clientName}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Assigner cette demande un client" />}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: purple[500] }}>
                        <ArrowDownwardOutlined />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Puis" secondary="Choisir un technicien" />
                  </ListItem>
                  <ListItem>
                    <Autocomplete
                      id="autoCompletUser"
                      options={optionsUserList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.userName}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Assigner cette demande un technicien" />}
                    />
                  </ListItem>
                </List>

                <Typography id="modal-modal-description" variant="h6" component="h2" sx={{ mt: 2 }}>
                  Sujet : {getDisplayMailDetails[7]}
                </Typography>
                <br />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Message : {getDisplayMailDetails[8]}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <FlatList
                  data={getListAttachment}
                  renderItem={toDisplayAttachmentList}
                  numColumns={1}
                  key={1}
                  windowSize={60}
                  refreshing={true}
                />

              </CardActions>

            </Card>
          </ScrollView>
        </Box>
      </Modal>

      <Modal
        open={openChildModal}
        onClose={handleCloseChildModal}
      >
        <Box sx={{ height: '100%', widows: '100%' }}>

          <Card sx={{ width: '80%', height: '96%', marginLeft: '10%', marginTop: '2%' }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                  I
                </Avatar>
              }
              action={
                <IconButton aria-label="settings" onClick={handleCloseChildModal}>
                  <Cancel />
                </IconButton>
              }
              title={getAttachmentName}
            // subheader="September 14, 2016"
            />
            <CardMedia
              component="img"
              height="80%"
              // source={getPreviewImageBase64}
              // image="/static/images/cards/paella.jpg"
              src={getPreviewImageBase64}
            />

            <CardContent>
              <MenuItem onClick={(event) => downloadDoc(getAttachmentData, getAttachmentName)}>
                <ListItemIcon>
                  <FileDownloadOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Télécharger</ListItemText>
              </MenuItem>
            </CardContent>
            {/* <CardActions disableSpacing>
              <IconButton aria-label="add to favorites" >
                
              </IconButton>
              
            </CardActions> */}

          </Card>


        </Box>
      </Modal>
    </>
  )

}

const rowsTmp = [
  {
    id: '1',
    clientID: '1',
    ibmDate: "1",
    ibmFromAddress: "1",
    ibmFromName: "1",
    ibmToAddress: '1',
    ibmAttachNumber: "1",
    ibmSubject: "1",
    ibmBody: "1",
    ibmIsOpened: "1",
    ibmIsAssigned: "1",
    ibmMessageID: "1",
    ibmAttachments: "1"
  }
]

export default Unassigned