import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { View } from 'react-native';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';
import { useForm, Controller, useWatch } from 'react-hook-form';
import zxcvbn from 'zxcvbn';

import { InputLabel, TextField, 
  Button,  
  InputAdornment, IconButton, Typography, Select, MenuItem } from '@mui/material';
  
import { AccountCircle, StoreRounded, Visibility, VisibilityOff, Clear, CheckCircleOutline } from '@mui/icons-material';

import MuiPhoneNumber from 'material-ui-phone-number';

import moment from 'moment';

// import { styles } from '../Styles/style';
import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap, createPassLabel, funcProgressColor } from '../Utils/Helper';
// Import Components
import Loader from './Loader';

const FormAddClients = ({idClients, closeModalRefreshList}) => {
    
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    
    const [id, setID] = useState('');
    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    
    const [password, setPassword] = useState('a');
    const [testResult, setTestResult] = useState()
    const [isLoginMailFormat, setISLoginMailFormat] = useState(true)
    const [isMailFormat, setISMailFormat] = useState(true)
    const [samePwd, setSamePwd] = React.useState(0)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ceoName, setCeoName] = useState('');
    const [phonFixe, setPhonFixe] = useState('');
    const [phoneMobile, setPhoneMobile] = useState('');

    const [formWasUpdated, setFormWasUpdated] = useState(false)

    const [defineProfile, setDefineProfile] = React.useState('Veuillez selectioner un profile');
    const [defeineProfileList, setDefineProfileList] = React.useState([])
    const [displaySelProfile, setDisplaySelProfile] = React.useState(false)

    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    
    const params = {
        userId: idClients
    };
    
    const getClientsDetail = async () => {
        console.log('FormAddClients -> getClientsDetail');
        await APIMainRequest('/MGUsers/dh/list', params).then((value) => {
            console.log('FormAddClients -> getClientsDetail -> Then -> Name : ' + value.getData.name);
            console.log('FormAddClients -> getClientsDetail -> Then -> Login : ' + value.getData.login);
            console.log('FormAddClients -> getClientsDetail -> Then -> Login : ' + value.getData._id);
            var getClientsInfo = value.getData
            setData(getClientsInfo)
            const formattedDate = moment.unix(getClientsInfo.entryDate).format('DD/MM/YYYY');

            // setID(value.getData._id)
            

            console.log('FormAddClients -> getClientsDetail -> Then -> formattedDate : ' + value.getData._id);
        })
    };

    useEffect(() => {
        console.log('FormAddClients -> useEffect');
        getClientsDetail()

        getDeviceTypeAsync().then((deviceType) => {
          console.log('FormAddClients -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
          
        });
    
        getData('MGUserInfo').then((value) => {
          console.log('FormAddClients -> useEffect -> getData -> MGUserInfo -> MGGetUserId : ' + value.MGGetUserId)
          console.log('FormAddClients -> useEffect -> getData -> MGUserInfo -> MGGetUserId : ' + value.profile)
          setID(value.MGGetUserId)

          if (value.profile == 'root') {
            setDisplaySelProfile(true)
            setDefineProfileList(['Administrateur', 'Utilisateur', 'Clients'])
          }

          if (value.profile == 'admin') {
            setDisplaySelProfile(true)
            setDefineProfileList(['Administrateur', 'Utilisateur', 'Clients'])
          }
          
          if (value.profile == 'user') {
            setDisplaySelProfile(true)
            setDefineProfileList(['Clients'])
          }

          if (value.profile == 'customer') {
            setDisplaySelProfile(false)
          }

          
          // {['Accueil', 'Tableau de bord', 'Clients', 'Historique', 'Messages'].map((text, index) => (
          
        })

        
    }, []);

    const onSubmit = formData => {

        if (isLoginMailFormat == false && isMailFormat == false) {

          console.log('FormAddClients -> onSubmit -> formData.firstName : ' + formData.name + " - Login : " + formData.login);
          const updateParm = {
            parentID: id,
            name: formData.name,
            login: formData.login,
            passWord: formData.passWord,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            ceoName: formData.ceoName,
            phonFixe: formData.phonFixe,
            phoneMobile: formData.phoneMobile,
            active: false,
            profile: defineProfile
            
          }

          addClient(updateParm).then((value) => {
            console.log('FormAddClients -> onSubmit -> updateClient -> Then : ' + value);
          }).finally(() => {
            console.log('FormAddClients -> onSubmit -> updateClient -> Finally');
          })

        } else {
          console.log('FormAddClients -> onSubmit -> Login and Email format are wrong');
        }

        
    };

    const addClient = async (params) => {
      console.log('FormAddClients -> addClient -> Display Param : ' + params.parentID);
      const resultRequest = await APIMainRequest('/MGUsers/dh/add/', params)

      console.log('FormAddClients -> addClient -> ' + resultRequest.getData.success + ' - getData -> ' + resultRequest.getData.message)

      if (resultRequest.getData.success) {
        setLoading(false)
        closeModalRefreshList('From Add client')
      } else {
        setLoading(false)
        closeModalRefreshList('From Add Client')
      } 
    }

    const selectProfile = (getValue) => {
      console.log('FormAddClients -> selectProfile -> getValue : ' + getValue.target.value)
      if (getValue.target.value == 'Administrateur') {
        setDefineProfile('admin')
      }
      if (getValue.target.value == 'Utilisateur') {
        setDefineProfile('user')
      }
      if (getValue.target.value == 'Clients') {
        setDefineProfile('customer')
      }
      

    }

    const setFormUpdate = (fieldValue, whichField) => {
      // console.log('FormAddClients -> setFormUpdate -> Form Client was updated !')
      setFormWasUpdated(true)
      
      if (whichField == 'login') {
        // console.log('FormAddClients -> setFormUpdate ->  Login : ' + fieldValue)
        setISLoginMailFormat(true)
        

        if (validateEmail(fieldValue)) {
          // console.log('FormAddClients -> setFormUpdate ->  Login is validde ')
          setISLoginMailFormat(false)
        }
      }

      if (whichField == 'email') {
        // console.log('FormAddClients -> setFormUpdate -> Email : ' + fieldValue)
        
        setISMailFormat(true)

        if (validateEmail(fieldValue)) {
          // console.log('FormAddClients -> setFormUpdate ->  Email is validde ')
          setISMailFormat(false)
        }
      }

      if (whichField == 'passWord') {
        
        if (fieldValue != undefined) {
          // console.log('FormAddClients -> setFormUpdate -> passWord test streng -> Value : ' + fieldValue)
          PasswordStrengthMeter(fieldValue)
        }
        setPassword(fieldValue)
        
      }
      
      if (whichField == 'passWordConf') {
        // console.log('FormAddClients -> setFormUpdate -> passWordConf test same password')
        setSamePwd(0)
        if (fieldValue == password && fieldValue != undefined) {
          // console.log('FormAddClients -> setFormUpdate -> Both password are same')
          setSamePwd(1)
        }
      }
    }

    const PasswordStrengthMeter = (password) => {
      const testResult = zxcvbn(password);
      const num = testResult.score * 100/4;
      console.log('FormAddClients -> PasswordStrengthMeter -> num : ' + testResult.score)
      setTestResult(testResult.score)
    }


    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    }
      
    return (
      
        
        <>
          <Loader loading={loading} />
            
            <View style={{ justifyContent: 'center', flex: 1 }}>
            <br/>
              <br/>
            <form onSubmit={handleSubmit(onSubmit)}>
              { displaySelProfile == true ? 
                <>
                <InputLabel id="demo-simple-select-standard-label">Profile</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={defineProfile}
                    onChange={selectProfile}
                    label="Profile"
                    variant='standard'
                  >
                    <MenuItem value="Veuillez selectioner un profile">
                      <em>Veuillez selectioner un profile</em>
                    </MenuItem>
                    {defeineProfileList.map((text, index) => (
                      <MenuItem value={text}>{text}</MenuItem>
                    ))}
                  </Select>
                </>
                : <></>
              }
            
              <Controller name="login" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Login" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'login')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    error={isLoginMailFormat}
                  />
                )}
              /> {errors.login && <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le login est obligatoire.</Typography>}<br/><br/>

              <Controller name="passWord" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  
                  <TextField label="Choisir un mot de passe" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'passWord')}
                    
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          { createPassLabel(testResult) }
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                      ),
                    }}
                    type={showPassword ? 'text' : 'password'}
                    sx={{ m: 1, width: '48ch' }}
                  />
                )}
              /> 
              {/* <PasswordStrengthMeter password={password} /> */}
              {errors.passWord && <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Un mot de passe est obligatoire.</Typography> }

              <Controller name="passWordConf" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Confirmer le mot de passe" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'passWordConf')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          { samePwd == 1 ? <CheckCircleOutline sx={{ color: 'green' }}/> : <Clear sx={{ color: 'darkRed' }}/> }
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                      ),
                    }}
                    type={showPassword ? 'text' : 'password'}
                    sx={{ m: 1, width: '48ch' }}
                  />
                )}
              /> {errors.passWordConf && 
                    <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Veuillez confirmer le mot de passe.</Typography>}<br/><br/>
              
              <Controller name="email" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Courriel" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'email')}
                    onFocusCapture={setFormUpdate(value, 'email')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    error={isMailFormat}
                  />
                )}
              /> {errors.login &&
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le courriel est obligatoire.</Typography>}<br/><br/>

              <Controller name="name" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Nom de la compagnie" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'name')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StoreRounded />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              /> {errors.name &&
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le nom de la compagnie est obligatoire.</Typography>}<br/><br/>
              
              <Controller name="firstName" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Prénom" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'firstName')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              /> {errors.firstName && 
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le prénom est obligatoire.</Typography>}<br/><br/>
              
              <Controller name="lastName" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Nom de famille" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'lastName')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              /> {errors.lastName && 
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le nom est obligatoire.</Typography>}<br/><br/>
              
              <Controller name="ceoName" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField label="Nom Prénom du propriétaire" id="standard-basic" variant="standard" fullWidth
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'ceoName')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              /> {errors.ceoName &&
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le nom et prénom du propriétaire est obligatoire.</Typography>}<br/><br/>
              
              <Controller name="phonFixe" control={control} rules={{ required: true, }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <MuiPhoneNumber defaultCountry={'ca'} label="Téléphone fixe" id="standard-basic" variant="standard" fullWidth 
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'phonFixe')}
                    sx={{ m: 1, width: '48ch' }}
                    
                  />
                )}
              /> {errors.phonFixe && 
                <Typography variant="subtitle2" gutterBottom sx={{ color: 'darkRed' }} >Le numéro de téléphone est obligatoire.</Typography> }
              
              <Controller name="phoneMobile" control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <MuiPhoneNumber defaultCountry={'ca'} label="Numéro téléphone mobile" id="standard-basic" variant="standard" fullWidth 
                    onBlur={onBlur} onChange={onChange} value={value}
                    onFocus={setFormUpdate(value, 'phonFixe')}
                    sx={{ m: 1, width: '48ch' }}
                    
                  />
                )}
              /><br/><br/>
              

              <Button type="submit" variant="contained" size="large">
                Ajouter
              </Button>
            </form>

            </View>
            </>
       
    )

}


export default FormAddClients


