import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';

import { SentimentVeryDissatisfiedRounded, 
  SentimentDissatisfiedRounded, SentimentNeutralRounded, 
  SentimentSatisfiedRounded, SentimentVerySatisfiedRounded,
  CheckCircleOutline, Cancel
} from '@mui/icons-material';


import {red, orange, yellow, green, 
  lightGreen } from '@mui/material/colors'


export const deviceTypeMap = {
    [DeviceType.UNKNOWN]: "unknown",
    [DeviceType.PHONE]: "phone",
    [DeviceType.TABLET]: "tablet",
    [DeviceType.DESKTOP]: "desktop",
    [DeviceType.TV]: "tv",
    
  };

  export const createPassLabel = (testResult) => {
    switch(testResult) {
      case 0:
        return <SentimentVeryDissatisfiedRounded sx={{ color: "darkRed" }}/>;
      case 1:
        return <SentimentDissatisfiedRounded sx={{ color: "red" }}/>;
      case 2:
        return <SentimentNeutralRounded sx={{ color: "orange" }}/>;
      case 3:
        return <SentimentSatisfiedRounded sx={{ color: "yellow" }}/>;
      case 4:
        return <SentimentVerySatisfiedRounded sx={{ color: "green" }}/>;
      default:
        return <SentimentVeryDissatisfiedRounded sx={{ color: "darkRed" }}/>;
    }
  }

  export const funcProgressColor = (testResult) => {
    switch(testResult) {
      case 0:
        return "darkRed"; // darkRed - #828282
      case 1:
        return "red"; // red - #EA1111
      case 2:
        return "orange"; // orange - #FFAD00
      case 3:
        return "yellow"; // yellow - #9bc158
      case 4:
        return "green"; // green - #00b500
      default:
        return "darkRed2"; // darkRed - #828282
    }
  }

  // export const funcProgressColor = (testResult) => {
  //   switch(testResult) {
  //     case 0:
  //       return "#828282";
  //     case 1:
  //       return "#EA1111";
  //     case 2:
  //       return "#FFAD00";
  //     case 3:
  //       return "#9bc158";
  //     case 4:
  //       return "#00b500";
  //     default:
  //       return "#828282";
  //   }
  // }