import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { StyleSheet, TextInput, View, Text, ScrollView, Keyboard, TouchableOpacity, KeyboardAvoidingView, Image, SafeAreaView, Dimensions } from 'react-native';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';

import {Box, Avatar, CssBaseline, AppBar, Toolbar, Typography, Grid} from '@mui/material';
  
import { storeData, getData, removeData } from "../Utils/DataManager";
import { deviceTypeMap } from '../Utils/Helper';



const TopBarScreen = ({navigation, getWitchPage}) => {

  
  const [getTitleProfile, setTitleProfile] = useState('')
  const [getNameProfile, setNameProfile] = useState('')

  

  useEffect(() => {
    console.log('TopBar -> useEffect');

    
    
    getDeviceTypeAsync().then((deviceType) => {
      console.log('TopBar -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }
  
    });

    getData('MGUserInfo').then(function(value) {
      console.log('TopBar -> useEffect -> getData -> then -> MGUserInfo -> profile : ' + value.profile)
      return value
    }).then(function(myValue) {

      console.log('TopBar -> useEffect -> getData -> finally -> value.profile : ' + myValue.name)
      if(myValue.profile == 'root') {
        setTitleProfile('Super Administrateur - ' + myValue.name)
        
      } else if (myValue.profile == 'admin') {
        setTitleProfile('Administrateur - ' + myValue.name)
      } else if (myValue.profile == 'user') {
        setTitleProfile('Utilisateur - ' + myValue.name)
      } else if (myValue.profile == 'customer') {
        setTitleProfile('Client - ' + myValue.name)
      }
      setNameProfile('Bonjour ' + myValue.firstName + ' ' + myValue.lastName)
    })
  
    
  }, []);
  
    return (
      
        <View>

          <Box sx={{ display: 'flex'}}>
            <CssBaseline />
            <AppBar
              position="fixed"
              sx={{
                width: { sm: `100%`, 
                        backgroundColor:'#f5f5f5', 
                        borderBottomWidth:1, 
                        borderBottomColor:'#0000001f', 
                        borderBottomStyle:'solid', 
                        boxShadow:'none', 
                        color:'#666666'  },
                // ml: { sm: `${drawerWidth}px` },
              }}>
              <Toolbar>
              <Grid container 
                    rowSpacing={1}
                    alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <Avatar alt="Desk Helper" src={require('../assets/Logo.jpg')} sx={{ width: 60, height: 60, left: 30, top: 5 }} />
                  
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h5" noWrap component="div">
                    { getTitleProfile }
                  </Typography>
                  
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" noWrap component="div">
                    Desk Helper
                  </Typography>
                  
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1" noWrap component="div">
                    { getNameProfile }
                  </Typography>
                  
                </Grid>
              </Grid>
                
                
                
              </Toolbar>
            </AppBar>
          </Box>
          
        </View>
    )

}

export default TopBarScreen