
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  View, 
} from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';

import {
  Box, Drawer, CssBaseline, Toolbar, List, Badge, Snackbar,
  Divider, ListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';

import {
  Dashboard, PeopleAlt, NoAccountsOutlined,
  History, QuestionAnswer, ExitToApp, LockOpen, Settings,
} from '@mui/icons-material';


import { storeData, getData, removeData } from "../Utils/DataManager";
import { deviceTypeMap } from '../Utils/Helper';
import { APIMainRequest } from '../Communication/MGAPIRequest';

const logOut = async () => {

  removeData('MGUserInfo').then(() => {

    console.log('Menu -> LogOut -> removeData(MGUserInfo)')

  }).finally(() => {

    console.log('Menu -> LogOut -> MGGetUserId -> Delete data')
    navigation.navigate('LoginScreen')

  })


}

const MenuScreen = ({ navigation, getWitchPage }) => {

  const [active, setActive] = React.useState('');
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [userConnected, setUserConnected] = useState(false)
  const [getUserInfo, setUserInfo] = React.useState([]);
  const [getUserProfile, setUserProfile] = React.useState('');

  const [getMenuList, setMenuList] = React.useState()


  const [getDashboardNumber, setDashboardNumber] = React.useState();
  const [getUserNumber, setUserNumber] = React.useState();
  const [getHistoryNumber, setHistoryNumber] = React.useState();
  const [getUnassignedNumber, setUnassignedNumber] = React.useState();
  const [getMessageNumber, setMessageNumber] = React.useState();
  const [getSettingNumber, setSettingNumber] = React.useState();

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const checkUnassignedMail = () => {
    var returnValue
    getData('MGUserInfo').then(function (value) {
      return value
    }).then(function (myValue) {
      const params = {
        clientID: myValue.MGGetUserId,
      };

      APIMainRequest('/MGUsers/dh/unassignedMailCount/', params).then(function (response) {

        return response.getData

      }).then(function (myJson) {
        console.log('Menu -> checkUnassignedMail -> -> Display Data #1 -> myJson.number : ' + myJson.number + ' - getUnassignedNumber : ' + getUnassignedNumber)
        // if (myJson.number != getUnassignedNumber) {
        //   console.log('Menu -> checkUnassignedMail -> -> Display Data #2 -> ')
        //   handleClick({ vertical: 'top', horizontal: 'center' })

        // }

        setUnassignedNumber(myJson.number)



      }).finally(() => {

        console.log('Menu -> checkUnassignedMail -> Display Data #3 -> ')

      })

    })

  }

  useEffect(() => {
    console.log('Menu -> useEffect');

    getDeviceTypeAsync().then((deviceType) => {
      console.log('Menu -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }

    });

    getData('MGUserInfo').then((value) => {
      console.log('Menu -> useEffect -> getData -> then -> MGUserInfo -> profile : ' + value.profile)

      if (value === null) {

        navigation.navigate('home')
        setUserConnected(false)

      } else {

        // navigation.navigate('HomeScreen')
        // setID(value.MGGetUserId)
        // userInfoRequest(value.MGGetUserId)
        setUserProfile(value.profile)


        setUserConnected(true)
        setUserInfo(value.MGGetUserId)

        checkUnassignedMail()
        // defineMenuList(value.profile)

        getData('MGUserSetting').then((value) => {

          console.log('Menu -> useEffect -> getData -> then -> MGUserSetting : ' + JSON.stringify(value, null, 2))

          var heartBeat = value.mailPopCheckFreq * 60 * 1000

          console.log('Menu -> useEffect -> getData -> then -> MGUserSetting -> heartBeat : ' + heartBeat)

          const timerId = setInterval(() => {
            console.log('Someone Scheduled me to run every second : ' + Date());

            checkUnassignedMail()

            // defineMenuList(value.profile)
          }, heartBeat);

        })

      }

      // const timerId = setInterval(() => {
      //   console.log('Someone Scheduled me to run every second');
      // }, 5000);

    }).finally(() => {
      console.log('Menu -> useEffect -> getData -> finally ->')


    }).catch(() => {
      console.log('Menu -> useEffect -> getData -> catch ->')
    })


  }, []);

  const selectSection = (witchSection) => {
    console.log('Menu Management Action -> witch Pages : ' + witchSection)
    setActive(witchSection)

    getWitchPage('', witchSection)
  }
  const drawerWidth = 240;

  return (
    <View style={{ backgroundColor: '#eaeaea', top: 90, width: drawerWidth, height: '100%', position: 'fixed' }}>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar style={{ height: 100 }} />
          <Divider />
          <List>
            {getUserProfile == 'customer' || getUserProfile == 'user' || getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Tableau de bord" disablePadding onClick={() => { selectSection('dashBoard') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <Badge badgeContent={getDashboardNumber} color="error">
                    <ListItemText primary="Tableau de bord" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }
            {getUserProfile == 'user' || getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Utilisateurs" disablePadding onClick={() => { selectSection('clientsList') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <PeopleAlt />

                  </ListItemIcon>
                  <Badge badgeContent={getUserNumber} color="error">
                    <ListItemText primary="Utilisateurs" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }

            {getUserProfile == 'customer' || getUserProfile == 'user' || getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Historique" disablePadding onClick={() => { selectSection('history') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <History />
                  </ListItemIcon>
                  <Badge badgeContent={getHistoryNumber} color="error">
                    <ListItemText primary="Historique" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }

            {getUserProfile == 'user' || getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Non assigné" disablePadding onClick={() => { selectSection('unassigned') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <NoAccountsOutlined />
                  </ListItemIcon>
                  <Badge badgeContent={getUnassignedNumber} color="error">
                    <ListItemText primary="Non assigné" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }

            {getUserProfile == 'customer' || getUserProfile == 'user' || getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Messages" disablePadding onClick={() => { selectSection('message') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <QuestionAnswer />
                  </ListItemIcon>
                  <Badge badgeContent={getMessageNumber} color="error">
                    <ListItemText primary="Messages" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }

            {getUserProfile == 'admin' || getUserProfile == 'root' ?
              <ListItem key="Réglages" disablePadding onClick={() => { selectSection('settings') }}>
                <ListItemButton >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <Badge badgeContent={getSettingNumber} color="error">
                    <ListItemText primary="Réglages" />
                  </Badge>
                </ListItemButton>
              </ListItem>
              : <></>
            }


            {/* <FlatList
              data={getMenuList}
              renderItem={toDisplayMenuList}
              numColumns={1}
              key={1}
              windowSize={60}
              refreshing={true}
            /> */}

          </List>
          <Divider />
          {userConnected == true ?
            <List>
              {['Se déconnecter'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => { logOut(getUserInfo) }}>
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            :
            <List>
              {['Connexion'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => { selectSection('LoginScreen') }}>
                    <ListItemIcon>
                      <LockOpen />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          }
        </Drawer>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
      />
    </View>
  )

}

export default MenuScreen;