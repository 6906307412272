import React, { useEffect, useState } from 'react';
import moment from 'moment';
const UnixTimestampToDate = ({ unixTimestamp }) => {
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    // Convertir le timestamp Unix en objet moment
    const dateObject = moment.unix(unixTimestamp);

    // Formater la date selon vos besoins
    const formatted = dateObject.format('DD/MM/YYYY à HH:mm:ss');

    // Mettre à jour l'état avec la date formatée
    setFormattedDate(formatted);
  }, [unixTimestamp]);

  return (
    <>
      {formattedDate !== null ? (
        <>{formattedDate}</>
      ) : (
        <>Chargement...</>
      )}
    </>
  );
};

export default UnixTimestampToDate;