import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { FlatList } from 'react-native';

import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  TextField, Box, MenuItem, Button, Stack, IconButton, Card, ListItemText, ListItemIcon,
  Avatar, Typography,
  Modal, Backdrop, CardMedia, CardHeader, CardContent, Tooltip, CircularProgress,
} from '@mui/material';

import {
  ImageOutlined, PictureAsPdfOutlined, Cancel,
  FilePresentOutlined,
  MarkUnreadChatAltOutlined, ChatOutlined, AttachEmailOutlined, FileDownloadOutlined
} from '@mui/icons-material';

import SnackBarManager from '../../Components/SnackBarManager';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import { storeData, getData, removeData } from "../../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../../Communication/MGAPIRequest';
import UnixTimestampToDate from '../../Components/UnixTimestampToDate'

const MGModalModTicket = ({ ticketID, closeModalRefreshList }) => {

  const closePopup = () => {
    console.log('MGModal -> closePopup -> close modal');

    modalManage(false)
  }

  const [getTicketID, setTicketID] = React.useState()

  const [userConnected, setUserConnected] = React.useState()
  const [getTicketDetails, setTicketDetails] = React.useState()
  const [getTicketCom, setTicketCom] = React.useState([])

  const [getTitle, setTitle] = useState('')
  const [getDescription, setDescription] = useState('')
  const [getFile, setFile] = useState()
  const [getFileName, setFileName] = useState('')
  const [getFileType, setFileType] = useState('')

  const [messageDisplayed, setMessageDisplayed] = useState('')
  const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

  const [getListAttachment, setListAttachment] = React.useState([])
  const [getPreviewImageBase64, setPreviewImageBase64] = React.useState()
  const [getAttachmentName, setAttachmentName] = React.useState()
  const [getAttachmentData, setAttachmentData] = React.useState()
  const [getAttachmentType, setAttachmentType] = React.useState()

  /////// TO CHILD MODAL MANAGEMENT
  const [openChildModal, setOpenChildModal] = React.useState(false);
  const handleOpenChildModal = () => {
    setOpenChildModal(true);
  };
  const handleCloseChildModal = () => {
    setOpenChildModal(false);
  };
  /////// TO CHILD MODAL MANAGEMENT

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const displayFileSelected = (fileType, fileName) => {

    return (
      <>
        {fileType != '' && fileName != '' ?
          <Stack direction="row" spacing={2} >
            {
              fileType == 'application/pdf' ?
                <PictureAsPdfOutlined sx={{
                  width: 25,
                  height: 25,
                  color: purple[700],
                }} /> : null
            }
            {
              fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'image/jpg' ?
                <ImageOutlined sx={{
                  width: 25,
                  height: 25,
                  color: purple[700],
                }} /> : null
            }
            <Typography variant="body1">{fileName}</Typography>
          </Stack>
          : null
        }
      </>
    )
  }

  const executeRequest = () => {

    getData('MGUserInfo').then((value) => {
      console.log('MGModalModTicket -> executeRequest -> MGUserInfo -> profile : ' + value.parentID)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {

        setOpen(true)

        const settingsParams = {
          ticketID: ticketID,
        }
        APIMainRequest('/MGUsers/dh/getTicket', settingsParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('MGModalModTicket -> getTicket -> ' + JSON.stringify(myJson, null, 2))
          setTicketID(myJson._id)

          setTicketDetails(myJson)
          setTicketCom(myJson.subjectComments)
          setListAttachment()

        }).finally(() => {
          console.log('MGModalModTicket -> DepartementMGT -> Finally : ')
          setOpen(false)
          console.log('MGModalModTicket -> executeRequest -> getTicketID : ' + getTicketID)
        })
      }
    })
  }

  const setToReadRequest = (setTicketID, setComID, setIsRead) => {

    getData('MGUserInfo').then((value) => {
      console.log('MGModalModTicket -> useEffect -> getData -> MGUserInfo -> profile : ' + value.parentID)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {

        setOpen(true)

        const settingsParams = {
          ticketID: setTicketID,
          comID: setComID,
          isRead: setIsRead
        }
        APIMainRequest('/MGUsers/dh/updateTicketToRead', settingsParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('MGModalModTicket -> getTicket -> ' + JSON.stringify(myJson, null, 2))

        }).finally(() => {
          console.log('MGModalModTicket -> DepartementMGT -> Finally : ')
          setOpen(false)
          executeRequest()
        })
      }
    })

  }


  const displayCommentRow = ({ item }) => {
    console.log('MGModalModTicket -> displayCommentRow -> ')

    return (

      <Stack direction="column" spacing={2}
        sx={{
          borderColor: purple[700], borderStyle: 'solid',
          borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 2
        }}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start"
          sx={{ backgroundColor: grey[100], padding: 1, borderRadius: 1 }}>
          <Typography><b>{item.commentAutor}</b></Typography>
          <UnixTimestampToDate unixTimestamp={item.entryDate} />

          {item.commentJoinFile.length > 0 && item.commentJoinFile[0] != null ?
            item.commentJoinFile[0].cjfAttachments != null ?
              <AttachEmailOutlined /> :
              null :
            null}
          {
            item.isRead == false ?
              <Tooltip title="Marquer comme lu">
                <Button variant="outlined" size="small" color="secondary"
                  onClick={() => { setToReadRequest(ticketID, item._id, true) }}>
                  <MarkUnreadChatAltOutlined sx={{ color: purple[700] }} />
                </Button>
              </Tooltip>

              :
              <Tooltip title="Marquer comme non lu">
                <Button variant="outlined" size="small" color="secondary"
                  onClick={() => { setToReadRequest(ticketID, item._id, false) }}>
                  <ChatOutlined sx={{ color: grey[500] }} />
                </Button>
              </Tooltip>
          }
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Typography
            sx={{ width: 120, minWidth: 120 }}><b><u>Titre : </u></b></Typography>
          <Typography>{item.commentTitle}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Typography
            sx={{ width: 120, minWidth: 120 }}><b><u>Description : </u></b></Typography>
          <Typography>{item.commentDesc}</Typography>
        </Stack>
        {
          item.commentJoinFile[0] != null ?
            item.commentJoinFile.map((getFile) => (

              getFile.cjfAttachType != '' && getFile.cjfAttachName != '' ?
                <Tooltip title={getFile.cjfAttachName}>
                  <Button variant="outlined" size="small" color="secondary" onClick={() => { toDisplayPreview(getFile) }}>
                    {displayFileSelected(getFile.cjfAttachType, getFile.cjfAttachName)} - {getFile.cjfAttachName}
                  </Button>
                </Tooltip> : null

            )) :
            null
        }
      </Stack>

    )

  }

  const toDisplayPreview = (getAttachment) => {
    // console.log('MGModalModTicket -> toDisplayPreview #1 -> ' + JSON.stringify(getAttachment.cjfAttachments, null, 2))
    console.log('MGModalModTicket -> toDisplayPreview #1 -> ' + getAttachment.cjfAttachments.data)
    console.log('MGModalModTicket -> toDisplayPreview #1 -> ' + getAttachment._id)
    console.log('MGModalModTicket -> toDisplayPreview #2 -> ' + getAttachment.cjfAttachType)

    if (getAttachment.cjfAttachType == 'image/png' || getAttachment.cjfAttachType == 'image/jpeg' || getAttachment.cjfAttachType == 'image/jpg') {

      //// FOR IMAGE
      const blob = new Blob([Int8Array.from(getAttachment.cjfAttachments.data)], { type: getAttachment.cjfAttachType });
      // const blob = new Blob([Int8Array.from(getDate)], { type: getType });
      console.log('MGModalModTicket -> executeRequest -> -> Display Data blob -> ' + window.URL.createObjectURL(blob))
      setPreviewImageBase64(window.URL.createObjectURL(blob))
      //// FOR IMAGE
      handleOpenChildModal()
      setAttachmentName(getAttachment.cjfAttachName)
      setAttachmentData(getAttachment.cjfAttachments.data)
      setAttachmentType(getAttachment.cjfAttachType)

    } else if (getAttachment.cjfAttachType == 'application/pdf') {

      urlPdfFile(getAttachment.cjfAttachments.data)

    }

  }

  const urlPdfFile = (data) => {
    const sBase64 = data
    const buffer = Buffer.from(sBase64, "base64") //Buffer is only available when using nodejs

    //Create a Blob from the PDF Stream
    const file = new Blob([buffer], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;

  }

  const downloadDoc = (data, name) => {
    // const sBase64 = myJson.ibmAttachments.data
    const sBase64 = data
    const buffer = Buffer.from(sBase64, "base64") //Buffer is only available when using nodejs
    saveAs(new Blob([buffer]), name)


  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      const maxSizeInBytes = 1024 * 1024; // 1MB (exemple : vous pouvez ajuster cela selon vos besoins)
      if (file.size > maxSizeInBytes) {
        setOpenModal(true)
        // Vous pouvez ajouter ici d'autres actions comme empêcher l'envoi du formulaire, etc.
      } else {
        console.log('CustomerDashboard -> handleFileChange -> file : ' + JSON.stringify(file, null, 2))
        const reader = new FileReader();
        // Read the file as binary data

        // reader.readAsDataURL(file)
        reader.onloadend = () => {
          console.log('CustomerDashboard -> handleFileChange -> Name : ' + file.name);
          console.log('CustomerDashboard -> handleFileChange -> Type : ' + file.type);
          // Convert binary data to Base64
          const base64Data = btoa(reader.result);
          setFile(base64Data);
          console.log('CustomerDashboard -> handleFileChange -> Data : ' + base64Data);
          setFileName(file.name)
          setFileType(file.type)
        };
        reader.readAsBinaryString(file);
      }

    }
  };

  const handleSendComment = () => {

    console.log('CustomerDashboard -> handleSendComment')

    console.log('CustomerDashboard -> handleSendComment -> getTitle : ' + getTitle)
    console.log('CustomerDashboard -> handleSendComment -> getDescription : ' + getDescription)

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> useEffect -> getData -> MGUserInfo -> profile : ' + value.MGGetUserId)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        console.log('SettingScreen -> onSubmit -> updateParm : ' + value.MGGetUserId);
        setOpen(true)

        const updateParm = {
          ticketID: getTicketID,
          commentAutor: value.firstName + ' ' + value.lastname,
          commentTitle: getTitle,
          commentDesc: getDescription,
          commentJoinFileB64: getFile,
          commentJointFileName: getFileName,
          commentJoinFileType: getFileType,

        }
        console.log('SettingScreen -> onSubmit -> updateParm : ' + JSON.stringify(updateParm, null, 2));
        APIMainRequest('/MGUsers/dh/addCommentTicket', updateParm).then(function (response) {
          return response.getData
        }).then(function (myJson) {

          console.log('CustomerDashboard -> Add new ticket -> ' + JSON.stringify(myJson, null, 2))
          if (myJson.success == true) {
            setMessageTypeDisplayed('success')
            setMessageDisplayed('Votre commentaire a bien été enregistré, un agent le prend en compte dans les meilleurs délais.')
          } else {
            setMessageTypeDisplayed('error')
            setMessageDisplayed('Une erreur est survenu. Si le problème persiste merci de prendre contact avec nous.')
          }


        }).finally(() => {

          console.log('CustomerDashboard -> Add new ticket -> Finally : ')
          setTimeout(() => {

            setOpen(false)
            executeRequest()

          }, 5000);
        })
      }
    })

  }

  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
    values: {
      getTitle: getTitle,
      getDescription: getDescription,
    }
  });

  const onSubmit = (formData) => {

    console.log('CustomerDashboard -> onSubmit -> getTitle : ' + formData.getTitle);
    console.log('CustomerDashboard -> onSubmit -> getDescription : ' + formData.getDescription);
    setTitle(formData.getTitle)
    setDescription(formData.getDescription)
  };

  const displayCommentArea = () => {

    return (

      <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >

        <Controller name="getTitle" control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <><TextField
              label="Titre"
              sx={{ minWidth: 120 }}
              placeholder='Titre de votre demande'
              id="getTitle"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
            </>
          )}
        /> {errors.getTitle && <Typography variant="body2" sx={{ color: red[900] }}>
          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
          Le champ Titre ne doit pas être vide
        </Typography>}

        <Controller name="getDescription" control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <><TextField

              label="Description de votre demande"
              multiline
              rows={4}
              sx={{ minWidth: 120 }}
              placeholder="Description de votre demande"
              id="getDescription"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
            </>
          )}
        /> {errors.getDescription && <Typography variant="body2" sx={{ color: red[900] }}>
          <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
          Veulliez rédiger une description.
        </Typography>}
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">

          <Stack direction="column" spacing={2} >

            <input
              style={{ display: 'none' }}
              placeholder='Vous pouvez joindre un document'
              type="file"
              onChange={handleFileChange}
              accept=".jpg, .jpeg, .png, .pdf"
              id="contained-button-file" />

            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                //color="primary"
                component="span"
                sx={{
                  // mt: 1, mr: 1,
                  backgroundColor: purple[700], color: purple[50]
                }}
                startIcon={<FilePresentOutlined />}>
                Joindre un fichier
              </Button>
              <Typography variant="caption" display="block" gutterBottom>
                Format accepté : .jpg, .jpeg, .png, .pdf
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                Taille max accepté : 1 Mo
              </Typography>
            </label>

          </Stack>

          {displayFileSelected(getFileType, getFileName)}
          
        </Stack>
      </Stack>

    )

  }

useEffect(() => {
  console.log('MGModalModTicket -> useEffect');

  getData('MGUserInfo').then((value) => {
    console.log('MGModalModTicket -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)

    if (value === null) {
      navigation.navigate('home')
      setUserConnected(false)
    } else {

      setUserConnected(true)

      executeRequest()
    }
  })

}, []);

return (
  <Box sx={{ flexGrow: 1 }}>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{ flexGrow: 1 }}>
      <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
      <Stack direction="column" spacing={2} >
        <Typography sx={{
          borderColor: purple[700], borderStyle: 'solid', color: purple[700],
          borderWidth: 1, padding: 1, borderRadius: 1
        }} variant="overline">Add comment to this ticket : {ticketID}</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            displayCommentArea()
          }
          <Button
            sx={{
              mt: 1, mr: 1,
              borderColor: purple[800], borderStyle: 'solid',
              borderWidth: 1, backgroundColor: grey[50], color: purple[600]
            }}
            // type="submit"
            onClick={handleSendComment}
            variant="contained"
            size="large">
            Enoyer
          </Button>
        </form>


        <FlatList
          data={getTicketCom}
          renderItem={displayCommentRow}
          numColumns={1}
          key={1}
          width="100%"
          //windowSize={60}
          refreshing={true}
        />

      </Stack>
    </Box>

    <Modal
      open={openChildModal}
      onClose={handleCloseChildModal}
    >
      <Box sx={{ height: '100%', widows: '100%' }}>

        <Card sx={{ width: '80%', height: '90%', marginLeft: '10%', marginTop: '2%' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                I
              </Avatar>
            }
            action={
              <IconButton aria-label="settings" onClick={handleCloseChildModal}>
                <Cancel />
              </IconButton>
            }
            title={getAttachmentName}
          // subheader="September 14, 2016"
          />
          <CardMedia
            component="img"
            height="80%"
            // source={getPreviewImageBase64}
            // image="/static/images/cards/paella.jpg"
            src={getPreviewImageBase64}
          />

          <CardContent>
            <MenuItem onClick={(event) => downloadDoc(getAttachmentData, getAttachmentName)}>
              <ListItemIcon>
                <FileDownloadOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Télécharger</ListItemText>
            </MenuItem>
          </CardContent>
          {/* <CardActions disableSpacing>
              <IconButton aria-label="add to favorites" >
                
              </IconButton>
              
            </CardActions> */}

        </Card>


      </Box>
    </Modal>
  </Box>
)

}

export default MGModalModTicket;