import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {Device, DeviceType, getDeviceTypeAsync} from 'expo-device';
import { styles } from './Styles/style';

// Import Screens
import SplashScreen from './Screens/SplashScreen';
import LoginScreen from './Screens/LoginScreen';
import RegisterScreen from './Screens/RegisterScreen';
import ForgotPasswordScreen from './Screens/ForgotPasswordScreen';
import HomeScreen from './Screens/HomeScreen';
import DownloadApp from './Screens/DownloadApp';

//   export default App;
const Stack = createStackNavigator();



const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  console.log("App -> Auth ->")
  
  return (
    <Stack.Navigator initialRouteName="LoginScreen">
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{
          title: 'Créer un compte', //Set Header Title
          headerStyle: {
            backgroundColor: '#a4529b', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
      <Stack.Screen
        name="ForgotPasswordScreen"
        component={ForgotPasswordScreen}
        options={{
          title: 'Mot de passe oublié', //Set Header Title
          headerStyle: {
            backgroundColor: '#a4529b', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
      <Stack.Screen
      name='HomeScreen'
      component={HomeScreen}
      options={{
        headerShown: false
      }}
      />
      <Stack.Screen
      name='DownloadApp'
      component={DownloadApp}
      options={{
        title: 'Télécharger notre application', //Set Header Title
          headerStyle: {
            backgroundColor: '#a4529b', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
      }}
      />
    </Stack.Navigator>
  );
};

export default function App() {
  console.log("App -> ")

  
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Auth">
        {/* SplashScreen which will come once for 5 Seconds */}
        <Stack.Screen
          name="SplashScreen"
          component={SplashScreen}
          // Hiding header for Splash Screen
          options={{headerShown: false}}
        />
        {/* Auth Navigator which includer Login Signup will come once */}
        <Stack.Screen
          name="Auth"
          component={Auth}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name='HomeScreen'
          component={HomeScreen}
          options={{
            headerShown: false
          }}
        />
        </Stack.Navigator>
    </NavigationContainer>
    
  );
}

