import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { StyleSheet, TextInput, View, Text, ScrollView, Keyboard, TouchableOpacity, KeyboardAvoidingView, SafeAreaView, Dimensions, FlatList } from 'react-native';
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Share } from 'react-native';


import { MD3Colors, PaperProvider, Surface, Icon, Drawer, Badge, Tooltip, Card } from 'react-native-paper'

import {
  IconButton, ListItemText, ListItemIcon,
  ListItemButton, Avatar, Typography, CardMedia, CardHeader, CardContent
} from '@mui/material';

import {
  MoreVert, ImageOutlined, PictureAsPdfOutlined
} from '@mui/icons-material';

import { red, green, black, blue } from '@mui/material/colors';


import { Buffer } from "buffer";
// import { openURL } from 'expo-linking';
// import * as FileSystem from 'expo-file-system';

import { storeData, getData, removeData } from "../Utils/DataManager";
import { APIMainRequest } from '../Communication/MGAPIRequest';
import { deviceTypeMap } from '../Utils/Helper';
import { MGGetSettings } from '../Communication/MGGetSettings';
import CustomerDashboard from '../Components/Customers/CustomersDashboard';

const logOut = async (id) => {
  console.log('Dashboard -> Try LogOut -> with id : ' + id)

  const params = {
    id: id
  };
  const resultRequest = await APIMainRequest('/MGUsers/Mcdv/logout', params).then(() => {

    console.log('Dashboard -> LogOut -> resultRequest -> Then')
    const displayResult = resultRequest.getData
    console.log('Dashboard -> LogOut -> resultRequest -> Then -> displayResult : ' + displayResult)

  }).finally(() => {

    console.log('Dashboard -> LogOut -> resultRequest -> Finally')

    removeData('MGUserInfo').then(() => {

      console.log('Dashboard -> LogOut -> removeData -> Then')

    }).finally(() => {

      console.log('Dashboard -> LogOut -> removeData -> Finally -> MGGetUserId -> Delete data -> Go to home')
      navigation.navigate('home')

    })

  }).catch(() => {
    console.log('Dashboard -> LogOut -> resultRequest -> Catch ->')
  })


}



const Dashboard = ({ navigation, getWitchPage }) => {


  const [expanded, setExpanded] = React.useState(false);
  const [userConnected, setUserConnected] = React.useState();
  const [userInfo, setUserInfo] = React.useState();
  const [userProfile, setUserProfile] = React.useState();
  const [retreiveImageBase64, setRetreiveImageBase64] = React.useState()
  const [retreivePDFBase64, setRetreivePDFBase64] = React.useState()
  const [displayAttachList, setDisplayAttachList] = React.useState()

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };



  const executeRequest = () => {

    getData('MGUserInfo').then(function (value) {
      console.log('Dashboard -> executeRequest -> getData -> then -> MGUserInfo -> profile : ' + value.profile)
      return value
    }).then(function (myValue) {

      console.log('Dashboard -> executeRequest -> getData -> finally -> myValue.parentID : ' + myValue.parentID)
      console.log('Dashboard -> executeRequest -> getData -> finally -> myValue.MGGetUserId : ' + myValue.MGGetUserId)

      MGGetSettings(myValue.MGGetUserId)

    })

  }



  const downloadDoc = (data, name) => {
    // const sBase64 = myJson.ibmAttachments.data
    const sBase64 = data
    const buffer = Buffer.from(sBase64, "base64") //Buffer is only available when using nodejs
    //var url = saveAs(new Blob([buffer]), name)

  }

  const toDisplayAttachmentsLink = ({ item }) => {
    console.log('Dashboard -> toDisplayAttachmentsLink #1 -> ' + item.lenght) // item.stoneShorDesc
    console.log('Dashboard -> toDisplayAttachmentsLink #2 -> ' + JSON.stringify(item.ibmAttachType, null, 2))
    return (
      <View style={{ position: 'relative', margin: 5, padding: 5, flex: 0.5 }} >
        <ListItemButton
          // selected={selectedIndex === 1}
          onClick={(event) => downloadDoc(item.ibmAttachments.data, item.ibmAttachName)}
        >
          <ListItemIcon>
            {item.ibmAttachType == 'application/pdf' ? <PictureAsPdfOutlined sx={{ color: red[500] }} /> : <ImageOutlined sx={{ color: blue[500] }} />}
          </ListItemIcon>
          <ListItemText primary={item.ibmAttachName} />
        </ListItemButton>

      </View>
    )
  }


  useEffect(() => {
    console.log('Dashbord -> useEffect');



    // setTimeout(() => {

    //   executeRequest()

    // }, 5000);

    getDeviceTypeAsync().then((deviceType) => {
      console.log('Dashboard -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')
      }

    });

    getData('MGUserInfo').then((value) => {
      console.log('Dashboard -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)

      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        // navigation.navigate('HomeScreen')
        // setID(value.MGGetUserId)
        // userInfoRequest(value.MGGetUserId)
        setUserConnected(true)
        setUserInfo(value)
        setUserProfile(value.profile)
        executeRequest()

      }
    })


  }, []);




  return (


    userProfile == 'customer' ?

      <CustomerDashboard />

      :

      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVert />
            </IconButton>
          }
          title="Tableau de bord"
          subheader="Détails tableau de bord"
        />
        <CardMedia
          component="img"
          height="194"
          src={require('../assets/backGround.jpg')}
          alt="Dashboard"

        />
        <CardContent>


          <Typography variant="body2" color="text.secondary">

            This impressive paella is a perfect party dish and a fun meal to cook
            together with your guests. Add 1 cup of frozen peas along with the mussels,
            if you like.
          </Typography>
        </CardContent>
      </Card>

  )

}

export default Dashboard