import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { Dimensions } from 'react-native';

import { Box, Grid, Paper, styled, Typography, Switch, Slide } from '@mui/material';
    
  import { 
    KeyOffRounded, KeyRounded, VerifiedUserRounded, GppBadRounded, HourglassTopRounded, 
    HourglassDisabledRounded } from '@mui/icons-material';
    
import { red, green, black } from '@mui/material/colors';
import { APIMainRequest } from '../Communication/MGAPIRequest';
import { storeData, getData, removeData } from "../Utils/DataManager";
import SnackBarManager from './SnackBarManager';

// getMessage = Message that we want display
// getType = Type of alert view that we want Ex : error, warning, info, success
const APIController = ({idClients}) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        // ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        boxShadow: 'none'
      }));

      const ItemIcon = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

      const [open, setOpen] = React.useState(false);
    
    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    // API Key 
    const [isAPIKeyValid, setIsAPIKeyValid] = useState();
    const [isAPIKeyValidMsg, setIsAPIKeyValidMsg] = useState('');

    const [isAPIKeyActive, setIsAPIKeyActive] = useState();
    const [isAPIKeyActiveMsg, setIsAPIKeyActiveMsg] = useState('');

    const [isAPIKeyValidDate, setIsAPIKeyValidDate] = useState();
    const [isAPIKeyValidDateMsg, setIsAPIKeyValidDateMsg] = useState('');

    const [currentProfile, setCurrentProfile] = useState('')

    const [activeAPIKEY, setActiveAPIKEY] = useState('');

    const checkAPIKey = async () => {
        // console.log('APIController -> checkAPIKey ->');
        const param = {
          userID: idClients,
        }
        await APIMainRequest('/MGUsers/dh/check/api', param).then((value) => {
          // console.log('APIController -> checkAPIKey -> Then : ' + value.getData.success);
          return value.getData
        }).then(function(myValue) {
          // console.log('APIController -> checkAPIKey -> Then 2 : ' + myValue.success);
  
          if (myValue.success == true) {
            // console.log('APIController -> checkAPIKey -> Then 2 -> Success : ' + myValue.success);

            // console.log('APIController -> checkAPIKey -> Then 2 -> keyValide : ' + myValue.keyValide);
            // console.log('APIController -> checkAPIKey -> Then 2 -> keyMessageValide : ' + myValue.keyMessageValide);
            // console.log('APIController -> checkAPIKey -> Then 2 -> keyActive : ' + myValue.keyActive);
            // console.log('APIController -> checkAPIKey -> Then 2 -> keyMessageActive : ' + myValue.keyMessageActive);
            // console.log('APIController -> checkAPIKey -> Then 2 -> keyEndDate : ' + myValue.keyEndDate);
            // console.log('APIController -> checkAPIKey -> Then 2 -> keyMessageDate : ' + myValue.keyMessageDate);
            setIsAPIKeyValid(myValue.keyValide)
            setIsAPIKeyValidMsg(myValue.keyMessageValide)

            setIsAPIKeyActive(myValue.keyActive)
            setIsAPIKeyActiveMsg(myValue.keyMessageActive)

            setIsAPIKeyValidDate(myValue.keyEndDate)
            setIsAPIKeyValidDateMsg(myValue.keyMessageDate)
          } else {
            
          }
        })
      }

    const switchActiveAPIKey = async (event) => {
        
        const param = {
            userID: idClients,
            active: event.target.checked
          }
          await APIMainRequest('/MGUsers/dh/activate/api', param).then((value) => {
            console.log('APIController -> switchActiveAPIKey -> Then : ' + value.getData.success);
            return value.getData
          }).then(function(myValue) {
            console.log('APIController -> switchActiveAPIKey -> Then 2 : ' + myValue.success);
    
            if (myValue.success == true) {
              console.log('APIController -> switchActiveAPIKey -> Then 2 -> Success : ' + myValue.success);
  
              console.log('APIController -> switchActiveAPIKey -> Then 2 -> message : ' + myValue.message);
              console.log('APIController -> switchActiveAPIKey -> Then 2 -> userLogin : ' + myValue.userLogin);
              setIsAPIKeyActive(myValue.success)
              setActiveAPIKEY(myValue.message)
              
            } else {
                setIsAPIKeyActive(false)
            }

            setOpen(true)
            setTimeout(() => {
                setOpen(false)
            }, 5000);
            
          })
        console.log('APIController -> switchActiveAPIKey -> event : ' + event.target.checked)
        setIsAPIKeyActive(event.target.checked)
    }

    const onChange = () => {
        // console.log('APIController -> onChange ->')
        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);
        var fw = Dimensions.get('window').width - 260
      };

    useEffect(function effectFunction() {
        console.log('APIController -> useEffect');
        checkAPIKey()
        
        onChange()

        //handler to get device Height
        setHeight(Dimensions.get('window').height);
        //handler to get device Width
        setWidth(Dimensions.get('window').width);

        Dimensions.addEventListener('change', onChange);

        getData('MGUserInfo').then((value) => {
            console.log('APIController -> useEffect -> MGUserInfo -> MGGetUserId : ' + value.MGGetUserId)
            setCurrentProfile(value.profile)
            
          })

    }, []);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        margin: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    
    return (
        <>
            <SnackBarManager isOpen={open} getMessage={activeAPIKEY} getType="success"/>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <ItemIcon>
                            {
                                isAPIKeyValid == true ? <VerifiedUserRounded fontSize='medium' sx={{ color: green[700] }} /> :
                                <GppBadRounded fontSize='medium' sx={{ color: red[700] }} />
                            }
                        </ItemIcon>
                    </Grid>
                    <Grid item xs={10}>
                        <Item>
                            <Typography variant="body1" sx={{ color: '#000000' }}>
                                {
                                    isAPIKeyValidMsg != undefined ? isAPIKeyValidMsg : 'Aucune informations !'
                                }
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        { currentProfile == 'root' ?
                        <ItemIcon>
                            
                            {
                                isAPIKeyActive == true ? <IOSSwitch sx={{ sx: 0 }} defaultChecked onChange={(event) => {switchActiveAPIKey(event)}} /> :
                                <IOSSwitch sx={{ sx: 0 }} onChange={(event) => {switchActiveAPIKey(event)}} />
                            }
                        </ItemIcon>
                        :
                        <ItemIcon>
                            {
                                isAPIKeyActive == true ? <KeyRounded fontSize='medium' sx={{ color: green[700] }} /> :
                                <KeyOffRounded fontSize='medium' sx={{ color: red[700] }} />
                            }
                        </ItemIcon>
                        }
                    </Grid>
                    <Grid item xs={10}>
                        <Item>
                            <Typography variant="body1" sx={{ color: '#000000' }}>
                                {
                                    isAPIKeyActiveMsg != undefined ? isAPIKeyActiveMsg : 'Aucune informations !'
                                }
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <ItemIcon>
                            {
                                isAPIKeyValidDate == true ? <HourglassTopRounded fontSize='medium' sx={{ color: green[700] }} /> :
                                <HourglassDisabledRounded fontSize='medium' sx={{ color: red[700] }} />
                            }
                        </ItemIcon>
                    </Grid>
                    <Grid item xs={10}>
                        <Item>
                            <Typography variant="body1" sx={{ color: '#000000' }}>
                                {
                                    isAPIKeyValidDateMsg != undefined ? isAPIKeyValidDateMsg : 'Aucune informations !'
                                }
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default APIController