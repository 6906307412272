import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,

} from 'react-native';
import { MD3Colors, PaperProvider, Button, Avatar, Surface, Icon, Drawer, Badge, IconButton, Tooltip, List } from 'react-native-paper'
import { storeData, getData, removeData } from "../Utils/DataManager";
import { Device, DeviceType, getDeviceTypeAsync } from 'expo-device';
import { styles } from '../Styles/style';

import { APIMainRequest } from '../Communication/MGAPIRequest';


// Import Components
import Loader from '../Components/Loader';

const LoginScreen = ({ navigation }) => {

  const deviceTypeMap = {
    [DeviceType.UNKNOWN]: "unknown",
    [DeviceType.PHONE]: "phone",
    [DeviceType.TABLET]: "tablet",
    [DeviceType.DESKTOP]: "desktop",
    [DeviceType.TV]: "tv",
  };

  useEffect(() => {
    console.log('LoginScreen -> useEffect');
    getDeviceTypeAsync().then((deviceType) => {
      console.log('LoginScreen -> useEffect -> Device : ' + deviceTypeMap[deviceType]);
      if (deviceTypeMap[deviceType] != 'desktop') {
        navigation.navigate('DownloadApp')

        removeData('MGUserInfo').then(() => {
          console.log('TabButton -> LogOut -> removeData(MGUserInfo)')
        }).finally(() => {
          console.log('TabButton -> LogOut -> MGGetUserId -> Delete data')
          // navigation.navigate('LoginScreen')
        })

      }

    });

    getData('MGUserInfo').then((value) => {
      console.log('LoginScreen -> useEffect -> getData -> MGUserInfo : ' + value)
      if (value === null) {
        // navigation.navigate('LoginScreen')
        navigation.navigate('HomeScreen')
      } else {
        navigation.navigate('HomeScreen')
      }
    })

  }, []);

  const [login, setUserEmail] = useState('');
  const [password, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');
  const [successtext, setSuccesstext] = useState('');
  const [infotext, setInfotext] = useState('');
  const passwordInputRef = createRef();

  const params = {
    login: login,
    password: password,
  };

  const sendRequest = async () => {
    setErrortext('')
    setSuccesstext('')
    setInfotext('')
    if (!login) {
      // alert('Please fill Email');
      setErrortext('Please fill Email');
      return;
    }
    if (!password) {
      // alert('Please fill Password');
      setErrortext('Please fill Password');
      return;
    }
    setLoading(true);
    const resultRequest = await APIMainRequest('/MGUsers/dh/login', params)

    console.log('LoginScreen -> sendRequest -> isSuccess -> ' + resultRequest.getData + ' - getData -> ' + resultRequest.getData.message)
    if (resultRequest.getData.success) {
      if (resultRequest.getData.login && resultRequest.getData.password) {
        console.log('LoginScreen -> sendRequest -> Success -> ' + resultRequest.getData.message);
        if (resultRequest.getData.active) {
          console.log('LoginScreen -> OK, we can go to home page ! - MGGetUserId : ' + resultRequest.getData.parentID)
          // MGGetUserId

          const MGUserInfo = {

            MGGetUserId: resultRequest.getData.MGGetUserId,
            parentID: resultRequest.getData.parentID,
            success: resultRequest.getData.success,
            message: resultRequest.getData.message,
            login: resultRequest.getData.login,
            password: resultRequest.getData.password,
            active: resultRequest.getData.active,
            login: resultRequest.getData.login,
            menuType: resultRequest.getData.menuType,
            group: resultRequest.getData.group,
            profile: resultRequest.getData.profile,
            entryDate: resultRequest.getData.entryDate,
            updateDate: resultRequest.getData.updateDate,
            email: resultRequest.getData.email,
            firstName: resultRequest.getData.firstName,
            lastName: resultRequest.getData.lastName,
            ceoName: resultRequest.getData.ceoName,
            phonFixe: resultRequest.getData.phonFixe,
            phoneMobile: resultRequest.getData.phoneMobile,
            active: resultRequest.getData.active,
            cp: resultRequest.getData.cp,
            bp: resultRequest.getData.bp,
            adress1: resultRequest.getData.adress1,
            adress2: resultRequest.getData.adress2,
            city: resultRequest.getData.city,
            name: resultRequest.getData.name,
            identificationNumber: resultRequest.getData.identificationNumber,
            sendDoc: resultRequest.getData.sendDoc,
            deadLineInvoicePayment: resultRequest.getData.deadLineInvoicePayment,
            salesRepresent: resultRequest.getData.salesRepresent,
            info: resultRequest.getData.info,
            licenceKey: resultRequest.getData.licenceKey,
            logoName: resultRequest.getData.logoName,
            directoryName: resultRequest.getData.directoryName,
            setToken: resultRequest.getData.setToken,
          }

          storeData('MGUserInfo', MGUserInfo).then(() => {
            console.log('LoginScreen -> sendRequest -> Success -> MGUserInfo : ' + JSON.stringify(MGUserInfo, null, 2))



          }).finally(() => {
            var IDForGetSettings

            if (resultRequest.getData.profile == 'customer') {
              console.log('LoginScreen -> customer -> GO TO HOME SCREEN ')
              navigation.navigate('HomeScreen')
            } else {

              if (resultRequest.getData.profile == 'root' || resultRequest.getData.profile == 'admin') {
                IDForGetSettings = resultRequest.getData.MGGetUserId
              } else {
                IDForGetSettings = resultRequest.getData.parentID
              }

              const settingsParams = {
                clientID: IDForGetSettings,
              };
              console.log('LoginScreen -> settingsParams -> ' + JSON.stringify(settingsParams, null, 2))

              APIMainRequest('/MGUsers/settings/dh/list', settingsParams).then(function (response) {

                return response.getData

              }).then(function (myJson) {


                console.log('LoginScreen -> resultSettingsRequest -> isSuccess -> ' + myJson)
                console.log('LoginScreen -> resultSettingsRequest -> message -> ' + myJson.message)
                console.log('LoginScreen -> resultSettingsRequest -> settings -> ' + JSON.stringify(myJson.settings.settingMail.mailPopCheckFreq, null, 2))

                const publicsetting = {
                  mailPopCheckFreq: myJson.settings.settingMail.mailPopCheckFreq
                }
                storeData('MGUserSetting', publicsetting).then(() => {

                  console.log('LoginScreen -> resultSettingsRequest -> THEN -> Store settinq')

                }).finally(() => {

                  console.log('LoginScreen -> resultSettingsRequest -> FINALLY -> Store settinq')

                })

              }).finally(() => {
                console.log('LoginScreen -> customer -> GO TO HOME SCREEN ')
                navigation.navigate('HomeScreen')
              })
            }

          })


        } else {
          setInfotext('Your account are not activate. Please go to your mail box and clic on the link to activate your account.');
        }
        setLoading(false)

      } else {
        // alert('There are an error with your login and/or password');
        setLoading(false)
        setErrortext('Il y a une erreur avec le login et/ou le mot de passe. \n');
      }
    } else {
      // alert('There are an error with the request');
      setLoading(false)
      setErrortext('Il y a une erreur avec votre compte. \nVeuillez contacter l\'administrateur de votre compte');
    }

  }

  return (

    <View style={styles.mainBody}>
      <Loader loading={loading} />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: '#ffffff'
        }}>

        <TouchableOpacity style={{ alignSelf: 'start', width: '100%', height: 90, flexDirection: 'row', top: 0, position: 'absolute', padding: 10 }} onPress={() => { navigation.navigate('HomeScreen') }}>
          <Avatar.Image size={60} source={require('../assets/Logo.jpg')} style={{ top: 5 }} />
          <Text style={{ width: '-webkit-fill-available', fontSize: '3vw', fontFamily: 'sans-serif', top: '1vw', height: 60, textAlign: 'censtartter', paddingTop: 10, color: '#666666' }}> Accueil</Text>
        </TouchableOpacity>
        <View>
          <KeyboardAvoidingView enabled>
            <View style={{ alignItems: 'center' }}>

              <Image
                source={require('../assets/Logo.jpg')}
                style={{
                  width: 200,
                  height: 200,
                  //resizeMode: 'contain', //objectFit?: 'cover' | 'contain' | 'fill' | 'scale-down' | undefined;
                  margin: 30,
                  borderRadius: 10,
                }}
              />
            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserEmail) => setUserEmail(UserEmail)}
                placeholder="Enter Email" //dummy@abc.com
                placeholderTextColor="#8b9cb5"
                autoCapitalize="none"
                keyboardType="email-address"
                returnKeyType="next"
                onSubmitEditing={() =>
                  passwordInputRef.current && passwordInputRef.current.focus()
                }
                underlineColorAndroid="#f000"
                blurOnSubmit={false}
              />

            </View>
            <View style={styles.SectionStyle}>
              <TextInput
                style={styles.inputStyle}
                onChangeText={(UserPassword) => setUserPassword(UserPassword)}
                placeholder="Enter Password" //12345
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                ref={passwordInputRef}
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
                underlineColorAndroid="#f000"
                returnKeyType="next"
              />
            </View>
            {infotext != '' ? (
              <Text style={styles.infoTextStyle}> {infotext} </Text>
            ) : null}
            {successtext != '' ? (
              <Text style={styles.successTextStyle}> {successtext} </Text>
            ) : null}
            {errortext != '' ? (
              <Text style={styles.errorTextStyle}> {errortext} </Text>
            ) : null}
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={sendRequest}>
              <Text style={styles.buttonTextStyle}>Se connecter</Text>
            </TouchableOpacity>
            <Text
              style={styles.linkStyle}
              onPress={() => navigation.navigate('RegisterScreen')}>
              Nouveau ici? - Créer un compte
            </Text>
            <Text
              style={styles.linkStyle}
              onPress={() => navigation.navigate('ForgotPasswordScreen')}>
              Ooup's, Mot de passe oublié?
            </Text>
          </KeyboardAvoidingView>
        </View>
      </ScrollView>
    </View>
  );
};
export default LoginScreen;