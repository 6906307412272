import React, { useState, createRef } from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import PasswordStrengthMeter from '../Components/PasswordStrengthMeter';

import { styles } from '../Styles/style';

import { APIMainRequest } from '../Communication/MGAPIRequest';

// Import Components
import Loader from '../Components/Loader';

const RegisterScreen = ({navigation}) => {
    
  
    const [login, setUserEmail] = useState('');
    const [password, setUserPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [errortext, setErrortext] = useState('')
    const [successText, setSuccessText] = useState('')

    
    const [isMailFormat, setIsMailFormat] = useState(false)
    const [confPwd, setConfPwd] = useState(null)
    const [isRegistraionSuccess, setIsRegistraionSuccess] = useState('none');

    const passwordInputRef = createRef();

    const [loginStyle, setLoginStyle] = useState(styles.inputStyle)
    const [passwordStyle, setPasswordStyle] = useState(styles.inputStyle)
    const [confPasswordStyle, setConfPasswordStyle] = useState(styles.inputStyle)
    const [isPasswordEnable, setIsPasswordEnable] = useState(true)

    const [buttonStyle, setButtonStyle] = useState(styles.buttonDisableStyle)
    const [buttonEnable, setButtonEnable] = useState(true)

    const params = {
      login: login,
      passWord: password,
    };
    
    const sendRequest = async () => {

      setErrortext('');

      if (isMailFormat && confPwd) {
        
        setLoading(true);
        const resultRequest = await APIMainRequest('/MGUsers/dh/add', params)
        
        console.log('sendRequest -> isSuccess -> ' + resultRequest.getData.success + ' - getData -> ' + resultRequest.getData.message)
        if (resultRequest.getData.success) {
          setIsRegistraionSuccess('true')
          setSuccessText(resultRequest.getData.message);
          setLoading(false)
    
        } else {
          setIsRegistraionSuccess('false')
          setErrortext(resultRequest.getData.message);
          setLoading(false)
        } 
        
      } else {
        
        console.log('sendRequest -> Check Form -> ERROR')
        
      }

      
       
    }

    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    }

    const handleChange = (getLogin, getPassword, getConfirmPassword) => {
      
      setUserEmail(getLogin)
      setUserPassword(getPassword)
      setConfirmPassword(getConfirmPassword)

        if (validateEmail(getLogin)) {
          console.log("Adresse e-mail valide !");
          setIsPasswordEnable(false)
          setIsMailFormat(true)
          setLoginStyle(styles.inputStyleSuccess)
          setPasswordStyle(styles.inputStyle)
          setConfPasswordStyle(styles.inputStyle)
        } else {
          console.log("Adresse e-mail non valide.");
          setIsPasswordEnable(true)
          setIsMailFormat(false)
          setLoginStyle(styles.inputStyleError)
          setPasswordStyle(styles.inputStyleDisable)
          setConfPasswordStyle(styles.inputStyleDisable)  
        }
        if ((isMailFormat == true) && (getPassword === getConfirmPassword) && (getPassword != '' && getConfirmPassword != '')) {
          console.log("Same password !");
          setConfPwd(true)
          setPasswordStyle(styles.inputStyleSuccess)
          setConfPasswordStyle(styles.inputStyleSuccess)

          if (isMailFormat) {
            setButtonStyle(styles.buttonStyle)
            setButtonEnable(false)
          } else {
            setButtonStyle(styles.buttonDisableStyle)
            setButtonEnable(true)
          }

        } else {
          console.log("Password are diffrents!");
          if (isPasswordEnable == false) {
            setConfPwd(false)
            setPasswordStyle(styles.inputStyleError)
            setConfPasswordStyle(styles.inputStyleError)
          }
          
        }

        
        console.log('handleChange -> Check Btn : ' + buttonEnable + ' - Login : ' + getLogin + ' - Pwd : ' + getPassword + ' - ConfPwd : ' + getConfirmPassword)
    }

    
    if (isRegistraionSuccess == 'true') {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: '#ffffff',
            justifyContent: 'center',
          }}>
          <Image
            source={require('../assets/success.png')}
            style={{height: 150, resizeMode: 'contain', alignSelf: 'center'}}
          />
          <Text style={styles.successTextStyle}>Registration Successful.</Text>
          <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={() => navigation.navigate('LoginScreen')}>
            <Text style={styles.buttonTextStyle}>Login Now</Text>
          </TouchableOpacity>
        </View>
      );
    } else if (isRegistraionSuccess == 'false') {



    } else if (isRegistraionSuccess == 'none') {

      
    }

    return (
      <View style={styles.mainBody}>
        <Loader loading={loading} />
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#000000'
          }}>
            <Image
                  source={require('../assets/backGround.jpg')}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    opacity: 0.7
                  }}
                />
          <View accessibilityRole='form'>
            <KeyboardAvoidingView enabled>
              <View style={{alignItems: 'center'}}>
              <Image
                  source={require('../assets/Logo.jpg')}
                  style={{
                    width: 200,
                    height: 200,
                    //resizeMode: 'contain', //objectFit?: 'cover' | 'contain' | 'fill' | 'scale-down' | undefined;
                    margin: 30,
                    borderRadius: 10,
                    
                  }}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={loginStyle}
                  onChangeText={(UserEmail) => handleChange(UserEmail, password, confirmPassword)} // (UserEmail) => setUserEmail(UserEmail)
                  onChange={(UserEmail) => handleChange(UserEmail, password, confirmPassword)}
                  placeholder="Entrez votre Email" //dummy@abc.com
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  returnKeyType="next"
                  onSubmitEditing={() =>
                    passwordInputRef.current && passwordInputRef.current.focus()
                  }
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />              
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={passwordStyle}
                  onChangeText={(UserPassword) => handleChange(login, UserPassword, confirmPassword)}
                  onChange={(UserPassword) => handleChange(login, UserPassword, confirmPassword)}
                  placeholder="Entrer votre mot de passe" //12345
                  placeholderTextColor="#8b9cb5"
                  keyboardType="default"
                  ref={passwordInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  blurOnSubmit={false}
                  secureTextEntry={true} // Default true
                  underlineColorAndroid="#f000"
                  returnKeyType="next"
                  disabled={isPasswordEnable}
                />
              </View>
              <View style={styles.SectionStyleMinus}>
                <PasswordStrengthMeter password={password} />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={confPasswordStyle}
                  onChangeText={(UserConfirmePassword) => handleChange(login, password, UserConfirmePassword)}
                  onChange={(UserConfirmePassword) => handleChange(login, password, UserConfirmePassword)}
                  placeholder="Confirmez votre mot de passe" //12345
                  placeholderTextColor="#8b9cb5"
                  keyboardType="default"
                  ref={passwordInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  blurOnSubmit={false}
                  secureTextEntry={true} // Default true
                  underlineColorAndroid="#f000"
                  returnKeyType="next"
                  disabled={isPasswordEnable}
                />
              </View>
              {errortext != '' ? (
                <Text style={styles.errorTextStyle}> {errortext} </Text>
              ) : null}
              {successText != '' ? (
                <Text style={styles.successTextStyle}> {successText} </Text>
              ) : null}
              <TouchableOpacity
                style={buttonStyle}
                activeOpacity={0.5}
                onPress={sendRequest}
                disabled={buttonEnable}>
                <Text style={styles.buttonTextStyle}>Signup</Text>
              </TouchableOpacity>
             
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    );

  };
  export default RegisterScreen;