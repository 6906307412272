import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { View } from 'react-native';

import {Box, CssBaseline,
  Paper, BottomNavigation, } from '@mui/material';

import {
  Home, Dashboard, PeopleAlt, 
  History, QuestionAnswer
} from '@mui/icons-material';

const BottomBarScreen = ({navigation, getWitchPage}) => {
    
  const [active, setActive] = React.useState('');

  const menuAction = (whichOne) => {
    console.log("Menu -> menuAction -> whichOne : " + whichOne)

    if (whichOne == 0) {
      selectSection('home')
    }
    if (whichOne == 1) {
      selectSection('dashBoard')
    }
    if (whichOne == 2) {
      console.log("Menu -> menuAction -> whichOne : clientsList " + whichOne)
      selectSection('clientsList')
    }
    if (whichOne == 3) {
      selectSection('history')
    }
    if (whichOne == 4) {
      selectSection('message')
    }
  }
  
  const selectSection = (witchSection) => {
  
    setActive(witchSection)
    console.log('Menu Management Action -> witch Pages : ' + witchSection)
    getWitchPage(witchSection)
  }
    
    return (
      <View>

      

      <Box sx={{ pb: 7 }}>
        <CssBaseline />
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
          >
            {/* {['Accueil', 'Tableau de bord', 'Clients', 'Historique', 'Messages'].map((text, index) => (
              <BottomNavigationAction label={text} icon={displayMenuIcon(index)} onClick={() => {menuAction(index)}} />
              
                  ))} */}
            
          </BottomNavigation>
        </Paper>
      </Box>
      
    </View>
    )

}

export default BottomBarScreen