import React, { useState, createRef, useRef, useEffect, Component } from 'react';

import { useForm, Controller, useWatch } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import {
  InputLabel, TextField, Box, MenuItem, 
  FormControl, Button, Stack, Paper, Typography, 
  Fade, Modal, Backdrop, Stepper, Step, StepLabel, StepContent
} from '@mui/material';

import {
  ExitToApp, ImageOutlined, PictureAsPdfOutlined,
  WarningAmberOutlined, Folder, CheckOutlined, 
  ArrowForwardIosOutlined, FilePresentOutlined,
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../../Communication/MGAPIRequest';


const MGModalTicket = ({ closeModalRefreshList }) => {

  const closePopup = () => {
    console.log('MGModal -> closePopup -> close modal');

    modalManage(false)
  }
  
  const [openModal, setOpenModal] = React.useState(false);

  const [depMainSelected, setDepMainSelected] = React.useState('');
  const [depNameMainSelected, setDepNameMainSelected] = React.useState('');
  const [depDescMainSelected, setDepDescMainSelected] = React.useState('');
  const [depContactMainSelected, setDepContactMainSelected] = React.useState('');
  const [depContactIDMainSelected, setDepContactIDMainSelected] = React.useState('');

  const [depLV1Selected, setDepLV1Selected] = React.useState('');
  const [depNameLV1Selected, setDepNameLV1Selected] = React.useState('');
  const [depDescLV1Selected, setDepDescLV1Selected] = React.useState('');
  const [depContactLV1Selected, setDepContactLV1Selected] = React.useState('');
  const [depContactLV1IDSelected, setDepContactLV1IDSelected] = React.useState('');

  const [depLV2Selected, setDepLV2Selected] = React.useState('');
  const [depNameLV2Selected, setDepNameLV2Selected] = React.useState('');
  const [depDescLV2Selected, setDepDescLV2Selected] = React.useState('');
  const [depContactLV2Selected, setDepContactLV2Selected] = React.useState('');
  const [depContactLV2IDSelected, setDepContactLV2IDSelected] = React.useState('');

  const [depLV3Selected, setDepLV3Selected] = React.useState('');
  const [depNameLV3Selected, setDepNameLV3Selected] = React.useState('');
  const [depDescLV3Selected, setDepDescLV3Selected] = React.useState('');
  const [depContactLV3Selected, setDepContactLV3Selected] = React.useState('');
  const [depContactLV3IDSelected, setDepContactLV3IDSelected] = React.useState('');

  const [getFinalDepRelation, setFinalDepRelation] = React.useState('')

  const [getTitle, setTitle] = useState('')
  const [getDescription, setDescription] = useState('')
  const [getFile, setFile] = useState()
  const [getFileName, setFileName] = useState('')
  const [getFileType, setFileType] = useState('')

  const [displayNextBtn, setDisplayNextBtn] = React.useState(true)

  const [getDepList, setDepList] = React.useState([])

  const [getDepListLV1, setDepListLV1] = React.useState([])
  const [getDepListLV2, setDepListLV2] = React.useState([])
  const [getDepListLV3, setDepListLV3] = React.useState([])

  const [userConnected, setUserConnected] = React.useState()
  const [open, setOpen] = React.useState(false);
  const [messageDisplayed, setMessageDisplayed] = useState('')
  const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#7b1fa2',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#7b1fa2',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#9e9e9e',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#9e9e9e',
    display: 'flex',
    height: 52,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#7b1fa2',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#7b1fa2',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className, index } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ?
          <CheckOutlined sx={{ color: purple[700], width: 35, height: 35 }} />
          :
          <ArrowForwardIosOutlined sx={{ color: grey[500], width: 35, height: 35 }} />
        }
      </QontoStepIconRoot>
    );
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const steps = [
    {
      label: 'Selectioner un sujet',
      description: `Veuillez sélectioner parmit les différentes options proposé celles qui 
      décrit le mieux votre situation afin de vous apporter le meilleur soutien`,
    },
    {
      label: 'Rédiger votre observation',
      description: 'Dites nous en plus sur votre demande',
    },
    {
      label: 'Recapitulatif de votre demande',
      description: 'Cliquez sur confirmer afin que notre agent ' + depContactMainSelected + ' traite votre demande dans les meilleurs délais.',
    },
  ];

  const executeRequest = () => {

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> useEffect -> getData -> MGUserInfo -> profile : ' + value.parentID)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        const settingsParams = {
          clientID: value.parentID,
        }
        APIMainRequest('/MGSubject/list', settingsParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('CustomerDashboard -> resultSettingsRequest -> ' + JSON.stringify(myJson, null, 2))
          setDepList(myJson)
        }).finally(() => {
          console.log('CustomerDashboard -> DepartementMGT -> Finally : ')
        })
      }
    })

  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setDisplayNextBtn(true)

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setDepMainSelected()
    setDisplayNextBtn(true)

    setDepLV1Selected('')
    setDepLV2Selected('')
    setDepLV3Selected('')

    setDepListLV1([])
    setDepListLV2([])
    setDepListLV3([])

    setTitle('')
    setDescription('')

    closeModalRefreshList()
  };

  const handleChangeMainDep = (event) => {
    console.log('CustomerDashboard -> handleChangeMainDep -> value : ' + event.target.value)

    setDisplayNextBtn(true)

    setDepLV1Selected('')
    setDepLV2Selected('')
    setDepLV3Selected('')

    setDepListLV1([])
    setDepListLV2([])
    setDepListLV3([])

    const getMainDepArray = getDepList.find((elemArray) => elemArray._id === event.target.value)
    setDepMainSelected(event.target.value);
    setDepNameMainSelected(getMainDepArray.subjectName)
    setDepDescMainSelected(getMainDepArray.subjectDescription)
    setDepContactMainSelected(getMainDepArray.contactRef)
    setDepContactIDMainSelected(getMainDepArray.contactRefID)

    console.log('CustomerDashboard -> handleChangeMainDep -> Get name : ' + getMainDepArray.subjectName)

    if (getMainDepArray.childLevel1 == '') {
      setDisplayNextBtn(false)
    }

    console.log('CustomerDashboard -> handleChangeMainDep -> getLV1DepArray : ' + JSON.stringify(getMainDepArray.childLevel1, null, 2))
    setDepListLV1(getMainDepArray.childLevel1)

  };

  const handleChangeLV1Dep = (event) => {
    console.log('CustomerDashboard -> handleChangeLV1Dep -> value : ' + event.target.value)

    setDisplayNextBtn(true)

    setDepLV2Selected('')
    setDepLV3Selected('')

    setDepListLV2([])
    setDepListLV3([])

    const getLV1DepArray = getDepListLV1.find((elemArray) => elemArray._id === event.target.value)
    setDepLV1Selected(event.target.value);
    setDepNameLV1Selected(getLV1DepArray.subjectName)
    setDepDescLV1Selected(getLV1DepArray.subjectDescription)
    setDepContactLV1Selected(getLV1DepArray.contactRef)
    setDepContactLV1IDSelected(getLV1DepArray.contactRefID)
    if (getLV1DepArray.childLevel2 == '') {
      setDisplayNextBtn(false)
    }

    console.log('CustomerDashboard -> handleChangeLV1Dep -> getLV2DepArray : ' + JSON.stringify(getLV1DepArray.childLevel2, null, 2))
    setDepListLV2(getLV1DepArray.childLevel2)

  };

  const handleChangeLV2Dep = (event) => {
    console.log('CustomerDashboard -> handleChangeLV2Dep -> value : ' + event.target.value)

    setDisplayNextBtn(true)

    setDepLV3Selected('')

    setDepListLV3([])

    const getLV2DepArray = getDepListLV2.find((elemArray) => elemArray._id === event.target.value)
    setDepLV2Selected(event.target.value);
    setDepNameLV2Selected(getLV2DepArray.subjectName)
    setDepDescLV2Selected(getLV2DepArray.subjectDescription)
    setDepContactLV2Selected(getLV2DepArray.contactRef)
    setDepContactLV2IDSelected(getLV2DepArray.contactRefID)
    if (getLV2DepArray.childLevel3 == '') {
      setDisplayNextBtn(false)
    }

    console.log('CustomerDashboard -> handleChangeLV2Dep -> getLV3DepArray : ' + JSON.stringify(getLV2DepArray.childLevel3, null, 2))
    setDepListLV3(getLV2DepArray.childLevel3)
  };

  const handleChangeLV3Dep = (event) => {
    console.log('CustomerDashboard -> handleChangeLV3Dep -> value : ' + event.target.value)

    setDisplayNextBtn(true)
    const getLV3DepArray = getDepListLV3.find((elemArray) => elemArray._id === event.target.value)
    setDepLV3Selected(event.target.value);
    setDepNameLV3Selected(getLV3DepArray.subjectName)
    setDepDescLV3Selected(getLV3DepArray.subjectDescription)
    setDepContactLV3Selected(getLV3DepArray.contactRef)
    setDepContactLV3IDSelected(getLV3DepArray.contactRefID)
    // console.log('CustomerDashboard -> handleChangeLV3Dep -> getLV2DepArray : ' + JSON.stringify(getLV3DepArray, null, 2))
    // setDepListLV3(getLV3DepArray)
    setDisplayNextBtn(false)
  };

  const handleSendTicket = () => {

    console.log('CustomerDashboard -> handleSendTicket')
    console.log('CustomerDashboard -> handleSendTicket -> depMainSelected : ' + depMainSelected)
    console.log('CustomerDashboard -> handleSendTicket -> depLV1Selected : ' + depLV1Selected)
    console.log('CustomerDashboard -> handleSendTicket -> depLV2Selected : ' + depLV2Selected)
    console.log('CustomerDashboard -> handleSendTicket -> depLV3Selected : ' + depLV3Selected)

    console.log('CustomerDashboard -> handleSendTicket -> getTitle : ' + getTitle)
    console.log('CustomerDashboard -> handleSendTicket -> getDescription : ' + getDescription)
    console.log('CustomerDashboard -> handleSendTicket -> Get companie ID : ' + getDepList[0].clientID)

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> useEffect -> getData -> MGUserInfo -> profile : ' + value.MGGetUserId)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        console.log('SettingScreen -> onSubmit -> updateParm : ' + value.MGGetUserId);
        setOpen(true)
        const finalSubject = depMainSelected + '/' + depLV1Selected + '/' + depLV2Selected + '/' + depLV3Selected
        const updateParm = {
          userID: value.MGGetUserId,
          forwarderName: value.name,
          assignID: depContactIDMainSelected,
          assignName: depContactMainSelected,
          commentAutor: value.firstName + ' ' + value.lastname,
          commentTitle: getTitle,
          commentDesc: getDescription,

          commentJoinFileB64: getFile,
          commentJointFileName: getFileName,
          commentJoinFileType: getFileType,

          ticketSLA: '48',
          ticketState: 'Open',
          ticketPriority: 'Customer-Requested',
          subjectTicketsName: finalSubject,
          parentSubjectRelation: finalSubject,
          companyID: getDepList[0].clientID

        }
        console.log('SettingScreen -> onSubmit -> updateParm : ' + JSON.stringify(updateParm, null, 2));
        APIMainRequest('/MGUsers/dh/addTicket', updateParm).then(function (response) {
          return response.getData
        }).then(function (myJson) {

          console.log('CustomerDashboard -> Add new ticket -> ' + JSON.stringify(myJson, null, 2))
          if (myJson.success == true) {
            setMessageTypeDisplayed('success')
            setMessageDisplayed('Votre ticket a bien été enregistré, un agent le prend en compte dans les meilleurs délais.')
          } else {
            setMessageTypeDisplayed('error')
            setMessageDisplayed('Une erreur est survenu. Si le problème persiste merci de prendre contact avec nous.')
          }


        }).finally(() => {

          console.log('CustomerDashboard -> Add new ticket -> Finally : ')
          setTimeout(() => {

            setOpen(false)
            handleReset()

          }, 5000);
        })
      }
    })

  }

  const displayStepMainDep = () => {
    return (
      <Box sx={{ minWidth: 120, marginTop: 5, paddingTop: 5 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Sujet principale</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={depMainSelected}
            label="Sujet principale"
            onChange={handleChangeMainDep}
          >
            <MenuItem value="-"><em>Sujet principale</em></MenuItem>
            {
              getDepList.map((item) => (
                <MenuItem value={item._id}>{item.subjectName}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </Box>
    )
  }

  const displayStepLV1Dep = () => {
    return (
      <Box sx={{ minWidth: 120, marginTop: 5 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Préciser</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={depLV1Selected}
            label="Préciser"
            onChange={handleChangeLV1Dep}
          >
            <MenuItem value="-"><em>Préciser</em></MenuItem>
            {
              getDepListLV1.map((item) => (
                <MenuItem value={item._id}>{item.subjectName}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </Box>
    )
  }

  const displayStepLV2Dep = () => {
    return (
      <Box sx={{ minWidth: 120, marginTop: 5 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Préciser</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={depLV2Selected}
            label="Préciser"
            onChange={handleChangeLV2Dep}
          >
            <MenuItem value="-"><em>Préciser</em></MenuItem>
            {
              getDepListLV2.map((item) => (
                <MenuItem value={item._id}>{item.subjectName}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </Box>
    )
  }

  const displayStepLV3Dep = () => {
    return (
      <Box sx={{ minWidth: 120, marginTop: 5 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Préciser</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={depLV3Selected}
            label="Préciser"
            onChange={handleChangeLV3Dep}
          >
            <MenuItem value="-"><em>Préciser</em></MenuItem>
            {
              getDepListLV3.map((item) => (
                <MenuItem value={item._id}>{item.subjectName}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </Box>
    )
  }

  const loadBalancingDep = () => {

    console.log('CustomerDashboard -> loadBalancingDep -> activeStep : ' + activeStep);



    if (getDepList != '' && getDepListLV1 == '' && getDepListLV2 == '' && getDepListLV3 == '') {
      console.log('CustomerDashboard -> loadBalancingDep -> Case 1');
      return (
        <Stack direction="column" spacing={2} >
          {displayStepMainDep()}
        </Stack>
      )
    } else if (getDepList != '' && getDepListLV1 != '' && getDepListLV2 == '' && getDepListLV3 == '') {
      console.log('CustomerDashboard -> loadBalancingDep -> Case 2');
      return (
        <Stack direction="column" spacing={2} >
          {displayStepMainDep()}
          {displayStepLV1Dep()}
        </Stack>
      )
    } else if (getDepList != '' && getDepListLV1 != '' && getDepListLV2 != '' && getDepListLV3 == '') {
      console.log('CustomerDashboard -> loadBalancingDep -> Case 3');
      return (
        <Stack direction="column" spacing={2} >
          {displayStepMainDep()}
          {displayStepLV1Dep()}
          {displayStepLV2Dep()}
        </Stack>
      )
    } else if (getDepList != '' && getDepListLV1 != '' && getDepListLV2 != '' && getDepListLV3 != '') {
      console.log('CustomerDashboard -> loadBalancingDep -> Case 4');
      return (
        <Stack direction="column" spacing={2} >
          {displayStepMainDep()}
          {displayStepLV1Dep()}
          {displayStepLV2Dep()}
          {displayStepLV3Dep()}
        </Stack>
      )
    }
  }

  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
    values: {
      getTitle: getTitle,
      getDescription: getDescription,
    }
  });

  const onSubmit = (formData) => {

    console.log('CustomerDashboard -> onSubmit -> getTitle : ' + formData.getTitle);
    console.log('CustomerDashboard -> onSubmit -> getDescription : ' + formData.getDescription);
    setTitle(formData.getTitle)
    setDescription(formData.getDescription)
    handleNext()
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      const maxSizeInBytes = 1024 * 1024; // 1MB (exemple : vous pouvez ajuster cela selon vos besoins)
      if (file.size > maxSizeInBytes) {
        setOpenModal(true)
        // Vous pouvez ajouter ici d'autres actions comme empêcher l'envoi du formulaire, etc.
      } else {
        console.log('CustomerDashboard -> handleFileChange -> file : ' + JSON.stringify(file, null, 2))
        const reader = new FileReader();
        // Read the file as binary data
        
        // reader.readAsDataURL(file)
        reader.onloadend = () => {
          console.log('CustomerDashboard -> handleFileChange -> Name : ' + file.name);
          console.log('CustomerDashboard -> handleFileChange -> Type : ' + file.type);
          // Convert binary data to Base64
          const base64Data = btoa(reader.result);
          setFile(base64Data);
          console.log('CustomerDashboard -> handleFileChange -> Data : ' + base64Data);
          setFileName(file.name)
          setFileType(file.type)
        };
        reader.readAsBinaryString(file);
      }

    }
  };
  
  const displayFileSelected = (fileType, fileName) => {

    return (
      <>
        {fileType != '' && fileName != '' ?
          <Stack direction="row" spacing={2} >
            {
              fileType == 'application/pdf' ?
                <PictureAsPdfOutlined sx={{
                  width: 35,
                  height: 35,
                  color: purple[700],
                }} /> : null
            }
            {
              fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'image/jpg' ?
                <ImageOutlined sx={{
                  width: 35,
                  height: 35,
                  color: purple[700],
                }} /> : null
            }
            <Typography variant="h5">{fileName}</Typography>
          </Stack>
          : null
        }
      </>
    )
  }

  const displayCommentArea = () => {

    if (activeStep == 1) {

      return (


        <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >

          <Controller name="getTitle" control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <><TextField
                label="Titre"
                sx={{ minWidth: 120 }}
                placeholder='Titre de votre demande'
                id="getTitle"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
              </>
            )}
          /> {errors.getTitle && <Typography variant="body2" sx={{ color: red[900] }}>
            <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
            Le champ Titre ne doit pas être vide
          </Typography>}

          <Controller name="getDescription" control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <><TextField

                label="Description de votre demande"
                multiline
                rows={4}
                sx={{ minWidth: 120 }}
                placeholder="Description de votre demande"
                id="getDescription"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
              </>
            )}
          /> {errors.getTitle && <Typography variant="body2" sx={{ color: red[900] }}>
            <ErrorOutlineRounded fontSize='medium' sx={{ color: red[900] }} />
            Veulliez rédiger une description.
          </Typography>}
          <Stack direction="row" spacing={2} >

            <Stack direction="column" spacing={2} >

              <input
                style={{ display: 'none' }}
                placeholder='Vous pouvez joindre un document'
                type="file"
                onChange={handleFileChange}
                accept=".jpg, .jpeg, .png, .pdf"
                id="contained-button-file" />

              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  //color="primary"
                  component="span"
                  sx={{
                    // mt: 1, mr: 1,
                    backgroundColor: purple[700], color: purple[50]
                  }}
                  startIcon={<FilePresentOutlined />}>
                  Joindre un fichier
                </Button>
                <Typography variant="caption" display="block" gutterBottom>
                  Format accepté : .jpg, .jpeg, .png, .pdf
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Taille max accepté : 1 Mo
                </Typography>
              </label>

            </Stack>

            {displayFileSelected(getFileType, getFileName)}

          </Stack>



        </Stack>
      )
    } else if (activeStep == 2) {
      return (
        <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >
          <Typography variant="caption">{getTitle}</Typography>
          <Typography variant="caption">{getDescription}</Typography>
        </Stack>

      )
    }

  }

  const displayReviewDep = () => {
    return (
      <Stack direction="column" spacing={2} marginTop={5} >
        <Typography variant="body1" sx={{ color: grey[700] }} ><b><u>Votre demande concerne le sujet suivant :</u></b></Typography>
        <Stack direction="row" spacing={2}>
          <Folder sx={{
            width: 35,
            height: 35,
            color: purple[700],
          }} />
          <Typography variant="button">{depNameMainSelected} / {depNameLV1Selected} / {depNameLV2Selected} / {depNameLV3Selected}</Typography>
        </Stack>

      </Stack>
    )
  }

  const displayReviewComment = () => {
    return (
      <Stack direction="column" spacing={2} marginTop={5} >
        <Stack direction="row" spacing={2} >
          <Typography variant="body1" sx={{ color: grey[700] }} ><b><u>Titre :</u></b></Typography>
          <Typography variant="body2">{getTitle}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} >
          <Typography variant="body1" sx={{ color: grey[700] }} ><b><u>Description :</u></b></Typography>
          <Typography variant="body2">{getDescription}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} >
          {displayFileSelected(getFileType, getFileName)}
        </Stack>
      </Stack>
    )
  }

  const displayFinalReview = () => {

    const storeAllDepSelected = depMainSelected + ' - ' + depLV1Selected + ' - ' + depLV2Selected + ' - ' + depLV3Selected
    console.log('CustomerDashboard -> loadBalancingDep -> storeAllDepSelected : ' + storeAllDepSelected);

    return (
      <Stack direction="column" spacing={2} marginTop={5} >
        <Typography variant="body1" sx={{ color: grey[700] }}><b><u>Votre demande concerne le sujet suivant :</u></b></Typography>
        <Stack direction="row" spacing={2}>
          <Folder sx={{
            width: 35,
            height: 35,
            color: purple[700],
          }} />
          <Typography variant="button">{depNameMainSelected} / {depNameLV1Selected} / {depNameLV2Selected} / {depNameLV3Selected}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} >
          <Typography variant="body1" sx={{ color: grey[700] }}><b><u>Titre :</u></b></Typography>
          <Typography variant="body2">{getTitle}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} >
          <Typography variant="body1" sx={{ color: grey[700] }}><b><u>Description :</u></b></Typography>
          <Typography variant="body2">{getDescription}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} >
          {displayFileSelected(getFileType, getFileName)}
        </Stack>
      </Stack>
    )
  }

  useEffect(() => {
    console.log('CustomerDashboard -> useEffect');

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)

      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {

        setUserConnected(true)
        
        executeRequest()
      }
    })

  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />

      <Typography sx={{
        borderColor: purple[700], borderStyle: 'solid', color: purple[700],
        borderWidth: 1, padding: 1, borderRadius: 1
      }} variant="h5">Créer une nouvelle demande</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep}
          orientation="vertical"
          connector={<QontoConnector />}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                optional={

                  index === 0 && activeStep > 0 ? displayReviewDep() :
                    index === 1 && activeStep > 1 ? displayReviewComment() :
                      index === 2 && activeStep >= 2 ? displayFinalReview() : null

                }
              >
                <Typography sx={{
                  borderColor: purple[700], borderStyle: 'solid', color: purple[700],
                  borderWidth: 1, padding: 1, borderRadius: 1
                }} variant="overline">{step.label}</Typography>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {index === 0 ? loadBalancingDep() : null}

                {index === 1 ? displayCommentArea() : null}

                {index === 2 ?
                  <Typography variant="caption">Dernière étape</Typography> : null
                }


                <Box sx={{ mb: 2 }}>
                  <div>

                    {index === 1 ?
                      <>
                        <Button
                          variant="contained"
                          // onClick={handleNext}
                          disabled={displayNextBtn}
                          type="submit"
                          sx={{
                            mt: 1, mr: 1,
                            borderColor: purple[800], borderStyle: 'solid',
                            borderWidth: 1, backgroundColor: grey[50],
                            color: purple[700], ':hover': {
                              ackgroundColor: purple[600],
                              color: purple[50]
                            }
                          }}
                        >
                          {index === steps.length - 1 ? 'Terminer' : 'Étape suivante'}
                        </Button>
                      </>
                      :
                      <>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          disabled={displayNextBtn}
                          sx={{
                            mt: 1, mr: 1,
                            borderColor: purple[800], borderStyle: 'solid',
                            borderWidth: 1, backgroundColor: grey[50],
                            color: purple[700], ':hover': {
                              ackgroundColor: purple[600],
                              color: purple[50]
                            }
                          }}
                        >
                          {index === steps.length - 1 ? 'Terminer' : 'Étape suivante'}
                        </Button>
                      </>
                    }



                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{
                        mt: 1, mr: 1,
                        backgroundColor: purple[50], color: purple[700]
                      }}
                    >
                      Retour
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>
              Vou avez complèté toutes les étapes, finalisez votre demande en cliquant sur Envoyer<br />
              Si non cliquez sur annuler
            </Typography>

            <Button
              sx={{
                mt: 1, mr: 1,
                borderColor: purple[800], borderStyle: 'solid',
                borderWidth: 1, backgroundColor: grey[50], color: purple[600]
              }}
              // type="submit"
              onClick={handleSendTicket}
              variant="contained"
              size="large">
              Enoyer
            </Button>

            <Button
              onClick={handleReset}
              sx={{
                mt: 1, mr: 1,
                borderColor: red[700], borderStyle: 'solid',
                borderWidth: 1, backgroundColor: grey[50], color: red[700]
              }}>
              Annuler
            </Button>
          </Paper>
        )}

      </form>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={styleModal}>
            <ExitToApp
              style={{ position: 'absolute', top: '2%', right: '2%', width: '6%', height: '6%', color: '#ffffff' }}
              onClick={() => { handleCloseModal }} />

            <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >
              <WarningAmberOutlined sx={{
                width: '100%',
                height: 65,
                color: purple[700],
              }} />
              <Typography id="transition-modal-title" variant="h3" component="h2">
                Attention
              </Typography>
              <Typography id="transition-modal-title" variant="body1" component="h2">
                Le fichier ne doit pas dépasser 1 Mo (1024 Ko)
              </Typography>
              <Button
                onClick={handleCloseModal}
                sx={{
                  mt: 1, mr: 1,
                  backgroundColor: purple[700], color: grey[50],
                  width: '50%',
                  alignSelf: 'center'
                }}>
                OK
              </Button>
            </Stack>



          </Box>
        </Fade>
      </Modal>

    </Box>
  );
};
const styleModal = {
  position: 'absolute',
  top: '30%',
  left: '35%',
  // transform: 'translate(-50%, -50%)',
  borderRadius: 5,
  width: '30%',
  height: '40%',
  bgcolor: 'background.paper',
  padding: '1%',
  overflow: 'hidden',
  textAlign: 'center'
};

export default MGModalTicket;
