import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import {
  ScrollView
} from 'react-native';

import { styled } from '@mui/material/styles';
import {
  Box, Button, Stack, Fab, ListItemText,
  ListItemIcon, Paper, Badge, Avatar, Typography, Chip,
  Fade, Modal, Backdrop
} from '@mui/material';

import {
  CancelOutlined, ExitToApp, EditOutlined, CalendarMonthOutlined, PersonOutlined,
  TaskAltOutlined, RuleOutlined, FlagOutlined, AddCircle,
  Grid3x3Outlined, ForumOutlined, LocalFireDepartmentOutlined, ArrowUpwardOutlined, KeyboardDoubleArrowUpOutlined, KeyboardArrowUpOutlined,
  UnfoldMoreOutlined, KeyboardArrowDownOutlined, KeyboardDoubleArrowDownOutlined, ArrowDownwardOutlined, AcUnitOutlined, AccountCircleOutlined
} from '@mui/icons-material';

import SnackBarManager from '../../Components/SnackBarManager';

import {
  DataGrid, GridToolbar,
} from '@mui/x-data-grid'

import { red, green, black, blue, yellow, grey, purple, orange } from '@mui/material/colors';

import { storeData, getData, removeData } from "../../Utils/DataManager";
import { APIMainRequest, selectClientListRequest } from '../../Communication/MGAPIRequest';
import { deviceTypeMap } from '../../Utils/Helper';

import MGModalTicket from './MGModalTicket';
import MGModalModTicket from './MGModalModTicket';
import UnixTimestampToDate from '../../Components/UnixTimestampToDate'


const CustomerDashboard = () => {

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'left',
    // color: theme.palette.text.secondary,
  }));

  const [userInfo, setUserInfo] = React.useState();
  const [userConnected, setUserConnected] = React.useState()
  const [open, setOpen] = React.useState(false);
  const [messageDisplayed, setMessageDisplayed] = useState('')
  const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

  ///////////////////////// TO MANAGE TICKET LIST ///////////////////////////

  const [expanded, setExpanded] = React.useState(false);
  const [getTicketListOpen, setTicketListOpen] = React.useState([])
  const [getTicketListPending, setTicketListPending] = React.useState([])
  const [getTicketListClose, setTicketListClose] = React.useState([])

  const [getDisplayInModal, setDisplayInModal] = React.useState()

  const [mainSubject, setMainSubject] = React.useState("")

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ticketListRequest = () => {

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> ticketListRequest -> MGUserInfo -> ' + value.MGGetUserId)
      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        const settingsParams = {
          userID: value.MGGetUserId,
        }
        APIMainRequest('/MGUsers/dh/ticketsList', settingsParams).then(function (response) {
          return response.getData
        }).then(function (myJson) {
          console.log('CustomerDashboard -> ticketListRequest -> ' + JSON.stringify(myJson.ticketInfoOpen, null, 2))

          setTicketListOpen(myJson.ticketInfoOpen)
          setTicketListPending(myJson.ticketInfoPending)
          setTicketListClose(myJson.ticketInfoClose)

        }).finally(() => {
          console.log('CustomerDashboard -> ticketListRequest -> Finally : ')
        })
      }
    })

  }

  ///////////////////////// TO MANAGE TICKET LIST ///////////////////////////

  /////////////////////////////// Stepper management ////////////////////////
  const [openAddTicket, setOpenAddTicket] = React.useState(false);
  const handleOpenAddTicket = () => setOpenAddTicket(true);
  const handleCloseAddTicket = () => setOpenAddTicket(false);

  const addTicketModal = () => {
    console.log('CustomerDashboard -> addTicketModal ')
    handleOpenAddTicket()
  }
  const closeModalRefreshList = () => {
    console.log('CustomerDashboard -> closeModalRefreshList - ')
    handleCloseAddTicket()

    ticketListRequest()
  }

  const selectDisplayInModal = (action, id) => {
    console.log('CustomerDashboard -> selectDisplayInModal - id : ' + id)

    if (action == 'mod') {
      setDisplayInModal(<MGModalModTicket ticketID={id} closeModalRefreshList={closeModalRefreshList} />)
      addTicketModal()
    }

    if (action == 'add') {
      setDisplayInModal(<MGModalTicket closeModalRefreshList={closeModalRefreshList} />)
      addTicketModal()
    }
  }

  const displayIconPriority = (priority) => {
    //[Critical, Emergency, High, Escalated, Medium, Low, Normal, Routine, Deferred, Customer-Requested Priority]
    return (
      <>
        {
          priority == 'Critical' ?
            <LocalFireDepartmentOutlined sx={{
              width: 35,
              height: 35,
              color: red[700],
            }} /> : null
        }
        {
          priority == 'Emergency' ?
            <ArrowUpwardOutlined sx={{
              width: 35,
              height: 35,
              color: orange[700],
            }} /> : null
        }
        {
          priority == 'High' ?
            <KeyboardDoubleArrowUpOutlined sx={{
              width: 35,
              height: 35,
              color: orange[700],
            }} /> : null
        }
        {
          priority == 'Escalated' ?
            <KeyboardArrowUpOutlined sx={{
              width: 35,
              height: 35,
              color: orange[700],
            }} /> : null
        }
        {
          priority == 'Medium' ?
            <UnfoldMoreOutlined sx={{
              width: 35,
              height: 35,
              color: grey[700],
            }} /> : null
        }
        {
          priority == 'Low' ?
            <KeyboardArrowDownOutlined sx={{
              width: 35,
              height: 35,
              color: green[700],
            }} /> : null
        }
        {
          priority == 'Normal' ?
            <KeyboardDoubleArrowDownOutlined sx={{
              width: 35,
              height: 35,
              color: green[700],
            }} /> : null
        }
        {
          priority == 'Routine' ?
            <ArrowDownwardOutlined sx={{
              width: 35,
              height: 35,
              color: green[700],
            }} /> : null
        }
        {
          priority == 'Deferred' ?
            <AcUnitOutlined sx={{
              width: 35,
              height: 35,
              color: blue[700],
            }} /> : null
        }
        {
          priority == 'Customer-Requested' ?
            <AccountCircleOutlined sx={{
              width: 35,
              height: 35,
              color: purple[700]
            }} /> : null
        }


      </>
    )
  }

  const displayIconStatus = (Status) => {
    //[Critical, Emergency, High, Escalated, Medium, Low, Normal, Routine, Deferred, Customer-Requested Priority]
    return (
      <>
        {
          Status == 'Open' ?
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: red[50], color: red[700], borderColor: red[700], borderWidth: 1, borderStyle: 'solid' }}
                >O</Avatar>}
              label={Status}
              sx={{ bgcolor: red[50], color: red[700], borderColor: red[700], borderWidth: 1, borderStyle: 'solid' }} />
            : null
        }
        {
          Status == 'Pending' ?
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: orange[50], color: orange[700], borderColor: orange[700], borderWidth: 1, borderStyle: 'solid' }}
                >P</Avatar>}
              label={Status}
              sx={{ bgcolor: orange[50], color: orange[700], borderColor: orange[700], borderWidth: 1, borderStyle: 'solid' }} />
            : null
        }
        {
          Status == 'Close' ?
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: green[50], color: green[700], borderColor: green[700], borderWidth: 1, borderStyle: 'solid' }}
                >C</Avatar>}
              label={Status}
              sx={{ bgcolor: green[50], color: green[700], borderColor: green[700], borderWidth: 1, borderStyle: 'solid' }} />
            : null
        }

      </>
    )
  }

  const getMainSubject = (idSubject) => {

    
    if (idSubject != null || idSubject != undefined) {
      const fullString = idSubject.split('/')
      const subjectID = fullString[0].toString()

      console.log('CustomerDashboard -> getMainSubject -> idSubject #1 -> ' + subjectID)
      const settingsParams = {
        subjectMain: subjectID
      }
      APIMainRequest('/MGUsers/dh/search', settingsParams).then(function (response) {
        console.log('CustomerDashboard -> getMainSubject -> ' + response.getData)
        return response.getData

      }).then(function (myJson) {
        console.log('CustomerDashboard -> getMainSubject -> ' + JSON.stringify(myJson.returnMain, null, 2))
        console.log('CustomerDashboard -> getMainSubject -> ' + myJson.returnMain.returnNameMain)

      }).finally(() => {
        console.log('CustomerDashboard -> getMainSubject -> Finally : ')
      })
    }

  }
  /////////////////////////////// Stepper management ////////////////////////

  useEffect(() => {
    console.log('CustomerDashboard -> useEffect');

    getData('MGUserInfo').then((value) => {
      console.log('CustomerDashboard -> useEffect -> getData -> MGUserInfo -> MGProfile : ' + value.profile)

      if (value === null) {
        navigation.navigate('home')
        setUserConnected(false)
      } else {
        // navigation.navigate('HomeScreen')
        // setID(value.MGGetUserId)
        // userInfoRequest(value.MGGetUserId)
        setUserConnected(true)
        setUserInfo(value)
        // executeRequestDepList()
        // executeRequest()

        ticketListRequest()

      }
    })

  }, []);

  const tmpInitialState = {
    columns: {
      columnVisibilityModel: {

        _id: true, // 1
        id: false, // 2
        ticketsId: true, // 3
        clientId: false, // 4
        entryDate: true, // 5
        subjectComments: true, // 6
        ticketSLA: true, // 7
        ticketState: true, // 8
        ticketPriority: true, // 9
        subjectTicketsName: true, // 10
        parentSubjectRelation: false, // 11
        active: true, // 12
        forwarderName: true, // 13
        assignIDCurrent: false, // User ID 
        assignNameCurrent: true, // Comment description
        assignName: false, // 14
        timeSpend: true, // 15

      }
    }
  };

  const rowsTmp = [
    {
      _id: '1', // 1
      id: '1', // 2
      ticketsId: '1', // 3
      clientId: '1', // 4
      entryDate: '1', // 5
      subjectComments: '1', // 6
      ticketSLA: '1', // 7
      ticketState: '1', // 8
      ticketPriority: '1', // 9
      subjectTicketsName: '1', // 10
      parentSubjectRelation: '1', // 11
      active: '1', // 12
      forwarderName: '1', // 13
      assignIDCurrent: '1', // User ID 
      assignNameCurrent: '1', // Comment description
      assignName: '1', // 14
      timeSpend: '1', // 15
    }
  ]

  const MGDefineCustomerColumns = [
    {
      field: "_id",
      headerName: <>
        <ListItemIcon>
          <EditOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Détails</ListItemText>
        </ListItemIcon>
      </>,
      width: 120,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Button variant="outlined" sx={{ color: grey[700], borderColor: grey[700] }} startIcon={<EditOutlined />}
              onClick={() => { selectDisplayInModal('mod', params.value) }}
            >
              Détails
            </Button>
          </>
        );
      },
      align: 'center'
    }, // _id
    {
      field: "id",
      hideable: false
    }, // id
    {
      field: "ticketsId",
      headerName: <>
        <ListItemIcon>
          <Grid3x3Outlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>I.D</ListItemText>
        </ListItemIcon>
      </>,
      width: 120,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false
    }, // ticketsId
    {
      field: "clientId",
      hideable: false
    }, // clientId
    {
      field: "entryDate",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Créé le</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      width: 180,
      renderCell: (params) => {
        return (
          <><UnixTimestampToDate unixTimestamp={params.value} /></>
        );
      },
    }, // entryDate
    {
      field: "subjectComments",
      headerName: <>
        <ListItemIcon>
          <ForumOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Commntaires</ListItemText>
        </ListItemIcon>
      </>,
      width: 140,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      align: 'center',
      renderCell: (params) => {
        var counUnread = 0
        for (let index = 0; index < params.value.length; index++) {
          const element = params.value[index];
          if (element.isRead == false) {
            console.log('CustomerDashboard -> subjectComments -> isRead : ' + element.isRead)
            counUnread += 1
          }
        }
        return (
          <>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              badgeContent={counUnread}
              color="error">

              <Avatar
                sx={{
                  bgcolor: purple[50],
                  color: purple[700],
                  borderColor: purple[700],
                  borderWidth: 1,
                  borderStyle: 'solid', width: 30, height: 30
                }}>
                {params.value.length}
              </Avatar>
            </Badge>

          </>
        );
      },
    }, // subjectComments
    {
      field: "ticketSLA",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>SLA</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      width: 150,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: purple[50], color: purple[700], borderColor: purple[700], borderWidth: 1, borderStyle: 'solid' }}
                >{params.value}</Avatar>}
              label="Heures"
              sx={{ bgcolor: grey[50], color: grey[700], borderColor: grey[700], borderWidth: 1, borderStyle: 'solid' }} />
          </>
        );
      },
    }, // ticketSLA
    {
      field: "ticketState",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Status</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      align: 'center',
      width: 150,
      renderCell: (params) => {
        return (
          <>{displayIconStatus(params.value)}</>
        );
      },
    }, // ticketState
    {
      field: "ticketPriority",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Priorité</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      width: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <>{displayIconPriority(params.value)}</>
        );
      },
    }, // ticketPriority
    {
      field: "subjectTicketsName",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Sujet</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      width: 180,
      renderCell: (params) => {
        return (
          <>{
            getMainSubject(params.value)
            // params.value
          }</>
        );
      },
    }, // subjectTicketsName
    {
      field: "parentSubjectRelation",
      headerName: <>
        <ListItemIcon>
          <CalendarMonthOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Relation</ListItemText>
        </ListItemIcon>
      </>,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: true,
      hideable: false,
      width: 180,
      renderCell: (params) => {
        return (
          <>{params.value}</>
        );
      },
    }, // parentSubjectRelation
    {
      field: "active",
      headerName: <>
        <ListItemIcon>
          <RuleOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Actif</ListItemText>
        </ListItemIcon>
      </>,
      width: 80,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      renderCell: (params) => {
        console.log('ClientsList -> Header -> Active : ' + params.value)
        return (
          <>
            {params.value == true ?
              <TaskAltOutlined sx={{ color: green[800] }} /> :
              <CancelOutlined sx={{ color: red[800] }} />}

          </>
        );
      },
      align: 'center'
    }, // active 
    {
      field: "forwarderName",
      headerName: <>
        <ListItemIcon>
          <PersonOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Auteur</ListItemText>
        </ListItemIcon>
      </>,
      width: 120,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      renderCell: (params) => {
        return (
          <>{params.value}</>
        );
      },
    }, // forwarderName
    {
      field: "assignNameCurrent",
      headerName: <>
        <ListItemIcon>
          <PersonOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Responçable</ListItemText>
        </ListItemIcon>
      </>,
      width: 140,
      editable: false,
      sortable: true,
      filterable: false,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      renderCell: (params) => {

        return (
          <>
            <Chip
              label={params.value}
              sx={{ bgcolor: grey[50], color: grey[700], borderColor: grey[700], borderWidth: 1, borderStyle: 'solid' }} />
            {/* {params.value[getLast].assignName} */}
          </>
        );
      },
    }, // assignName
    {
      field: "timeSpend",
      headerName: <>
        <ListItemIcon>
          <FlagOutlined sx={{ color: grey[600], width: 30, height: 30 }} />
          <ListItemText>Temps passé</ListItemText>
        </ListItemIcon>
      </>,
      width: 140,
      editable: false,
      sortable: true,
      filterable: true,
      groupable: true,
      aggregable: true,
      disableExport: false,
      hide: false,
      renderCell: (params) => {

        return (
          <>{params.value}</>
        );
      },
    }, // timeSpend
  ]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />

      <Stack direction="column" spacing={2} >
        <Item>
          <Box sx={{
            width: { marginLeft: 5, },
            // ml: { sm: `${drawerWidth}px` },
          }}>
            <Stack direction="row" spacing={2} >
              <Typography onClick={() => { selectDisplayInModal('add', '0') }} sx={{
                borderColor: purple[700], borderStyle: 'solid', color: purple[700],
                borderWidth: 1, padding: 1, borderRadius: 1
              }} variant="h5">
                <Fab color="secondary" aria-label="add" sx={{ marginRight: 2 }}>
                  <AddCircle />
                </Fab>
                Créer une nouvelle demande
              </Typography>

            </Stack>
          </Box>

        </Item>

        <Item>
          <Stack direction="row" spacing={2} >
            <Typography sx={{
              borderColor: purple[700], borderStyle: 'solid', color: purple[700],
              borderWidth: 1, padding: 1, borderRadius: 1, marginBottom: 3
            }} variant="h5">Vos demandes ouverte</Typography>
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: red[50], color: red[700], borderColor: red[700], borderWidth: 1, borderStyle: 'solid' }}
                >O</Avatar>}
              label={"Ouvert"}
              sx={{ bgcolor: red[50], color: red[700], borderColor: red[700], borderWidth: 1, borderStyle: 'solid' }} />
          </Stack>
          <Box sx={{ width: 1 }}>
            <DataGrid
              columns={MGDefineCustomerColumns}
              //columns={data != null ? data.columns : [{ field: 'username' }]}
              rows={getTicketListOpen != null ? getTicketListOpen : rowsTmp}
              showColumnVerticalBorder={true}
              editMode='row'
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,

                },
              }}
              initialState={tmpInitialState}
            />
          </Box>


        </Item>

        <Item>
          <Stack direction="row" spacing={2} >
            <Typography sx={{
              borderColor: purple[700], borderStyle: 'solid', color: purple[700],
              borderWidth: 1, padding: 1, borderRadius: 1
            }} variant="h5">Vos demandes en cours de traitement</Typography>
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: orange[50], color: orange[700], borderColor: orange[700], borderWidth: 1, borderStyle: 'solid' }}
                >P</Avatar>}
              label={"En cours"}
              sx={{ bgcolor: orange[50], color: orange[700], borderColor: orange[700], borderWidth: 1, borderStyle: 'solid' }} />
          </Stack>

          <Box sx={{ width: 1 }}>

            <DataGrid
              columns={MGDefineCustomerColumns}
              //columns={data != null ? data.columns : [{ field: 'username' }]}
              rows={getTicketListPending != null ? getTicketListPending : rowsTmp}
              showColumnVerticalBorder={true}
              editMode='row'
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              initialState={tmpInitialState}
            />
          </Box>
        </Item>

        <Item>
          <Stack direction="row" spacing={2} >
            <Typography sx={{
              borderColor: purple[700], borderStyle: 'solid', color: purple[700],
              borderWidth: 1, padding: 1, borderRadius: 1
            }} variant="h5">Vos demandes Terminées</Typography>
            <Chip
              avatar={
                <Avatar
                  sx={{ bgcolor: green[50], color: green[700], borderColor: green[700], borderWidth: 1, borderStyle: 'solid' }}
                >C</Avatar>}
              label={"Fermé"}
              sx={{ bgcolor: green[50], color: green[700], borderColor: green[700], borderWidth: 1, borderStyle: 'solid' }} />
          </Stack>

          <Box sx={{ width: 1 }}>

            <DataGrid
              columns={MGDefineCustomerColumns}
              //columns={data != null ? data.columns : [{ field: 'username' }]}
              rows={getTicketListClose != null ? getTicketListClose : rowsTmp}
              showColumnVerticalBorder={true}
              editMode='row'
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              initialState={tmpInitialState}
            />
          </Box>
        </Item>
      </Stack>



      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAddTicket}
        onClose={closeModalRefreshList}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddTicket}>
          <Box sx={styleAddTicket}>
            <ExitToApp
              style={{ position: 'absolute', top: '2%', right: '2%', width: '6%', height: '6%', color: '#ffffff' }}
              onClick={() => { closeModalRefreshList() }} />
            <ScrollView style={{ height: '100%', padding: 5 }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}>
              {getDisplayInModal}

            </ScrollView>


          </Box>
        </Fade>
      </Modal>
    </Box >



  )


}

const style = {
  color: grey[700]
};
const styleAddTicket = {
  position: 'absolute',
  top: '5%',
  left: '25%',
  // transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '88%',
  bgcolor: 'background.paper',
  padding: '1%',
  overflow: 'hidden'
};




export default CustomerDashboard