

import React, { useState, createRef } from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';

import { styles } from '../Styles/style';

import { APIMainRequest } from '../Communication/MGAPIRequest';

// Import Components
import Loader from '../Components/Loader';

const ForgotPasswordScreen = ({navigation}) => {
    
  
    const [login, setUserEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [errortext, setErrortext] = useState('')
    const [successText, setSuccessText] = useState('')

    
    const [isMailFormat, setIsMailFormat] = useState(null)
    const [isRegistraionSuccess, setIsRegistraionSuccess] = useState('none');

    const passwordInputRef = createRef();

    const [loginStyle, setLoginStyle] = useState(styles.inputStyle)
    const [buttonStyle, setButtonStyle] = useState(styles.buttonDisableStyle)
    const [buttonEnable, setButtonEnable] = useState(true)

    const params = {
      login: login,
    };
    
    const sendRequest = async () => {

      setErrortext('');

      if (isMailFormat) {
        
        setLoading(true);
        const resultRequest = await APIMainRequest('/MGUsers/dh/lostpwd', params)
        
        console.log('sendRequest -> isSuccess -> ' + resultRequest.getData.success + ' - getData -> ' + resultRequest.getData.message)
        if (resultRequest.getData.success) {
          setIsRegistraionSuccess('true')
          setSuccessText(resultRequest.getData.message);
          setLoading(false)
    
        } else {
          setIsRegistraionSuccess('false')
          setErrortext(resultRequest.getData.message);
          setLoading(false)
        } 
        
      } else {
        
        console.log('sendRequest -> Check Form -> ERROR')
        
      }

      
       
    }

    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    }

    const handleChange = (getLogin) => {
      
      setUserEmail(getLogin)
        if (validateEmail(getLogin)) {
          console.log("Adresse e-mail valide !");
          setIsMailFormat(true)
          setLoginStyle(styles.inputStyleSuccess)
          setButtonStyle(styles.buttonStyle)
          setButtonEnable(false)
        } else {
          console.log("Adresse e-mail non valide.");
          setIsMailFormat(false)
          setLoginStyle(styles.inputStyleError)
          setButtonStyle(styles.buttonDisableStyle)
          setButtonEnable(true)
        }
        console.log('handleChange -> Check Btn : ' + buttonEnable + ' - Login : ' + login)
        
    }

    
    if (isRegistraionSuccess == 'true') {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: '#ffffff',
            justifyContent: 'center',
          }}>
          <Image
            source={require('../assets/success.png')}
            style={{height: 150, resizeMode: 'contain', alignSelf: 'center'}}
          />
          <Text style={styles.successTextStyle}>Veuillez consulter votre boite mail.<br/>Suivez les instructions pour ré-initialiser votre mot de passe</Text>
          <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={() => navigation.navigate('LoginScreen')}>
            <Text style={styles.buttonTextStyle}>Connectez-vous</Text>
          </TouchableOpacity>
        </View>
      );
    } else if (isRegistraionSuccess == 'false') {



    } else if (isRegistraionSuccess == 'none') {

      
    }

    return (
      <View style={styles.mainBody}>
        <Loader loading={loading} />
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#000000'
          }}>
            <Image
                  source={require('../assets/backGround.jpg')}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    opacity: 0.7
                  }}
                />
          <View accessibilityRole='form'>
            <KeyboardAvoidingView enabled>
              <View style={{alignItems: 'center'}}>
              <Image
                  source={require('../assets/Logo.jpg')}
                  style={{
                    width: 200,
                    height: 200,
                    //resizeMode: 'contain', //objectFit?: 'cover' | 'contain' | 'fill' | 'scale-down' | undefined;
                    margin: 30,
                    borderRadius: 10,
                  }}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={loginStyle}
                  onChangeText={(UserEmail) => handleChange(UserEmail)} // (UserEmail) => setUserEmail(UserEmail)
                  placeholder="Entrez votre Email" //dummy@abc.com
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  returnKeyType="next"
                  onSubmitEditing={() =>
                    passwordInputRef.current && passwordInputRef.current.focus()
                  }
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />                
              </View>
              {errortext != '' ? (
                <Text style={styles.errorTextStyle}> {errortext} </Text>
              ) : null}
              {successText != '' ? (
                <Text style={styles.successTextStyle}> {successText} </Text>
              ) : null}
              <TouchableOpacity
                style={buttonStyle}
                activeOpacity={0.5}
                onPress={sendRequest}
                disabled={buttonEnable}>
                
                <Text style={styles.buttonTextStyle}>Demander un nouveau mot de passe</Text>
              </TouchableOpacity>
             
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    );

  };
  export default ForgotPasswordScreen;